import React, { Component } from 'react';

import { Row, Col, Input, Button, Alert, Container, Label, FormGroup } from "reactstrap";
import axios from "axios";
import {nonAuthFoot,nonAuthVerifyFoot} from "../../helpers/AuthType/nonAuthFunction";

// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {Loading,SuccessPopup, SwalClose, ErrorPopup} from "../../helpers/globalfunctions";
// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';
// actions
import { checkLogin, apiError } from '../../store/actions';
import GowlingLogo from "../../assets/images/gowlings_logo_red.png";

import Auth from '../../apis/Auth';
import Lottie from "lottie-react";
import LoginLottie from "../../components/Common/LoginLottie.json";
import Loader from "../../components/Common/Loader";

class Login extends Component {

    constructor(props) {
        super(props);
        
        //this.state = {  email : "admin@themesdesign.in", password : "123456" }
        this.state = {
            // email: "",
            // password: "",
            error_message: null,
            errors: null,
            loader:false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleSubmit(event, values) {
        //console.log(values)
        // this.setState({loader:true})
        this.setState({
            error_message: null,
            errors: null
        });
  
        if(values.email == "" || values.password == "") {
            this.setState({
                error_message: "Please enter login credentials"
            });
  
            return false;
        }

        Loading()
        await Auth.login({email: values.email, password: values.password}, (response) => {

            SwalClose()
            // this.setState({loader:false})
            if(response.data.data.access_token != "") {
                for (var i in response.data.data) {
                    localStorage.setItem("user." + i, response.data.data[i]);
                }                    
                    if(localStorage.getItem("user.user_scopes")=='solicitor'){                      
                        setTimeout(() => {
                            this.props.history.push("/admin-panel/solicitor-dashboard");
                        }, 500);                        
                    }else if((localStorage.getItem("user.user_scopes")=='conveyancer')){                        
                        setTimeout(() => {
                            this.props.history.push("/admin-panel/conveyancer-dashboard");
                        }, 500);
                    }
                    else if((localStorage.getItem("user.user_scopes")=='customer')){                        
                        setTimeout(() => {
                            this.props.history.push("/admin-panel/customer-dashboard");
                        }, 500);
                    }
                    else if((localStorage.getItem("user.user_scopes")=='agent' || localStorage.getItem("user.user_scopes")=='estate_agent')){                        
                        setTimeout(() => {
                            this.props.history.push("/admin-panel/agent-dashboard");
                        }, 500);
                    }
                    else if((localStorage.getItem("user.user_scopes")=='admin')){                        
                        setTimeout(() => {
                            this.props.history.push("/admin-panel/dashboard");
                        }, 500);
                        
                    }
            } else {
                localStorage.clear();  
                this.setState({
                    error_message: "Unauthorized"
                });
            }
        }, (err) => {
            SwalClose()
            console.log({'erre':err})
            this.setState({
                error_message: err.response.data.error,
                errors: err.response.data.error
            });
        });

        // this.props.checkLogin(values, this.props.history);
    }

    componentDidMount(){
        this.props.apiError("");
        document.body.classList.add("auth-body-bg");
        nonAuthVerifyFoot();
    }

    componentWillUnmount(){
        document.body.classList.remove("auth-body-bg");
    }

    render() {

        return (
            <React.Fragment>
                <div className="home-btn d-none d-sm-block">
                    <Link to="/"><i className="mdi mdi-home-variant h2 text-white"></i></Link>
                </div>
                {/* {this.state.loader&&<Loader/>} */}
                <div>
            <Container fluid className="p-0">
                <Row className="no-gutters">
                    <Col lg={4} style={{backgroundColor:'#192a56'}}>
                        <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                            <div className="w-100">
                                <Row className="justify-content-center">
                                    <Col lg={9}>
                                        <div>
                                            <div className="text-center d-flex justify-content-center" style={{marginRight:'10px'}}>
                                                <div className='d-flex'>
                                                    {/* <img src='https://gowlingslaw.co.uk/wp-content/themes/gowlings/img/logo.svg' alt="" height="50" className="header-title"/> */}
                                                    <img src={GowlingLogo} height='50'/>
                                                    <div style={{marginLeft:'15px'}}>
                                                    <h4 className='text-logo' >Y O U R  <span style={{marginLeft:'10px'}}>L E G A L</span></h4>
                                                    <h6 className='logo-sub'>━━━━━ G R O U P ━━━━━</h6>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="p-2 mt-5">
                                                <h4 className="mb-4 text-center" style={{color:'#fff'}}>Login</h4>
                                                <AvForm className="form-horizontal mb-4" onValidSubmit={this.handleSubmit} >
                    
                                                    <FormGroup className="auth-form-group-custom mb-4">
                                                        <i className="ri-user-2-line auti-custom-input-icon" style={{color:'#79AC78'}}></i>
                                                        <Label htmlFor="email">Username</Label>
                                                        <AvField name="email" value={this.state.email} type="text" className="form-control" id="email" validate={{email: true, required: true}} placeholder="Enter email"/>
                                                    </FormGroup>
                            
                                                    <FormGroup className="auth-form-group-custom mb-4">
                                                        <i className="ri-lock-2-line auti-custom-input-icon" style={{color:'#79AC78'}}></i>
                                                        <Label htmlFor="userpassword">Password</Label>
                                                        <AvField name="password" value={this.state.password} type="password" className="form-control" id="userpassword" placeholder="Enter password"/>
                                                    </FormGroup>
{/*                             
                                                    <div className="custom-control custom-checkbox">
                                                        <Input type="checkbox" className="custom-control-input" id="customControlInline"/>
                                                        <Label className="custom-control-label" htmlFor="customControlInline">Remember me</Label>
                                                    </div> */}

                                                    <div className="mt-4 text-center">
                                                        <Button color="success" className="w-md waves-effect waves-light" type="submit">Log In</Button>
                                                    </div>

                                                    {/* <div className="mt-4 text-center">
                                                        <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock mr-1"></i> Forgot your password?</Link>
                                                    </div> */}
                                                </AvForm>
                                            {this.state.error_message ? <Alert color="danger">{this.state.error_message}</Alert> : <div style={{height:47.73}} ></div> }

                                            </div>

                                             <div className="mt-5 text-center">         
                                                <p style={{color:'#fff'}}><span style={{color:'#fff'}}>Develop & Design by <a target="_blank" style={{color:'#79AC78'}} href="https://nexisinfotech.com/">Nexis Infotech </a></span>© {new Date().getFullYear()}</p>
                                            </div>
                                        </div>

                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col lg={8}>
                        <div className="authentication-bg" >
                            <Lottie animationData={LoginLottie} loop={true} style={{width:'680px'}}/>
                            <div className=""></div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { loginError } = state.Login;
    return { loginError };
}

export default withRouter(connect(mapStatetoProps, { checkLogin, apiError })(Login));