import React, { Component } from "react";
import { Table, Row, Col, Card, CardBody, Container, Button, Form, FormGroup, Label, Input, FormText,
Pagination, PaginationItem, PaginationLink, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Modal, ModalHeader, ModalBody, ModalFooter, CustomInput, CardTitle } from "reactstrap";
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
import { Link } from "react-router-dom";
import {Today,DateFormat,DateTimeFormat,Loading,SuccessPopup, SwalClose, ErrorPopup} from "../../helpers/globalfunctions";
import CommonApi from '../../apis/CommonApi';
import Swal from 'sweetalert2';
import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

class PurchaseReport extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Report", link : "#" },
                { title : "Purchase Report", link : "#" },
            ],
            listSaleReport:[],
            pageCount: 1,
            currentPage: 1,
            per_page:50,
            search:'',
            isWaiting:false,
            modalIsOpen: false,
            from_date:'',
            to_date:'',
            sort:'',
            ViewPuchaserDetail:'',
            questions:[],
            ViewStepsModal:false
        };

        this.handlePageClick = this.handlePageClick.bind(this);
        this.rowPerPageChange = this.rowPerPageChange.bind(this);
        this.FromdateChange= this.FromdateChange.bind(this);
        this.TodateChange=this.TodateChange.bind(this);
        this.rowSortChange =this.rowSortChange.bind(this);
        this.ViewDetails = this.ViewDetails.bind(this);
        this.PurchaseStepsView =this.PurchaseStepsView.bind(this);
    }

    componentDidMount() {
        this._isMounted = true
        this.getPurchaseData()
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    //Steps view modal
    OpenViewStepsModal = ()=>this.setState({ViewStepsModal:true});
    CloseViewStepsModal = ()=>this.setState({ViewStepsModal:false});

    //viewDetail Modal open/close
    OpenViewDetailModal = ()=>this.setState({ViewDetailModal:true});
    CloseViewDetailModal = ()=>this.setState({ViewDetailModal:false});

    //Get kit distribution tracking data
    getPurchaseData() {
        CommonApi.getPurchaseReport(this.state.currentPage,this.state.per_page,this.state.search,this.state.from_date,this.state.to_date,this.state.sort).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({listSaleReport: response.data.data.data});
                    this.setState({pageCount: response.data.data.last_page});
                    this.setState({currentPage: response.data.data.current_page});
                    this.setState({per_page: response.data.data.per_page});
                }
            }
        });
    }
    //date change event
    async FromdateChange(e){
        await this.setState({from_date: e.target.value});  
        this.getPurchaseData();       
    }
    async TodateChange(e){
        await this.setState({to_date:e.target.value});
        this.getPurchaseData() 
    }

     // row per page    
    async rowPerPageChange(event) {        
        await this.setState({per_page: event.target.value});        
        this.getPurchaseData()
    }

    //sort
    async rowSortChange(event) {        
        await this.setState({sort: event.target.value});        
        this.getPurchaseData()
    }

    // page change event
    async handlePageClick(data) {
        const page = data.selected >= 0 ? data.selected + 1 : 0;
        await Promise.resolve(this.setState(() => ({ currentPage: page })));        
        this.getPurchaseData()
    }

    ViewDetails(id){
        this.OpenViewDetailModal();
        CommonApi.PurchasePropertyView(id).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({ViewPuchaserDetail:response.data.data.result})
                    console.log(this.state.ViewPuchaserDetail);
                }
            }
        });
        //Purchase Questions
        CommonApi.GetPurchaseQuestions().then(response=>{
            if (response) {
                if(this._isMounted) {
                    this.setState({questions:response.data.data})
                }
            }
        })
    }
    //steps view
    PurchaseStepsView(id){
        this.OpenViewStepsModal();
        CommonApi.PurchasePropertyFetchSteps(id).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({StepList:response.data.data.result})
                }
            }
        });
    }
   

    render() {
        const { selectedMulti } = this.state;

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Purchase Report" breadcrumbItems={this.state.breadcrumbItems} />

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>                                       

                                        <Row>
                                            <Col lg={2}>
                                                <Row>
                                                    <Col lg={3} className="d-flex justify-content-end">
                                                        <FormGroup className="mt-2">                                                    
                                                            <Label for="selectRows"><i className="  fas fa-filter"></i></Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <FormGroup>                                                    
                                                            <Input type="select" name="selectRows" onChange={this.rowSortChange} id="selectRows" value={this.state.sort}
                                                             >                                                              
                                                              <option value=''>All</option>
                                                              <option value='Won'>Won</option>
                                                              <option value='Lost'>Lost</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>                                                 
                                            </Col>
                                            <Col lg={3}>
                                                 <Row>
                                                    <Col lg={3}className="d-flex justify-content-end">
                                                        <FormGroup className="mt-2">                                    
                                                            <Label><i className="  fas fa-sort-amount-down-alt"></i></Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={5}>
                                                        <FormGroup>                                                    
                                                            <Input type="select" name="selectRows" onChange={this.rowPerPageChange} id="selectRows" value={this.state.per_page}
                                                             >                                                              
                                                              <option value=''>All</option>
                                                              <option value='50'>50</option>
                                                              <option value='100'>100</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                 </Row>                                                
                                            </Col>
                                            <Col lg={7}>
                                                <Row className="">
                                                    <Col lg={2} className="d-flex justify-content-end">
                                                        <FormGroup className="mt-2">                                                    
                                                            <Label for="">From<i class=" fas fa-calendar-alt mx-2"></i></Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <FormGroup>                                                            
                                                            <Input type="date" name="created_at" onChange={this.FromdateChange}  />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col lg={2} className="d-flex justify-content-end">
                                                        <FormGroup className="mt-2">                                                    
                                                            <Label for="">To<i class=" fas fa-calendar-alt mx-2"></i></Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <FormGroup>                                                            
                                                            <Input type="date" name="created_at" onChange={this.TodateChange} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>


        
                                        <div className="table-responsive">
                                            <Table className="mb-0">
                                                <thead className="bg-primary text-white">
                                                    <tr>
                                                        <th>Created Date</th> 
                                                        <th>Id</th>
                                                        <th>Property Address</th>
                                                        <th>Property Value</th>
                                                        <th>Full Name</th>      
                                                        <th>Steps</th>                                                 
                                                        <th>Final Status</th>
                                                        <th>Won / Lost</th>
                                                        <th>Agent name</th>
                                                        <th>Solicitor name</th>
                                                        <th>Solicitor Fee</th>
                                                        <th>Company name</th>
                                                        <th>Disbursement Fee</th>
                                                        <th>Total</th>
                                                        <th>View</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.listSaleReport ?(
                                                            this.state.listSaleReport.map((option,index)=>{
                                                                return <tr key={index}>
                                                                        <td>{DateFormat(option.created_at)}</td>
                                                                        <td>{option.id}</td>
                                                                        <td>{option.property_address}</td>
                                                                        <td>£{option.property_value}</td>                                 
                                                                        <td className="d-flex">
                                                                        <span>{option.surname_fc}</span><span className="mx-1">{option.first_name_fc}</span><span>{option.last_name_fc}</span></td>
                                                                        <td><i className="  ri-funds-box-fill" style={{fontSize:'18px',color:'#192a56'}} onClick={()=>this.PurchaseStepsView(option.id)}></i></td>
                                                                        <td style={option.final_status == 'Completed'? {background:'#5D9C59'}:option.final_status == 'Pending'?{background:'#F4D160'}:null}>{option.final_status}</td>

                                                                        <td style={option.win_lose == 'Won'? {background:'#5D9C59'}:option.win_lose=='Lost'?{background:'#EF4040'}:null}>{option.win_lose}</td>
                                                                        <td>{option.agent_name}</td>
                                                                        <td>{option.solicitor_name}</td>
                                                                        <td>£{option.std_fee}</td>
                                                                        <td>{option.soli_company}</td>
                                                                        <td>£{option.disbursement_fee_total}</td>
                                                                        <td>£{option.final_total}</td>
                                                                          <td><i className=" mdi mdi-eye" style={{fontSize:'18px',color:'#192a56'}} onClick={()=>this.ViewDetails(option.id)}></i></td>
                                                                       </tr>
                                                            })
                                                        ): null
                                                    }
                                                   
                                                    
                                                </tbody>
                                            </Table>
                                        </div>

                                    {/* View Detail Modal */}
                                    <Modal isOpen={this.state.ViewDetailModal} size='xl' >
                                        <ModalHeader className="modal-header" toggle={this.CloseViewDetailModal}> All Detail </ModalHeader>
                                        <ModalBody>
                                            {this.state.ViewPuchaserDetail? (this.state.ViewPuchaserDetail.map((value,index)=>{
                                                return (
                                                    <>
                                                        <Row key={index} className="mt-4">
                                                            <Col md={6}>
                                                                <Card>
                                                                    <CardTitle className="d-flex justify-content-center">Customer Details</CardTitle>
                                                                    <CardBody>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Property Address :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.property_address}</h6>
                                                                            </Col>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Property Value :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>£{value.property_value}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Freehold/Leasehold :</h6>
                                                                            </Col>
                                                                            <Col lg={5} md={5}>
                                                                                <h6>{value.hold_type}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Title :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.title_fc}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Surname :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.surname_fc}</h6>
                                                                            </Col>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>First Name :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.first_name_fc}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Last Name :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.last_name_fc}</h6>
                                                                            </Col>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Telephone :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.telephone_fc}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Email :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.email_fc}</h6>
                                                                            </Col>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Card>
                                                                    <CardTitle className="d-flex justify-content-center">Disbursements</CardTitle>
                                                                    <div className="d-flex" style={{background:'#192a56'}}>
                                                                            <Col lg={6} md={6}>
                                                                                <h5 style={{color:'#fff'}}>Solicitor Fee :</h5>
                                                                            </Col>
                                                                            <Col lg={6} md={6} style={{textAlign:'end'}}>
                                                                                <h5 style={{color:'#fff'}}>£{value.std_fee}</h5>
                                                                            </Col>
                                                                        </div>
                                                                    <Table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Title</th>
                                                                                <th>Fees</th>
                                                                                <th>Vat %</th>
                                                                                <th>Total</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                    {value.purchase_disbursements ?(value.purchase_disbursements.map((option,index)=>{
                                                                        return (
                                                                            <>
                                                                                <tr key={index}>
                                                                                    <td>{option.title}</td>
                                                                                    <td>£{option.disbursement_charge}</td>
                                                                                    <td>{option.vat}%</td>
                                                                                    <td>£{option.disbursement_total}</td>
                                                                                </tr>
                                                                            </>
                                                                        )
                                                                    })):null}
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td>Total</td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td>£{value.disbursement_fee_total}</td>
                                                                        </tr>
                                                                        <tr className="card-title">
                                                                            <td className="final-total-text">Final Total</td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td className="final-total-text">£{value.final_total == 0 ? value.std_fee:value.final_total }</td>
                                                                        </tr>
                                                                    </tfoot>
                                                                    </Table>
                                                                </Card>
                                                            </Col>

                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                            <Card>
                                                                <CardTitle className="d-flex justify-content-center"style={{background:'#192a56',color:'#fff'}}>Questions  Details</CardTitle>
                                                                    <CardBody>
                                                                        {this.state.questions? (this.state.questions.map((option,index)=>{
                                                                            return (
                                                                                <>
                                                                                    <div className="d-flex border-bottom mb-2" key={index}>
                                                                                        <Col lg={1} md={1} >
                                                                                            <h6>{option.que_no} </h6>
                                                                                        </Col>
                                                                                        <Col lg={8} md={8}>
                                                                                            <h6>{option.question} </h6>
                                                                                        </Col>
                                                                                        <Col lg={3} md={3}>
                                                                                            {option.que_no == 1 ? <h6>{value.located_eng}</h6>:option.que_no == 2 ? <h6>{value.buy_sell_auction}</h6>:option.que_no == 3 ? <h6>{value.as_company}</h6>:option.que_no == 4 ? <h6>{value.hold_type}</h6>:option.que_no == 5 ? <h6>{value.new_build}</h6>:option.que_no == 6 ? <h6>{value.land_registry}</h6>:option.que_no == 7 ? <h6>{value.gift_money_tp}</h6>:option.que_no == 8 ? <h6>{value.help_buy_isa}</h6>:option.que_no == 9 ? <h6>{value.mortgage}</h6>:''}
                                                                                        </Col>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })):null}
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                        {/* {
                                                            value.first_name_sc ?<>
                                                                <h6 className="my-3" style={{color:'#192a56'}}>Second Client</h6>
                                                                <Row>
                                                                <Col md={2}>
                                                                <FormGroup>
                                                                    <Label for="">
                                                                        Title 
                                                                    </Label>
                                                                    <Input
                                                                    id=""
                                                                    name="title_sc"
                                                                    placeholder=""
                                                                    type="text"
                                                                    disabled
                                                                    value={value.title_sc}
                                                                    />
                                                                </FormGroup>
                                                                </Col>
                                                                <Col md={2}>
                                                                <FormGroup>
                                                                    <Label for="">
                                                                        Surname 
                                                                    </Label>
                                                                    <Input
                                                                    id=""
                                                                    name="surname_sc"
                                                                    placeholder=""
                                                                    type="text"
                                                                    disabled
                                                                    value={value.surname_sc}
                                                                    />
                                                                </FormGroup>
                                                                </Col>
                                                                <Col md={4}>
                                                                <FormGroup>
                                                                    <Label for="">
                                                                        First Name
                                                                    </Label>
                                                                    <Input
                                                                    id=""
                                                                    name="first_name_sc"
                                                                    placeholder=""
                                                                    type="text"
                                                                    disabled
                                                                    value={value.first_name_sc}
                                                                    />
                                                                </FormGroup>
                                                                </Col>
                                                                <Col md={4}>
                                                                <FormGroup>
                                                                    <Label for="">
                                                                        Last Name
                                                                    </Label>
                                                                    <Input
                                                                    id=""
                                                                    name="last_name_sc"
                                                                    placeholder=""
                                                                    type="text"
                                                                    disabled
                                                                    value={value.last_name_sc}
                                                                    />
                                                                </FormGroup>
                                                                </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md={4}>
                                                                    <FormGroup>
                                                                        <Label for="">
                                                                            Telephone
                                                                        </Label>
                                                                        <Input
                                                                        id=""
                                                                        name="telephone_sc"
                                                                        placeholder=""
                                                                        type="text"
                                                                        disabled
                                                                        value={value.telephone_sc}
                                                                        />
                                                                    </FormGroup>
                                                                    </Col>
                                                                    <Col md={4}>
                                                                    <FormGroup>
                                                                        <Label for="">
                                                                            Email Address
                                                                        </Label>
                                                                        <Input
                                                                        id=""
                                                                        name="email_sc"
                                                                        placeholder=""
                                                                        type="text"
                                                                        disabled
                                                                        value={value.email_sc}
                                                                        />
                                                                    </FormGroup>
                                                                    </Col>
                                                                    <Col md={4}>
                                                                    <FormGroup>
                                                                        <Label for="">
                                                                            Company Name 
                                                                        </Label>
                                                                        <Input
                                                                        id=""
                                                                        name="company_name_sc"
                                                                        placeholder=""
                                                                        type="text"
                                                                        disabled
                                                                        value={value.company_name_sc}
                                                                        />
                                                                    </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </>:''
                                                        } */}
                                                    </>
                                                )

                                            })):null}
                                    </ModalBody>
                                    </Modal>

                                     {/* View Steps */}
                                     <Modal isOpen={this.state.ViewStepsModal} size='xl' >
                                        <ModalHeader className="modal-header" toggle={this.CloseViewStepsModal}>Purchase property step </ModalHeader>
                                        <ModalBody>
                                            <Row>
                                                <Col md={6} sm={6} lg={6}>
                                                    <Table>
                                                    <thead>
                                                        <tr style={{'fontWeight':'bold','fontSize':'16px'}}>
                                                            <th style={{width:'15%'}}>Step No</th>
                                                            <th style={{width:'65%'}}>Step Name</th>
                                                            <th style={{width:'20%'}}>Completed Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.StepList ?(this.state.StepList.map((stepValue,index)=>{
                                                                return(stepValue.step_no >= 1 && stepValue.step_no <= 8)? <tr key={index}>
                                                                <td>{stepValue.step_no}</td>
                                                                <td>{stepValue.step_name}</td>
                                                                <td>{stepValue.step_submit_date? DateFormat(stepValue.step_submit_date):''}</td>
                                                            </tr>:null 
                                                            })):null
                                                        }
                                                    </tbody>
                                                    </Table>
                                                </Col>
                                                <Col md={6} sm={6} lg={6}>
                                                    <Table>
                                                    <thead>
                                                        <tr style={{'fontWeight':'bold','fontSize':'16px'}}>
                                                            <th style={{width:'15%'}}>Step No</th>
                                                            <th style={{width:'65%'}}>Step Name</th>
                                                            <th style={{width:'20%'}}>Completed Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.StepList ?(this.state.StepList.map((stepValue,index)=>{
                                                                return(stepValue.step_no >= 9 && stepValue.step_no <= 15)? <tr key={index}>
                                                                <td>{stepValue.step_no}</td>
                                                                <td>{stepValue.step_name}</td>
                                                                <td>{stepValue.step_submit_date ? DateFormat(stepValue.step_submit_date):''}</td>
                                                            </tr>:null 
                                                            })):null
                                                        }
                                                    </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        </ModalBody>
                                    </Modal>
                                    {/* paginnation */}
                                    <ReactPaginate
                                      previousLabel="&#x276E;"
                                      nextLabel="&#x276F;"
                                      breakLabel={'...'}
                                      breakClassName={'break-me'}
                                      pageCount={this.state.pageCount}
                                      initialPage={this.state.currentPage - 1}
                                      forcePage={this.state.currentPage - 1}
                                      marginPagesDisplayed={2}
                                      pageRangeDisplayed={5}
                                      onPageChange={this.handlePageClick}
                                      containerClassName={'pagination mt-4 float-right'}
                                      activeClassName={'active'}
                                      disableInitialCallback={true}
                                    /> 
        
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default PurchaseReport;