import { Box,Button,Card,CardContent,CardMedia,Container, FormControl, FormControlLabel, FormLabel, InputLabel, OutlinedInput, Step, StepLabel, Stepper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react'
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Col, FormGroup, Input, Label, Row ,Table,Collapse} from 'reactstrap';
import SoldIcon from "../../assets/images/SoldIcon.png";
import BuyIcon from "../../assets/images/saleIcon.png";
import { useEffect } from 'react';
import CommonApi from '../../apis/CommonApi';
import Swal from 'sweetalert2';
import { green } from '@mui/material/colors';
import { Sheet,RadioGroup,Radio } from '@mui/joy';
import { Loading, SwalClose } from '../../helpers/globalfunctions';


function getSteps(){
    return[
        'Property Details',
        'Your Details',
    ]
}

//step 1
const BuyingForm =()=>{
    const { control } = useFormContext();
    return(
        <>

        <Controller
          control={control}
          name="property_value"
          render={({ field }) => (
            <TextField
              variant="outlined"
              label="VALUE OF PROPERTY YOU ARE BUYING"
              fullWidth
              size='small'
              margin="normal"
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="property_address"
          render={({ field }) => (
            <TextField
              variant="outlined"
              label="FIRST LINE OF THE ADDRESS OF THE PROPERTY"
              fullWidth
              size='small'
              margin="normal"
              required
              {...field}
            />
          )}
        />

            <div style={{width:'100%'}} className='mt-4'>
              <Label className='px-2 m-0'>IS THE PROPERTY LOCATED IN ENGLAND & WALES?</Label>
                <Controller
                    render={({ field }) => (
                    <RadioGroup {...field} >
                    <Row style={{padding:'0'}}>
                        <Col lg={6}>
                        <Sheet
                            sx={{
                            p: '5px',
                            borderRadius: 'md',
                            boxShadow: 'sm',
                            }}
                        >
                            <Radio
                            label='Yes'
                            overlay
                            disableIcon
                            value='YES'
                            slotProps={{
                                label: ({ checked }) => ({
                                sx: {
                                    fontWeight: 'md',
                                    fontSize: 'md',
                                    color: checked ? 'text.primary' : 'text.secondary',
                                },
                                }),
                                action: ({ checked }) => ({
                                sx: (theme) => ({
                                    ...(checked && {
                                    '--variant-borderWidth': '3px',
                                    '&&': {
                                        // && to increase the specificity to win the base :hover styles
                                        borderColor: '#192a56',
                                    },
                                    }),
                                }),
                                }),
                            }}
                            />
                        </Sheet>
                        </Col>
                        <Col lg={6}>
                            <Sheet
                                sx={{
                                p: '5px',
                                borderRadius: 'md',
                                boxShadow: 'sm',
                                }}
                            >
                                <Radio
                                    label='No'
                                    overlay
                                    disableIcon
                                    value='NO'
                                    slotProps={{
                                        label: ({ checked }) => ({
                                        sx: {
                                            fontWeight: 'md',
                                            fontSize: 'md',
                                            color: checked ? 'text.primary' : 'text.secondary',
                                        },
                                        }),
                                        action: ({ checked }) => ({
                                        sx: (theme) => ({
                                            ...(checked && {
                                            '--variant-borderWidth': '3px',
                                            '&&': {
                                                // && to increase the specificity to win the base :hover styles
                                                borderColor: '#192a56',
                                            },
                                            }),
                                        }),
                                        }),
                                    }}
                                />
                            </Sheet>
                        </Col>
                    </Row>
                    </RadioGroup>
                    )}
                    name="located_eng"
                    control={control}
                />
            </div>

        <div style={{width:'100%'}} className='mt-4'>
            <Label className='px-2 m-0'>ARE YOU BUYING OR SELLING AT AUCTION?</Label>
                <Controller
                    render={({ field }) => (
                    <RadioGroup {...field}>
                    <Row style={{padding:'0'}}>
                        <Col lg={6}>
                        <Sheet
                            sx={{
                            p: '5px',
                            borderRadius: 'md',
                            boxShadow: 'sm',
                            }}
                        >
                            <Radio
                            label='Yes'
                            overlay
                            disableIcon
                            value='YES'
                            slotProps={{
                                label: ({ checked }) => ({
                                sx: {
                                    fontWeight: 'md',
                                    fontSize: 'md',
                                    color: checked ? 'text.primary' : 'text.secondary',
                                },
                                }),
                                action: ({ checked }) => ({
                                sx: (theme) => ({
                                    ...(checked && {
                                    '--variant-borderWidth': '3px',
                                    '&&': {
                                        // && to increase the specificity to win the base :hover styles
                                        borderColor: '#192a56',
                                    },
                                    }),
                                }),
                                }),
                            }}
                            />
                        </Sheet>
                        </Col>
                        <Col lg={6}>
                            <Sheet
                                sx={{
                                p: '5px',
                                borderRadius: 'md',
                                boxShadow: 'sm',
                                }}
                            >
                                <Radio
                                    label='No'
                                    overlay
                                    disableIcon
                                    value='NO'
                                    slotProps={{
                                        label: ({ checked }) => ({
                                        sx: {
                                            fontWeight: 'md',
                                            fontSize: 'md',
                                            color: checked ? 'text.primary' : 'text.secondary',
                                        },
                                        }),
                                        action: ({ checked }) => ({
                                        sx: (theme) => ({
                                            ...(checked && {
                                            '--variant-borderWidth': '3px',
                                            '&&': {
                                                // && to increase the specificity to win the base :hover styles
                                                borderColor: '#192a56',
                                            },
                                            }),
                                        }),
                                        }),
                                    }}
                                />
                            </Sheet>
                        </Col>
                    </Row>
                    </RadioGroup>
                    )}
                    name="buy_sell_auction"
                    control={control}
                />
        </div>

        <div style={{width:'100%'}} className='mt-4'>
            <Label className='px-2 m-0'>ARE YOU PURCHASING THE PROPERTY AS A COMPANY?</Label>
                <Controller
                    render={({ field }) => (
                    <RadioGroup {...field}>
                    <Row style={{padding:'0'}}>
                        <Col lg={6}>
                        <Sheet
                            sx={{
                            p: '5px',
                            borderRadius: 'md',
                            boxShadow: 'sm',
                            }}
                        >
                            <Radio
                            label='Yes'
                            overlay
                            disableIcon
                            value='YES'
                            slotProps={{
                                label: ({ checked }) => ({
                                sx: {
                                    fontWeight: 'md',
                                    fontSize: 'md',
                                    color: checked ? 'text.primary' : 'text.secondary',
                                },
                                }),
                                action: ({ checked }) => ({
                                sx: (theme) => ({
                                    ...(checked && {
                                    '--variant-borderWidth': '3px',
                                    '&&': {
                                        // && to increase the specificity to win the base :hover styles
                                        borderColor: '#192a56',
                                    },
                                    }),
                                }),
                                }),
                            }}
                            />
                        </Sheet>
                        </Col>
                        <Col lg={6}>
                            <Sheet
                                sx={{
                                p: '5px',
                                borderRadius: 'md',
                                boxShadow: 'sm',
                                }}
                            >
                                <Radio
                                    label='No'
                                    overlay
                                    disableIcon
                                    value='NO'
                                    slotProps={{
                                        label: ({ checked }) => ({
                                        sx: {
                                            fontWeight: 'md',
                                            fontSize: 'md',
                                            color: checked ? 'text.primary' : 'text.secondary',
                                        },
                                        }),
                                        action: ({ checked }) => ({
                                        sx: (theme) => ({
                                            ...(checked && {
                                            '--variant-borderWidth': '3px',
                                            '&&': {
                                                // && to increase the specificity to win the base :hover styles
                                                borderColor: '#192a56',
                                            },
                                            }),
                                        }),
                                        }),
                                    }}
                                />
                            </Sheet>
                        </Col>
                    </Row>

                    </RadioGroup>
                    )}
                    name="as_company"
                    control={control}
                />
        </div>

        <div style={{width:'100%'}} className='mt-4'>
            <Label className='px-2 m-0'>IS THE PROPERTY YOU ARE BUYING FREEHOLD OR LEASEHOLD?</Label>
                <Controller
                    render={({ field }) => (
                    <RadioGroup {...field}>
                    <Row style={{padding:'0'}}>
                        <Col lg={6}>
                        <Sheet
                            sx={{
                            p: '5px',
                            borderRadius: 'md',
                            boxShadow: 'sm',
                            }}
                        >
                            <Radio
                            label='Freehold'
                            overlay
                            disableIcon
                            value='freehold'
                            slotProps={{
                                label: ({ checked }) => ({
                                sx: {
                                    fontWeight: 'md',
                                    fontSize: 'md',
                                    color: checked ? 'text.primary' : 'text.secondary',
                                },
                                }),
                                action: ({ checked }) => ({
                                sx: (theme) => ({
                                    ...(checked && {
                                    '--variant-borderWidth': '3px',
                                    '&&': {
                                        // && to increase the specificity to win the base :hover styles
                                        borderColor: '#192a56',
                                    },
                                    }),
                                }),
                                }),
                            }}
                            />
                        </Sheet>
                        </Col>
                        <Col lg={6}>
                            <Sheet
                                sx={{
                                p: '5px',
                                borderRadius: 'md',
                                boxShadow: 'sm',
                                }}
                            >
                                <Radio
                                    label='Leasehold'
                                    overlay
                                    disableIcon
                                    value='leasehold'
                                    slotProps={{
                                        label: ({ checked }) => ({
                                        sx: {
                                            fontWeight: 'md',
                                            fontSize: 'md',
                                            color: checked ? 'text.primary' : 'text.secondary',
                                        },
                                        }),
                                        action: ({ checked }) => ({
                                        sx: (theme) => ({
                                            ...(checked && {
                                            '--variant-borderWidth': '3px',
                                            '&&': {
                                                // && to increase the specificity to win the base :hover styles
                                                borderColor: '#192a56',
                                            },
                                            }),
                                        }),
                                        }),
                                    }}
                                />
                            </Sheet>
                        </Col>
                    </Row>

                    </RadioGroup>
                    )}
                    name="hold_type"
                    control={control}
                />
        </div>

        <div style={{width:'100%'}} className='mt-4'>
            <Label className='px-2 m-0'>IS THE PROPERTY YOU ARE BUYING A NEW BUILD?</Label>
                <Controller
                    render={({ field }) => (
                    <RadioGroup {...field}>
                    <Row style={{padding:'0'}}>
                        <Col lg={6}>
                        <Sheet
                            sx={{
                            p: '5px',
                            borderRadius: 'md',
                            boxShadow: 'sm',
                            }}
                        >
                            <Radio
                            label='Yes'
                            overlay
                            disableIcon
                            value='YES'
                            slotProps={{
                                label: ({ checked }) => ({
                                sx: {
                                    fontWeight: 'md',
                                    fontSize: 'md',
                                    color: checked ? 'text.primary' : 'text.secondary',
                                },
                                }),
                                action: ({ checked }) => ({
                                sx: (theme) => ({
                                    ...(checked && {
                                    '--variant-borderWidth': '3px',
                                    '&&': {
                                        // && to increase the specificity to win the base :hover styles
                                        borderColor: '#192a56',
                                    },
                                    }),
                                }),
                                }),
                            }}
                            />
                        </Sheet>
                        </Col>
                        <Col lg={6}>
                            <Sheet
                                sx={{
                                p: '5px',
                                borderRadius: 'md',
                                boxShadow: 'sm',
                                }}
                            >
                                <Radio
                                    label='No'
                                    overlay
                                    disableIcon
                                    value='NO'
                                    slotProps={{
                                        label: ({ checked }) => ({
                                        sx: {
                                            fontWeight: 'md',
                                            fontSize: 'md',
                                            color: checked ? 'text.primary' : 'text.secondary',
                                        },
                                        }),
                                        action: ({ checked }) => ({
                                        sx: (theme) => ({
                                            ...(checked && {
                                            '--variant-borderWidth': '3px',
                                            '&&': {
                                                // && to increase the specificity to win the base :hover styles
                                                borderColor: '#192a56',
                                            },
                                            }),
                                        }),
                                        }),
                                    }}
                                />
                            </Sheet>
                        </Col>
                    </Row>

                    </RadioGroup>
                    )}
                    name="new_build"
                    control={control}
                />
        </div>

        <div style={{width:'100%'}} className='mt-4'>
            <Label className='px-2 m-0'>IS THE PROPERTY REGISTERED WITH THE LAND REGISTRY?</Label>
                <Controller
                    render={({ field }) => (
                    <RadioGroup {...field}>
                    <Row style={{padding:'0'}}>
                        <Col lg={6}>
                        <Sheet
                            sx={{
                            p: '5px',
                            borderRadius: 'md',
                            boxShadow: 'sm',
                            }}
                        >
                            <Radio
                            label='Yes'
                            overlay
                            disableIcon
                            value='YES'
                            slotProps={{
                                label: ({ checked }) => ({
                                sx: {
                                    fontWeight: 'md',
                                    fontSize: 'md',
                                    color: checked ? 'text.primary' : 'text.secondary',
                                },
                                }),
                                action: ({ checked }) => ({
                                sx: (theme) => ({
                                    ...(checked && {
                                    '--variant-borderWidth': '3px',
                                    '&&': {
                                        // && to increase the specificity to win the base :hover styles
                                        borderColor: '#192a56',
                                    },
                                    }),
                                }),
                                }),
                            }}
                            />
                        </Sheet>
                        </Col>
                        <Col lg={6}>
                            <Sheet
                                sx={{
                                p: '5px',
                                borderRadius: 'md',
                                boxShadow: 'sm',
                                }}
                            >
                                <Radio
                                    label='No'
                                    overlay
                                    disableIcon
                                    value='NO'
                                    slotProps={{
                                        label: ({ checked }) => ({
                                        sx: {
                                            fontWeight: 'md',
                                            fontSize: 'md',
                                            color: checked ? 'text.primary' : 'text.secondary',
                                        },
                                        }),
                                        action: ({ checked }) => ({
                                        sx: (theme) => ({
                                            ...(checked && {
                                            '--variant-borderWidth': '3px',
                                            '&&': {
                                                // && to increase the specificity to win the base :hover styles
                                                borderColor: '#192a56',
                                            },
                                            }),
                                        }),
                                        }),
                                    }}
                                />
                            </Sheet>
                        </Col>
                    </Row>

                    </RadioGroup>
                    )}
                    name="land_registry"
                    control={control}
                />
        </div>

        <div style={{width:'100%'}} className='mt-4'>
            <Label className='px-2 m-0'>IS THE PROPERTY BEING PURCHASED WITH A GIFT OF MONEY FROM A THIRD PARTY?</Label>
                <Controller
                    render={({ field }) => (
                    <RadioGroup {...field}>
                    <Row style={{padding:'0'}}>
                        <Col lg={6}>
                        <Sheet
                            sx={{
                            p: '5px',
                            borderRadius: 'md',
                            boxShadow: 'sm',
                            }}
                        >
                            <Radio
                            label='Yes'
                            overlay
                            disableIcon
                            value='YES'
                            slotProps={{
                                label: ({ checked }) => ({
                                sx: {
                                    fontWeight: 'md',
                                    fontSize: 'md',
                                    color: checked ? 'text.primary' : 'text.secondary',
                                },
                                }),
                                action: ({ checked }) => ({
                                sx: (theme) => ({
                                    ...(checked && {
                                    '--variant-borderWidth': '3px',
                                    '&&': {
                                        // && to increase the specificity to win the base :hover styles
                                        borderColor: '#192a56',
                                    },
                                    }),
                                }),
                                }),
                            }}
                            />
                        </Sheet>
                        </Col>
                        <Col lg={6}>
                            <Sheet
                                sx={{
                                p: '5px',
                                borderRadius: 'md',
                                boxShadow: 'sm',
                                }}
                            >
                                <Radio
                                    label='No'
                                    overlay
                                    disableIcon
                                    value='NO'
                                    slotProps={{
                                        label: ({ checked }) => ({
                                        sx: {
                                            fontWeight: 'md',
                                            fontSize: 'md',
                                            color: checked ? 'text.primary' : 'text.secondary',
                                        },
                                        }),
                                        action: ({ checked }) => ({
                                        sx: (theme) => ({
                                            ...(checked && {
                                            '--variant-borderWidth': '3px',
                                            '&&': {
                                                // && to increase the specificity to win the base :hover styles
                                                borderColor: '#192a56',
                                            },
                                            }),
                                        }),
                                        }),
                                    }}
                                />
                            </Sheet>
                        </Col>
                    </Row>

                    </RadioGroup>
                    )}
                    name="gift_money_tp"
                    control={control}
                />
        </div>

        <div style={{width:'100%'}} className='mt-4'>
            <Label className='px-2 m-0'>IS THE PROPERTY BEING PURCHASED USING A HELP TO BUY OR LIFETIME ISA?</Label>
                <Controller
                    render={({ field }) => (
                    <RadioGroup {...field}>
                    <Row style={{padding:'0'}}>
                        <Col lg={6}>
                        <Sheet
                            sx={{
                            p: '5px',
                            borderRadius: 'md',
                            boxShadow: 'sm',
                            }}
                        >
                            <Radio
                            label='Yes'
                            overlay
                            disableIcon
                            value='YES'
                            slotProps={{
                                label: ({ checked }) => ({
                                sx: {
                                    fontWeight: 'md',
                                    fontSize: 'md',
                                    color: checked ? 'text.primary' : 'text.secondary',
                                },
                                }),
                                action: ({ checked }) => ({
                                sx: (theme) => ({
                                    ...(checked && {
                                    '--variant-borderWidth': '3px',
                                    '&&': {
                                        // && to increase the specificity to win the base :hover styles
                                        borderColor: '#192a56',
                                    },
                                    }),
                                }),
                                }),
                            }}
                            />
                        </Sheet>
                        </Col>
                        <Col lg={6}>
                            <Sheet
                                sx={{
                                p: '5px',
                                borderRadius: 'md',
                                boxShadow: 'sm',
                                }}
                            >
                                <Radio
                                    label='No'
                                    overlay
                                    disableIcon
                                    value='NO'
                                    slotProps={{
                                        label: ({ checked }) => ({
                                        sx: {
                                            fontWeight: 'md',
                                            fontSize: 'md',
                                            color: checked ? 'text.primary' : 'text.secondary',
                                        },
                                        }),
                                        action: ({ checked }) => ({
                                        sx: (theme) => ({
                                            ...(checked && {
                                            '--variant-borderWidth': '3px',
                                            '&&': {
                                                // && to increase the specificity to win the base :hover styles
                                                borderColor: '#192a56',
                                            },
                                            }),
                                        }),
                                        }),
                                    }}
                                />
                            </Sheet>
                        </Col>
                    </Row>

                    </RadioGroup>
                    )}
                    name="help_buy_isa"
                    control={control}
                />
        </div>

        <div style={{width:'100%'}} className='mt-4'>
            <Label className='px-2 m-0'>ARE YOU BUYING THE PROPERTY WITH A MORTGAGE?</Label>
                <Controller
                    render={({ field }) => (
                    <RadioGroup {...field}>
                    <Row style={{padding:'0'}}>
                        <Col lg={6}>
                        <Sheet
                            sx={{
                            p: '5px',
                            borderRadius: 'md',
                            boxShadow: 'sm',
                            }}
                        >
                            <Radio
                            label='Yes'
                            overlay
                            disableIcon
                            value='YES'
                            slotProps={{
                                label: ({ checked }) => ({
                                sx: {
                                    fontWeight: 'md',
                                    fontSize: 'md',
                                    color: checked ? 'text.primary' : 'text.secondary',
                                },
                                }),
                                action: ({ checked }) => ({
                                sx: (theme) => ({
                                    ...(checked && {
                                    '--variant-borderWidth': '3px',
                                    '&&': {
                                        // && to increase the specificity to win the base :hover styles
                                        borderColor: '#192a56',
                                    },
                                    }),
                                }),
                                }),
                            }}
                            />
                        </Sheet>
                        </Col>
                        <Col lg={6}>
                            <Sheet
                                sx={{
                                p: '5px',
                                borderRadius: 'md',
                                boxShadow: 'sm',
                                }}
                            >
                                <Radio
                                    label='No'
                                    overlay
                                    disableIcon
                                    value='NO'
                                    slotProps={{
                                        label: ({ checked }) => ({
                                        sx: {
                                            fontWeight: 'md',
                                            fontSize: 'md',
                                            color: checked ? 'text.primary' : 'text.secondary',
                                        },
                                        }),
                                        action: ({ checked }) => ({
                                        sx: (theme) => ({
                                            ...(checked && {
                                            '--variant-borderWidth': '3px',
                                            '&&': {
                                                // && to increase the specificity to win the base :hover styles
                                                borderColor: '#192a56',
                                            },
                                            }),
                                        }),
                                        }),
                                    }}
                                />
                            </Sheet>
                        </Col>
                    </Row>

                    </RadioGroup>
                    )}
                    name="mortgage"
                    control={control}
                />
        </div>
        
        </>
    )
}

const SellingForm =()=>{
    const { control } = useFormContext();
    return(
        <>
        <Controller
          control={control}
          name="property_value"
          render={({ field }) => (
            <TextField
              variant="outlined"
              label="VALUE OF PROPERTY YOU ARE SELLING"
              fullWidth
              size='small'
              margin="normal"
              {...field}
            />
          )}
        />

        <Controller
          control={control}
          name="property_address"
          render={({ field }) => (
            <TextField
              variant="outlined"
              label="FIRST LINE OF THE ADDRESS OF THE PROPERTY "
              fullWidth
              size='small'
              margin="normal"
              required
              {...field}
            />
          )}
        />

            {/* <TextField label="IS THE PROPERTY LOCATED IN ENGLAND & WALES?" name='' focused sx={{width:'100%',m:1}}/> */}
            
            <div style={{width:'100%'}} className='mt-4'>
              <Label className='px-2 m-0'>IS THE PROPERTY LOCATED IN ENGLAND & WALES?</Label>
                <Controller
                    render={({ field }) => (
                    <RadioGroup {...field}>
                    <Row style={{padding:'0'}}>
                        <Col lg={6}>
                        <Sheet
                            sx={{
                            p: '5px',
                            borderRadius: 'md',
                            boxShadow: 'sm',
                            }}
                        >
                            <Radio
                            label='Yes'
                            overlay
                            disableIcon
                            value='YES'
                            slotProps={{
                                label: ({ checked }) => ({
                                sx: {
                                    fontWeight: 'md',
                                    fontSize: 'md',
                                    color: checked ? 'text.primary' : 'text.secondary',
                                },
                                }),
                                action: ({ checked }) => ({
                                sx: (theme) => ({
                                    ...(checked && {
                                    '--variant-borderWidth': '3px',
                                    '&&': {
                                        // && to increase the specificity to win the base :hover styles
                                        borderColor: '#192a56',
                                    },
                                    }),
                                }),
                                }),
                            }}
                            />
                        </Sheet>
                        </Col>
                        <Col lg={6}>
                            <Sheet
                                sx={{
                                p: '5px',
                                borderRadius: 'md',
                                boxShadow: 'sm',
                                }}
                            >
                                <Radio
                                    label='No'
                                    overlay
                                    disableIcon
                                    value='NO'
                                    slotProps={{
                                        label: ({ checked }) => ({
                                        sx: {
                                            fontWeight: 'md',
                                            fontSize: 'md',
                                            color: checked ? 'text.primary' : 'text.secondary',
                                        },
                                        }),
                                        action: ({ checked }) => ({
                                        sx: (theme) => ({
                                            ...(checked && {
                                            '--variant-borderWidth': '3px',
                                            '&&': {
                                                // && to increase the specificity to win the base :hover styles
                                                borderColor: '#192a56',
                                            },
                                            }),
                                        }),
                                        }),
                                    }}
                                />
                            </Sheet>
                        </Col>
                    </Row>

                    </RadioGroup>
                    )}
                    name="located_eng"
                    control={control}
                />
            </div>

            <div style={{width:'100%'}} className='mt-4'>
            <Label className='px-2 m-0'>ARE YOU BUYING OR SELLING AT AUCTION?</Label>
                <Controller
                    render={({ field }) => (
                    <RadioGroup {...field}>
                    <Row style={{padding:'0'}}>
                        <Col lg={6}>
                        <Sheet
                            sx={{
                            p: '5px',
                            borderRadius: 'md',
                            boxShadow: 'sm',
                            }}
                        >
                            <Radio
                            label='Yes'
                            overlay
                            disableIcon
                            value='YES'
                            slotProps={{
                                label: ({ checked }) => ({
                                sx: {
                                    fontWeight: 'md',
                                    fontSize: 'md',
                                    color: checked ? 'text.primary' : 'text.secondary',
                                },
                                }),
                                action: ({ checked }) => ({
                                sx: (theme) => ({
                                    ...(checked && {
                                    '--variant-borderWidth': '3px',
                                    '&&': {
                                        // && to increase the specificity to win the base :hover styles
                                        borderColor: '#192a56',
                                    },
                                    }),
                                }),
                                }),
                            }}
                            />
                        </Sheet>
                        </Col>
                        <Col lg={6}>
                            <Sheet
                                sx={{
                                p: '5px',
                                borderRadius: 'md',
                                boxShadow: 'sm',
                                }}
                            >
                                <Radio
                                    label='No'
                                    overlay
                                    disableIcon
                                    value='NO'
                                    slotProps={{
                                        label: ({ checked }) => ({
                                        sx: {
                                            fontWeight: 'md',
                                            fontSize: 'md',
                                            color: checked ? 'text.primary' : 'text.secondary',
                                        },
                                        }),
                                        action: ({ checked }) => ({
                                        sx: (theme) => ({
                                            ...(checked && {
                                            '--variant-borderWidth': '3px',
                                            '&&': {
                                                // && to increase the specificity to win the base :hover styles
                                                borderColor: '#192a56',
                                            },
                                            }),
                                        }),
                                        }),
                                    }}
                                />
                            </Sheet>
                        </Col>
                    </Row>

                    </RadioGroup>
                    )}
                    name="buy_sell_auction"
                    control={control}
                />
            </div>

            <div style={{width:'100%'}} className='mt-4'>
            <Label className='px-2 m-0'>IS THE PROPERTY YOU ARE BUYING FREEHOLD OR LEASEHOLD?</Label>
                <Controller
                    render={({ field }) => (
                    <RadioGroup {...field}>
                    <Row style={{padding:'0'}}>
                        <Col lg={6}>
                        <Sheet
                            sx={{
                            p: '5px',
                            borderRadius: 'md',
                            boxShadow: 'sm',
                            }}
                        >
                            <Radio
                            label='Freehold'
                            overlay
                            disableIcon
                            value='freehold'
                            slotProps={{
                                label: ({ checked }) => ({
                                sx: {
                                    fontWeight: 'md',
                                    fontSize: 'md',
                                    color: checked ? 'text.primary' : 'text.secondary',
                                },
                                }),
                                action: ({ checked }) => ({
                                sx: (theme) => ({
                                    ...(checked && {
                                    '--variant-borderWidth': '3px',
                                    '&&': {
                                        // && to increase the specificity to win the base :hover styles
                                        borderColor: '#192a56',
                                    },
                                    }),
                                }),
                                }),
                            }}
                            />
                        </Sheet>
                        </Col>
                        <Col lg={6}>
                            <Sheet
                                sx={{
                                p: '5px',
                                borderRadius: 'md',
                                boxShadow: 'sm',
                                }}
                            >
                                <Radio
                                    label='Leasehold'
                                    overlay
                                    disableIcon
                                    value='leasehold'
                                    slotProps={{
                                        label: ({ checked }) => ({
                                        sx: {
                                            fontWeight: 'md',
                                            fontSize: 'md',
                                            color: checked ? 'text.primary' : 'text.secondary',
                                        },
                                        }),
                                        action: ({ checked }) => ({
                                        sx: (theme) => ({
                                            ...(checked && {
                                            '--variant-borderWidth': '3px',
                                            '&&': {
                                                // && to increase the specificity to win the base :hover styles
                                                borderColor: '#192a56',
                                            },
                                            }),
                                        }),
                                        }),
                                    }}
                                />
                            </Sheet>
                        </Col>
                    </Row>

                    </RadioGroup>
                    )}
                    name="hold_type"
                    control={control}
                />
            </div>

            <div style={{width:'100%'}} className='mt-4'>
            <Label className='px-2 m-0'>IS THE PROPERTY REGISTERED WITH THE LAND REGISTRY?</Label>
                <Controller
                    render={({ field }) => (
                    <RadioGroup {...field}>
                    <Row style={{padding:'0'}}>
                        <Col lg={6}>
                        <Sheet
                            sx={{
                            p: '5px',
                            borderRadius: 'md',
                            boxShadow: 'sm',
                            }}
                        >
                            <Radio
                            label='Yes'
                            overlay
                            disableIcon
                            value='YES'
                            slotProps={{
                                label: ({ checked }) => ({
                                sx: {
                                    fontWeight: 'md',
                                    fontSize: 'md',
                                    color: checked ? 'text.primary' : 'text.secondary',
                                },
                                }),
                                action: ({ checked }) => ({
                                sx: (theme) => ({
                                    ...(checked && {
                                    '--variant-borderWidth': '3px',
                                    '&&': {
                                        // && to increase the specificity to win the base :hover styles
                                        borderColor: '#192a56',
                                    },
                                    }),
                                }),
                                }),
                            }}
                            />
                        </Sheet>
                        </Col>
                        <Col lg={6}>
                            <Sheet
                                sx={{
                                p: '5px',
                                borderRadius: 'md',
                                boxShadow: 'sm',
                                }}
                            >
                                <Radio
                                    label='No'
                                    overlay
                                    disableIcon
                                    value='NO'
                                    slotProps={{
                                        label: ({ checked }) => ({
                                        sx: {
                                            fontWeight: 'md',
                                            fontSize: 'md',
                                            color: checked ? 'text.primary' : 'text.secondary',
                                        },
                                        }),
                                        action: ({ checked }) => ({
                                        sx: (theme) => ({
                                            ...(checked && {
                                            '--variant-borderWidth': '3px',
                                            '&&': {
                                                // && to increase the specificity to win the base :hover styles
                                                borderColor: '#192a56',
                                            },
                                            }),
                                        }),
                                        }),
                                    }}
                                />
                            </Sheet>
                        </Col>
                    </Row>

                    </RadioGroup>
                    )}
                    name="land_registry"
                    control={control}
                />
            </div>

            <div style={{width:'100%'}} className='mt-4'>
                <Label className='px-2 m-0'>IS THERE AN OUTSTANDING MORTGAGE ON THE PROPERTY YOU ARE SELLING?</Label>
                    <Controller
                        render={({ field }) => (
                        <RadioGroup {...field}>
                        <Row style={{padding:'0'}}>
                        <Col lg={6}>
                        <Sheet
                            sx={{
                            p: '5px',
                            borderRadius: 'md',
                            boxShadow: 'sm',
                            }}
                        >
                            <Radio
                            label='Yes'
                            overlay
                            disableIcon
                            value='YES'
                            slotProps={{
                                label: ({ checked }) => ({
                                sx: {
                                    fontWeight: 'md',
                                    fontSize: 'md',
                                    color: checked ? 'text.primary' : 'text.secondary',
                                },
                                }),
                                action: ({ checked }) => ({
                                sx: (theme) => ({
                                    ...(checked && {
                                    '--variant-borderWidth': '3px',
                                    '&&': {
                                        // && to increase the specificity to win the base :hover styles
                                        borderColor: '#192a56',
                                    },
                                    }),
                                }),
                                }),
                            }}
                            />
                        </Sheet>
                        </Col>
                        <Col lg={6}>
                            <Sheet
                                sx={{
                                p: '5px',
                                borderRadius: 'md',
                                boxShadow: 'sm',
                                }}
                            >
                                <Radio
                                    label='No'
                                    overlay
                                    disableIcon
                                    value='NO'
                                    slotProps={{
                                        label: ({ checked }) => ({
                                        sx: {
                                            fontWeight: 'md',
                                            fontSize: 'md',
                                            color: checked ? 'text.primary' : 'text.secondary',
                                        },
                                        }),
                                        action: ({ checked }) => ({
                                        sx: (theme) => ({
                                            ...(checked && {
                                            '--variant-borderWidth': '3px',
                                            '&&': {
                                                // && to increase the specificity to win the base :hover styles
                                                borderColor: '#192a56',
                                            },
                                            }),
                                        }),
                                        }),
                                    }}
                                />
                            </Sheet>
                        </Col>
                    </Row>

                        </RadioGroup>
                        )}
                        name="mortgage"
                        control={control}
                    />
            </div>


        </>
    )
}

const PropertyDetails= ()=>{
    const {control} = useFormContext();
    const [buyform,setBuyForm]=useState(false);
    const [sellForm,setSellForm]=useState(false);

    var changeBuyBGcolor= document.getElementById('buyForm');
    var changeSaleBGcolor= document.getElementById('saleForm');
    const HandleBuy =()=>{
        setBuyForm(true);
        setSellForm(false);
        //if SetBuyform true change bg
        changeBuyBGcolor.style.border = '3px solid #192a56';
        changeSaleBGcolor.style.border = 'none';
    }

    const HandleSell = () =>{
        setSellForm(true);
        setBuyForm(false);
        //if SetSellform true change bg
        changeBuyBGcolor.style.border = 'none';
        changeSaleBGcolor.style.border = '3px solid #192a56';
    }
    return(
            <>
                <span className='d-flex justify-content-center mt-4'>WHAT TYPE OF QUOTE DO YOU NEED?</span>
                <div className='d-flex justify-content-center'>

                    <Controller
                        render={({ field }) => (
                        <RadioGroup {...field}>
                            <div className='d-flex'>
                                <label className='card-btn mx-2' id='buyForm' onClick={HandleBuy}>
                                    <FormControlLabel
                                        value="buy"
                                        control={<Radio />}
                                        className='radio-hide'
                                    />
                                    <img src={BuyIcon} width={'50px'} height={'50px'} className='d-block'/>
                                    <span>Buying Property</span>
                                </label>
                                <label className='card-btn' id='saleForm' onClick={HandleSell}>
                                    <FormControlLabel
                                        value="sale"
                                        control={<Radio />}
                                        className='radio-hide'
                                    />
                                    <img src={SoldIcon} width={'50px'} height={'50px'} className='d-block'/>
                                    <span>Selling Property</span>
                                </label>
                            </div>
                        </RadioGroup>
                        )}
                        name="form_type"
                        control={control}
                    />
                    
                    {/* <Card onClick={HandleBuy} className='mx-2'>
                        <CardContent className='img-btn'>
                            <img src={BuyIcon} width={'50px'} height={'50px'}/>
                            <Typography gutterBottom variant="body2">
                                Buying Property
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card onClick={HandleSell}>
                        <CardContent className='img-btn'>
                            <img src={SoldIcon} width={'50px'} height={'50px'}/>
                            <Typography gutterBottom variant="body2">
                                Selling Property
                            </Typography>
                        </CardContent>
                    </Card> */}
                </div>

                <div className='mt-4'>
                    {buyform ? <BuyingForm/>:null}
                    {sellForm ? <SellingForm/>:null}
                </div>
                
            </> 
    )
}

//step 2
// const YourQuote= ()=>{
//     const {control} = useFormContext();
//     const [purchaseAddFee,setPurchaseAddFee] =useState([]);
//     const [saleAddFee,setSaleAddFee] =useState([]);

//     useEffect(()=>{
        
//     },[])
//     return(
//         <>
//             <div className='d-flex justify-content-center mt-4'>
//                 <Table style={{width:'50%'}}>
//                     <thead>
//                         <tr style={{'fontWeight':'bold','fontSize':'16px',textAlign:'center'}}>
//                             <th >SOLICITORS FEES</th>
//                             {/* <th >£{stdFee.total} inc VAT</th> */}
//                         </tr>
//                     </thead>
//                 </Table>
//             </div>

//             <div className='d-flex justify-content-center mt-4'>
//                 <Table style={{width:'50%'}}>
//                     <thead>
//                         <tr style={{'fontWeight':'bold','fontSize':'16px'}}>
//                             <th >ADDITIONAL FEES</th>
//                             <th >FEE</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         <tr>
//                             <td>Stamp Duty Land Tax Admin fee</td>
//                             <td>£1,318.80 </td>
//                         </tr>
//                     </tbody>
//                 </Table>
//             </div>
//         </>
//     )
// }

//step3
const YourDetails= ()=>{
    const {control} = useFormContext();
    const [CollapseOpen,setCollapseOpen] = useState(false);
    //Collapse Open/close
    const Opencollapse = ()=>setCollapseOpen(true);
    const Closecollapse = ()=>setCollapseOpen(false);
    return(
        <>
            <Card className='mt-4'>
                <CardContent>
                    <h4 className='d-flex justify-content-center' style={{margin:'25px 0'}}>YOUR DETAILS </h4>
                    <Row className='mt-4'>
                        <Col md={2}>
                            <Controller
                                control={control}
                                name="title_fc"
                                render={({ field }) => (
                                    <FormGroup>
                                    <Label for="">
                                        Title 
                                    </Label>
                                    <Input
                                        placeholder=""
                                        type="select"
                                        {...field}
                                    >
                                        <option>
                                        </option>
                                        <option value="mr">
                                            Mr
                                        </option>
                                        <option value="mrs">
                                            Mrs
                                        </option>
                                        <option value="miss">
                                            Miss
                                        </option>
                                        <option value="ms">
                                            Ms
                                        </option>
                                        <option value="dr">
                                            Dr
                                        </option>
                                    </Input>
                                    </FormGroup>
                                )}
                            />
                        </Col>
                        <Col md={4}>
                            <Controller
                                control={control}
                                name="first_name_fc"
                                render={({ field }) => (
                                    <FormGroup>
                                        <Label for="">
                                            First Name <span style={{color:'red'}}>*</span>
                                        </Label>
                                        <Input
                                        placeholder=""
                                        type="text"
                                        required
                                        {...field}/>
                                    </FormGroup>
                                )}
                            />
                        </Col>

                        <Col md={4}>
                            <Controller
                                control={control}
                                name="last_name_fc"
                                render={({ field }) => (
                                    <FormGroup>
                                        <Label for="">
                                            Middle Name 
                                        </Label>
                                        <Input
                                        placeholder=""
                                        type="text"
                                        {...field}/>
                                    </FormGroup>
                                )}
                            />
                        </Col>

                        <Col md={2}>
                            <Controller
                                control={control}
                                name="surname_fc"
                                render={({ field }) => (
                                    <FormGroup>
                                        <Label for="">
                                            Surname 
                                        </Label>
                                        <Input
                                        placeholder=""
                                        type="text"
                                        {...field}/>
                                    </FormGroup>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Controller
                            control={control}
                            name="telephone_fc"
                            render={({ field }) => (
                                <FormGroup>
                                    <Label for="">
                                    Telephone 
                                    </Label>
                                    <Input
                                    placeholder=""
                                    type="text"
                                    {...field}/>
                                </FormGroup>
                            )}
                            />
                        </Col>
                        <Col md={4}>
                            <Controller
                            control={control}
                            name="email_fc"
                            render={({ field }) => (
                                <FormGroup>
                                    <Label for="">
                                        Email Address <span style={{color:'red'}}>*</span>
                                    </Label>
                                    <Input
                                    placeholder=""
                                    type="text"
                                    required
                                    {...field}/>
                                </FormGroup>
                            )}
                            />
                        </Col>
                        <Col md={4}>
                            <Controller
                            control={control}
                            name="company_name"
                            render={({ field }) => (
                                <FormGroup>
                                    <Label for="">
                                    Company Name 
                                    </Label>
                                    <Input
                                    placeholder="if applicable!"
                                    type="text"
                                    {...field}/>
                                </FormGroup>
                            )}
                            />
                        </Col>
                    </Row>
                    <a className="d-flex justify-content-end" style={{cursor:'pointer'}} onClick={Opencollapse}>Add Second Client</a>
                        <Collapse isOpen={CollapseOpen}>
                            <Row className='mt-4'>
                                <Col md={2}>
                                    <Controller
                                        control={control}
                                        name="title_sc"
                                        render={({ field }) => (
                                            <FormGroup>
                                            <Label for="">
                                                Title 
                                            </Label>
                                            <Input
                                                placeholder=""
                                                type="select"
                                                {...field}
                                            >
                                                <option>
                                                </option>
                                                <option value="mr">
                                                    Mr
                                                </option>
                                                <option value="mrs">
                                                    Mrs
                                                </option>
                                                <option value="miss">
                                                    Miss
                                                </option>
                                                <option value="ms">
                                                    Ms
                                                </option>
                                                <option value="dr">
                                                    Dr
                                                </option>
                                            </Input>
                                            </FormGroup>
                                        )}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Controller
                                        control={control}
                                        name="first_name_sc"
                                        render={({ field }) => (
                                            <FormGroup>
                                                <Label for="">
                                                    First Name 
                                                </Label>
                                                <Input
                                                placeholder=""
                                                type="text"
                                                {...field}/>
                                            </FormGroup>
                                        )}
                                    />
                                </Col>

                                <Col md={4}>
                                    <Controller
                                        control={control}
                                        name="last_name_sc"
                                        render={({ field }) => (
                                            <FormGroup>
                                                <Label for="">
                                                    Middle Name 
                                                </Label>
                                                <Input
                                                placeholder=""
                                                type="text"
                                                {...field}/>
                                            </FormGroup>
                                        )}
                                    />
                                </Col>

                                <Col md={2}>
                                    <Controller
                                        control={control}
                                        name="surname_sc"
                                        render={({ field }) => (
                                            <FormGroup>
                                                <Label for="">
                                                    Surname 
                                                </Label>
                                                <Input
                                                placeholder=""
                                                type="text"
                                                {...field}/>
                                            </FormGroup>
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <Controller
                                    control={control}
                                    name="telephone_sc"
                                    render={({ field }) => (
                                        <FormGroup>
                                            <Label for="">
                                            Telephone 
                                            </Label>
                                            <Input
                                            placeholder=""
                                            type="text"
                                            {...field}/>
                                        </FormGroup>
                                    )}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Controller
                                    control={control}
                                    name="email_sc"
                                    render={({ field }) => (
                                        <FormGroup>
                                            <Label for="">
                                                Email Address
                                            </Label>
                                            <Input
                                            placeholder=""
                                            type="text"
                                            {...field}/>
                                        </FormGroup>
                                    )}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Controller
                                    control={control}
                                    name="company_name_sc"
                                    render={({ field }) => (
                                        <FormGroup>
                                            <Label for="">
                                            Company Name 
                                            </Label>
                                            <Input
                                            placeholder="if applicable!"
                                            type="text"
                                            {...field}/>
                                        </FormGroup>
                                    )}
                                    />
                                </Col>
                            </Row>
                            <a className="d-flex justify-content-end" style={{cursor:'pointer'}} onClick={Closecollapse}>Remove Second Client</a> 
                        </Collapse>
                </CardContent>
            </Card>
        </>
    )
}
/*function getStepContent(step){
    switch(step){
        case 0 :
            return<PropertyDetails/>;
        case 1 :
            return<YourQuote/>;
        case 2 :
            return<YourDetails/>;
    }
}*/
const ConveyancingQuote = () => {
    const [activeStep,setActiveStep] = useState(0);
    const [buyData ,setBuyData] = useState(false);
    const [saleData,setSaleData] =useState(false);
    const [step2Empty,setStep2Empty] =useState(false);
    const [purchaseAddiFee ,setPurchaseAddiFee] =useState([]);
    const [purchaseStdFee,setPurchaseStdFee]=useState('');
    const [saleStdFee,setSaleStdFee]=useState('');
    const [salesAddiFee,setSalesAddiFee]=useState([]);
    const [isLoading, setIsloading ]= useState(false) ;
    const methods = useForm({
        defaultValues:{
            property_value:'',
            property_address:'',
        },
    })

    const steps = getSteps();
    
      const handleNext = (data) => {
        var property_value = data.property_value;
        // console.log(data);
        if(activeStep == 0){
            if(data.form_type == 'buy'){
                if(data.property_address == ''){
                    setActiveStep(0);
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Please Enter Property Address !",
                    });
                }else{
                    setBuyData(true);
                    setSaleData(false);
                    CommonApi.GetPurchaseAddiFee().then(res=>{
                        setPurchaseAddiFee(res.data.data);
                    });
                    CommonApi.GetBuyStdFee(property_value).then(res=>{
                        setPurchaseStdFee(res.data.data)
                    })
                }
            }else if(data.form_type == 'sale'){
                if(data.property_address == ''){
                    setActiveStep(0);
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Please Enter Property Address !",
                    });
                }else{
                    setSaleData(true);
                    setBuyData(false);
                    CommonApi.GetSalesAddiFee().then(res=>{
                        // console.log(res.data.data);
                        setSalesAddiFee(res.data.data);
                    });
                    CommonApi.GetSaleStdFee(property_value).then(res=>{
                        setSaleStdFee(res.data.data)
                    })
                }
            }
        }
        //last Step 
        if (activeStep == steps.length - 1) {
          //Last Submit Api Call Here
          if(data.form_type == 'buy'){
            Loading()
            CommonApi.PurchasePropertyRequest(data).then(res=>{
                Swal.fire({
                    icon:'success',
                    title: "Thanks for contacting us! We will be in touch with you shortly.",
                    showClass: {
                      popup: `
                        animate__animated
                        animate__fadeInUp
                        animate__faster
                      `
                    },
                    hideClass: {
                      popup: `
                        animate__animated
                        animate__fadeOutDown
                        animate__faster
                      `
                    }
                });
                setActiveStep(0);
                SwalClose();
              })
          }else if(data.form_type == 'sale'){
            Loading();
            CommonApi.salePropertyRequest(data).then(res=>{
                Swal.fire({
                    icon:'success',
                    title: "Thanks for contacting us! We will be in touch with you shortly.",
                    showClass: {
                      popup: `
                        animate__animated
                        animate__fadeInUp
                        animate__faster
                      `
                    },
                    hideClass: {
                      popup: `
                        animate__animated
                        animate__fadeOutDown
                        animate__faster
                      `
                    }
                });
                setActiveStep(0);
                SwalClose();
              })
          }
        } else {
            if(data.property_address == ''){
                setActiveStep(0);
            }else{
                setActiveStep(activeStep + 1);
            }
        }
      };


      const handleBack = () => {
        setActiveStep(activeStep - 1);
      };

      // buy request display Addi fees &std fee data
      const BuyAddiFeesCompo =()=>{
        const { control ,setValue} = useFormContext();
        useEffect(() => { 
            setValue("std_fee", purchaseStdFee.fees);
        }, [setValue]);
        return(
            <>
            <Controller
                control={control}
                name="std_fee"
                render={({ field }) => (
                    <input
                    type='hidden'
                    {...field}
                    />
                )}
            />
            <h4 className='d-flex justify-content-center mt-4'>Your conveyancing quote for the purchase</h4>
            {/* <div className='d-flex justify-content-center mt-4'>
                <Table style={{width:'70%'}}>
                    <thead>
                        <tr style={{'fontWeight':'bold','fontSize':'16px',textAlign:'center'}}>
                            <th >SOLICITORS FEES</th>
                            <th >£{purchaseStdFee? purchaseStdFee.fees:null}
                            </th>
                        </tr>
                    </thead>
                </Table>
            </div> */}

            <div className='d-flex justify-content-center mt-4'>
                <Table style={{width:'70%'}}>
                    <thead>
                        <tr style={{'fontWeight':'bold','fontSize':'16px'}}>
                            <th >DISBURSEMENTS</th>
                            <th >FEE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {purchaseAddiFee?purchaseAddiFee.map((value,index)=>{
                            return(
                                <tr key={index}>
                                    <td>{value.title}</td>
                                    <td>{value.notes}</td>
                                </tr>
                            )
                        }):null
                    }
                    </tbody>
                </Table>
            </div>

            {/* <div className='d-flex justify-content-center mt-4'>
                <Table style={{width:'70%'}}>
                    <thead>
                        <tr style={{'fontWeight':'bold','fontSize':'16px',textAlign:'center'}}>
                            <th >TOTAL FEES</th>
                            <th >£{purchaseStdFee? purchaseStdFee.total:null} inc VAT</th>
                        </tr>
                    </thead>
                </Table>
            </div> */}

            <span className='d-flex justify-content-center mt-4'style={{fontWeight:'bolder',color:'#111'}}>Further costs may be applicable if your property is:</span>
            <div className='d-flex justify-content-center mt-2'>
                <ul>
                    <li>Purchased as a company.</li>
                    <li>A New Build.</li>
                    <li>Purchased/Sold as shared ownership.</li>
                    <li>Leasehold.</li>
                    <li>Not registered with the land registry.</li>
                    <li>Purchased with a gift of money from a third party.</li>
                    <li>Purchased using a help to buy or lifetime ISA.</li>
                    <li>Purchased at auction.</li>
                </ul>
            </div>
            </>
        )
      } 

      //sale request display Addi fees & std fee Data 
      const SaleAddiFeesCompo = ()=>{
        const { control ,setValue} = useFormContext();
        useEffect(() => { 
            setValue("std_fee", saleStdFee.fees);
        }, [setValue]);
        return(
            <>
            <Controller
                control={control}
                name="std_fee"
                render={({ field }) => (
                    <input
                    type='hidden'
                    {...field}
                    />
                )}
            />
            <h4 className='d-flex justify-content-center mt-4'>Your conveyancing quote for the sale</h4>
            {/* <div className='d-flex justify-content-center mt-4'>
                <Table style={{width:'70%'}}>
                    <thead>
                        <tr style={{'fontWeight':'bold','fontSize':'16px',textAlign:'center'}}>
                            <th >SOLICITORS FEES</th>
                            <th >£{saleStdFee? saleStdFee.fees:null}</th>
                        </tr>
                    </thead>
                </Table>
            </div> */}

            <div className='d-flex justify-content-center mt-4'>
                <Table style={{width:'70%'}}>
                    <thead>
                        <tr style={{'fontWeight':'bold','fontSize':'16px'}}>
                            <th >DISBURSEMENTS</th>
                            <th >FEE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {salesAddiFee?salesAddiFee.map((value,index)=>{
                            return(
                                <tr key={index}>
                                    <td>{value.title}</td>
                                    <td>{value.notes}</td>
                                </tr>
                            )
                        }):null
                    }
                    </tbody>
                </Table>
            </div>

            {/* <div className='d-flex justify-content-center mt-4'>
                <Table style={{width:'70%'}}>
                    <thead>
                        <tr style={{'fontWeight':'bold','fontSize':'16px',textAlign:'center'}}>
                            <th >TOTAL FEES</th>
                            <th >£{saleStdFee? saleStdFee.total:null} inc VAT</th>
                        </tr>
                    </thead>
                </Table>
            </div> */}

            <span className='d-flex justify-content-center mt-4'style={{fontWeight:'bolder',color:'#111'}}>Further costs may be applicable if your property is:</span>
            <div className='d-flex justify-content-center mt-2'>
                <ul>
                    <li>Purchased as a company.</li>
                    <li>A New Build.</li>
                    <li>Purchased/Sold as shared ownership.</li>
                    <li>Leasehold.</li>
                    <li>Not registered with the land registry.</li>
                    <li>Purchased with a gift of money from a third party.</li>
                    <li>Purchased using a help to buy or lifetime ISA.</li>
                    <li>Purchased at auction.</li>
                </ul>
            </div>
            </>
        )
      }
      //step 2 Main Component
      const Step2Main = ()=>{
        return(
            <>
                {/* <h4 className='d-flex justify-content-center mt-4'>Your Conveyancing Quote</h4> */}
                {buyData ? <BuyAddiFeesCompo/>:null}
                {saleData ? <SaleAddiFeesCompo/>:null}
                {/* {step2Empty ? <Step2Empty/> :null} */}
                
                <span className='d-flex justify-content-center mt-4'style={{fontWeight:'bolder',color:'#111'}}> All The above fees will be subject to VAT @ 20% and are exclusive of disbursements. We will be able to provide accurate costs after an initial consultation</span>

                <span className='d-flex justify-content-center mt-4'>The above quote does not include any Stamp Duty that may be be applicable.</span>
            </>
        )
      }
  return (
    <React.Fragment>
        <Container style={{marginTop:"5%",marginBottom:'8%',height:"auto"}}>
        <div className='d-flex justify-content-center'>
            <div style={{width:'100%'}}>
                <h4 className='d-flex justify-content-center' style={{marginTop:'50px'}}>GET A CONVEYANCING QUOTE</h4>
                
                <Box sx={{ width: '100%',marginTop:'15px' }} className="stepForm">
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                    <Step key={label} sx={{
                        '& .MuiStepLabel-root .Mui-completed': {
                          color: '#192a56', // circle color (COMPLETED)
                        },
                        '& .MuiStepLabel-root .Mui-active': {
                          color: '#192a56', // circle color (ACTIVE)
                        },
                      }}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                    ))}
                </Stepper>
                </Box>

                {activeStep === steps.length ? (
                    <Typography>Thanks for contacting us! We will be in touch with you shortly.</Typography>
                ):(
                    <>
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(handleNext)}>
                        {/* {getStepContent(activeStep)}
                         */}
                         {activeStep == 0 ?<PropertyDetails/>:<YourDetails/>}
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 ,justifyContent:'center',marginBottom:"25px"}}>
                        <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            variant="contained"
                            sx={{marginRight:'5px',background:'#9BB8CD','&:hover': {
                                backgroundColor: '#fff',
                                color: '#192a56',
                            },}}
                        >
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            sx={{backgroundColor:'#192a56','&:hover': {
                                backgroundColor: '#fff',
                                color: '#3c52b2',
                            },}}
                            // onClick={handleNext}
                            type="submit"
                        >
                            {activeStep === 0 ? "GET QUOTE" :"SUBMIT"}
                        </Button>
                        </Box>
                        </form>
                    </FormProvider>
                    </>
                )}
            </div>
        </div>
        </Container>
    </React.Fragment>
  )
}

export default ConveyancingQuote