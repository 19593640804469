import React, { Component } from 'react';
import { Container, Row, Col, Table, Card, CardBody, Button, Form, FormGroup, Label, Input, FormText,
Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, CardTitle } from "reactstrap";
//import MiniWidgets from "./MiniWidgets";
//import RevenueAnalytics from "./RevenueAnalytics";
import CommonApi from '../../apis/CommonApi';
import FileUpload from '../Common/FileUpload';
import FileUploadPurchase from '../Common/FileUploadPurchase';


//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Tooltip } from '@mui/material';
import { DateFormat } from '../../helpers/globalfunctions';
//import Stats from '../../apis/Stats';
let isMounted = false;
class CustomerDashBoard extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state={
            breadcrumbItems : [
                { title : "Dashboard", link : "#" },                
            ],
            TotalSale:'',
            TotalPurchase:'',
            SalePendingCount:'',
            SaleCompletedCount:'',
            PurchaseCompletedCount:'',
            PurchasePendingCount:'',
            SaleList:[],
            PurchaseList:[],
            ViewSaleDetailModal:false,
            ViewPurchaseDetailModal:false,
            ViewSaleDetail:'',
            ViewPurchaseDetail:'',
            Salequestions:[],
            Purchasequestions:[],
            StepList:[],
            UploadViewModal:false,
            sale_id:'',
            attachmentList:[],
            UploadViewModal:false,            
            purchase_id:'',
            attachmentList:[],
            ModalType:''
        };

        this.CloseUploadModal=this.CloseUploadModal.bind(this);
        this.ClosePurchaseUploadModal=this.ClosePurchaseUploadModal.bind(this);
    }

    componentDidMount(){
        this._isMounted = true;
        // this.getDashboardCount();
        this.getDashboardData();
        
    }

    //viewDetail Modal open/close
    OpenViewSaleDetailModal = ()=>this.setState({ViewSaleDetailModal:true});
    CloseViewSaleDetailModal = ()=>this.setState({ViewSaleDetailModal:false});
    OpenViewPurchaseDetailModal = ()=>this.setState({ViewPurchaseDetailModal:true});
    CloseViewPurchaseDetailModal = ()=>this.setState({ViewPurchaseDetailModal:false});

    //sales upload model
    OpenUploadModal = ()=>this.setState({UploadViewModal:true});
    CloseUploadModal(){this.setState({UploadViewModal:false})};

    //purchase upload model
    OpenPurchaseUploadModal = ()=>this.setState({UploadViewModal:true});
    ClosePurchaseUploadModal(){this.setState({UploadViewModal:false})};


    /*getDashboardCount() {
        CommonApi.GetCustomerDashBoard().then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({TotalSale:response.data.data.saleTotal});
                    this.setState({TotalPurchase:response.data.data.purchaseTotal});
                    this.setState({SalePendingCount:response.data.data.salePending});
                    this.setState({SaleCompletedCount:response.data.data.saleCompleted});
                    this.setState({PurchasePendingCount:response.data.data.puchasePending});
                    this.setState({PurchaseCompletedCount:response.data.data.purchaseCompleted});
                }
            }
        });
    }*/

    getDashboardData() {
        CommonApi.GetCustomerData().then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({SaleList:response.data.data.Sale});
                    this.setState({PurchaseList:response.data.data.Purchase});
                }
            }
        });
    }

    SaleDetails(id){
        this.OpenViewSaleDetailModal();
        CommonApi.SalePropertyFetchSteps(id).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({StepList:response.data.data.result})
                }
            }
        });
    }

    //Purchase detail
    PurchaseDetails(id){
        this.OpenViewPurchaseDetailModal();
        CommonApi.PurchasePropertyFetchSteps(id).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({StepList:response.data.data.result})
                }
            }
        });
    }

    //sales upload modal view
    async UploadModal(id){
        this.OpenUploadModal();
        await this.setState({ModalType:'Sale'});
        await this.setState({sale_id: id});
        CommonApi.getSaleFiles(id).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({attachmentList:response.data.data.result.attachments})
                }
            }
        });
    }

    //upload modal purchase
    async UploadModalPurchase(id){
        // console.log('hello')
        this.OpenPurchaseUploadModal();
        await this.setState({ModalType:'Purchase'});
        await this.setState({purchase_id: id});
        CommonApi.getPurchaseFiles(id).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({attachmentList:response.data.data.result.attachments})
                }
            }
        });
    }

    render() {        
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title="Dashboard" breadcrumbItems={this.state.breadcrumbItems} />

                        {/* <Row>
                            <Col lg={4} md={4}>
                                <Card>
                                    <CardBody style={{background:'#C2D9FF'}}>
                                        <div className='d-flex justify-content-between'>
                                            <h6 style={{color:'#164863'}}>Total sale requests</h6>
                                            <i className='ri-stack-line' style={{fontSize:'18px',color:'#164863'}}></i>    
                                        </div>
                                        <h4 style={{color:'#164863'}}>{this.state.TotalSale}</h4>
                                        <h5 style={{borderBottom:'1px solid #164863',marginTop:'20px'}}></h5>
                                    </CardBody>
                                </Card>
                            </Col>
                
                            <Col lg={4} md={4}>
                                <Card>
                                    <CardBody style={{background:'#D0E7D2'}}>
                                        <div className='d-flex justify-content-between'>
                                            <h6 style={{color:'#618264'}}>Total sale completed requests</h6>
                                            <i className='ri-stack-line' style={{fontSize:'18px',color:'#618264'}}></i>    
                                        </div>
                                        <h4 style={{color:'#618264'}}>{this.state.SaleCompletedCount}</h4>
                                        <h5 style={{borderBottom:'1px solid #618264',marginTop:'20px'}}></h5>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg={4} md={4}>
                                <Card>
                                    <CardBody style={{background:'#ECF4D6'}}>
                                        <div className='d-flex justify-content-between'>
                                            <h6 style={{color:'#5F6F52'}}>Total sale pending requests</h6>
                                            <i className=' ri-stack-line' style={{fontSize:'18px',color:'#5F6F52'}}></i>    
                                        </div>
                                        <h4 style={{color:'#5F6F52'}}>{this.state.SalePendingCount}</h4>
                                        <h5 style={{borderBottom:'1px solid #5F6F52',marginTop:'20px'}}></h5>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> */}

                        {/* <Row>
                            <Col lg={4} md={4}>
                                <Card>
                                    <CardBody style={{background:'#FECDA6'}}>
                                        <div className='d-flex justify-content-between'>
                                            <h6 style={{color:'#FF5B22'}}>Total purchase request</h6>
                                            <i className=' ri-stack-line' style={{fontSize:'18px',color:'#FF5B22'}}></i>    
                                        </div>
                                        <h4 style={{color:'#FF5B22'}}>{this.state.TotalPurchase}</h4>
                                        <h5 style={{borderBottom:'1px solid #FF5B22',marginTop:'20px'}}></h5>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg={4} md={4}>
                                <Card>
                                    <CardBody style={{background:'#D0E7D2'}}>
                                        <div className='d-flex justify-content-between'>
                                            <h6 style={{color:'#618264'}}>Total purchase completed requests</h6>
                                            <i className='ri-stack-line' style={{fontSize:'18px',color:'#618264'}}></i>    
                                        </div>
                                        <h4 style={{color:'#618264'}}>{this.state.PurchaseCompletedCount}</h4>
                                        <h5 style={{borderBottom:'1px solid #618264',marginTop:'20px'}}></h5>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg={4} md={4}>
                                <Card>
                                    <CardBody style={{background:'#ECF4D6'}}>
                                        <div className='d-flex justify-content-between'>
                                            <h6 style={{color:'#5F6F52'}}>Total purchase pending requests</h6>
                                            <i className=' ri-stack-line' style={{fontSize:'18px',color:'#5F6F52'}}></i>    
                                        </div>
                                        <h4 style={{color:'#5F6F52'}}>{this.state.PurchasePendingCount}</h4>
                                        <h5 style={{borderBottom:'1px solid #5F6F52',marginTop:'20px'}}></h5>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> */}

                        <div className="table-responsive">
                            <Card>
                                <CardBody>
                                    <h4 className='d-flex justify-content-center'>Purchase</h4>
                                    <Table className="mb-0">
                                        <thead className="bg-primary text-white">
                                            <tr>
                                                <th>Property Address</th>
                                                <th>Property Value</th>
                                                {/* <th>Solicitor Fee</th>
                                                <th>Disbursement Fee</th>
                                                <th>Total</th> */}
                                                <th>Agent company name</th>
                                                <th>estate agent name </th>
                                                <th>solicitor company name</th>
                                                <th>conveyancer name</th>
                                                <th style={{width:'35%'}}>Step Track</th>
                                                {/* <th>Final Status </th>
                                                <th>Won / Lost</th> */}
                                                <th>Steps</th>
                                                <th>Upload</th>
                                            </tr>
                                        </thead>
                                        <tbody>  
                                        {this.state.PurchaseList ?(this.state.PurchaseList.map((value,index)=>{
                                                return(
                                                    <>
                                                        <tr key={index}>
                                                            <td>{value.property_address}</td>
                                                            <td>£{value.property_value}</td>
                                                            {/* <td>£{value.std_fee}</td>
                                                            <td>£{value.disbursement_fee_total}</td>
                                                            <td>£{value.final_total}</td> */}
                                                            <td>{value.agent_company}</td>
                                                            <td>{value.agent_name}</td>
                                                            <td>{value.soli_company}</td>
                                                            <td>{value.conveyancer_name}</td>
                                                            <td>
                                                                <div className="flat pipeline">
                                                                    {value.purchase_step ?(value.purchase_step.map((option,index)=>{
                                                                        return <Tooltip arrow title={option.step_name}><a key={index} text={option.step_name}className={option.status=='Pending'?'pending-pipeline':(option.status=='Completed')?'completed-pipeline'
                                                                        :(option.status=='Cancel')?'cancel-pipeline':''}><span></span></a></Tooltip>
                                                                    })):null}
                                                                </div>
                                                            </td>
                                                            {/* <td style={value.final_status == 'Completed'? {background:'#5D9C59'}:value.final_status == 'Pending'?{background:'#F4D160'}:null}>{value.final_status}</td>

                                                            <td style={value.win_lose == 'Won'? {background:'#5D9C59'}:value.win_lose=='Lost'?{background:'#EF4040'}:null}>{value.win_lose}</td> */}                                                            

                                                            <td><i className=" mdi mdi-eye" style={{fontSize:'18px',color:'#192a56'}} onClick={()=>this.PurchaseDetails(value.id)}></i></td>

                                                            <td>{value.final_status == 'Cancel'?'':<i className="ri-upload-2-line" style={{fontSize:'18px',color:'#192a56'}} onClick={()=>this.UploadModalPurchase(value.id)}></i>}</td>
                                                        </tr>
                                                    </>
                                                )
                                            })
                                            ):null
                                        }
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </div>

                        <div className="table-responsive">
                            <Card>
                                <CardBody>
                                    <h4 className='d-flex justify-content-center'>Sale</h4>
                                    <Table className="mb-0">
                                        <thead className="bg-primary text-white">
                                            <tr>
                                                <th>Property Address</th>
                                                <th>Property Value</th>
                                                {/* <th>Solicitor Fee</th>
                                                <th>Disbursement Fee</th>
                                                <th>Total</th> */}
                                                <th>Agent company name</th>
                                                <th>estate agent name </th>
                                                <th>solicitor company name</th>
                                                <th>conveyancer name </th>
                                                <th style={{width:'30%'}}>Step Track</th>
                                                {/* <th>Final Status </th>
                                                <th>Won / Lost</th> */}
                                                <th>Steps</th>
                                                <th>Upload</th>
                                            </tr>
                                        </thead>
                                        <tbody>  
                                            {this.state.SaleList ?(this.state.SaleList.map((value,index)=>{
                                                return(
                                                    <>
                                                        <tr key={index}>
                                                            <td>{value.property_address}</td>
                                                            <td>£{value.property_value}</td>
                                                            {/* <td>£{value.std_fee}</td>
                                                            <td>£{value.disbursement_fee_total}</td>
                                                            <td>£{value.final_total}</td> */}
                                                            <td>{value.agent_company}</td>
                                                            <td>{value.agent_name}</td>
                                                            <td>{value.soli_company}</td>
                                                            <td>{value.conveyancer_name}</td>
                                                            <td>
                                                                <div className="flat pipeline">
                                                                    {value.sales_step ?(value.sales_step.map((option,index)=>{
                                                                        return <Tooltip arrow title={option.step_name}><a key={index} text={option.step_name}className={option.status=='Pending'?'pending-pipeline':(option.status=='Completed')?'completed-pipeline'
                                                                        :(option.status=='Cancel')?'cancel-pipeline':''}><span></span></a></Tooltip>
                                                                    })):null}
                                                                </div>
                                                            </td>
                                                            {/* <td style={value.final_status == 'Completed'? {background:'#5D9C59'}:value.final_status == 'Pending'?{background:'#F4D160'}:null}>{value.final_status}</td>

                                                            <td style={value.win_lose == 'Won'? {background:'#5D9C59'}:value.win_lose=='Lost'?{background:'#EF4040'}:null}>{value.win_lose}</td> */}
                                                            <td><i className=" mdi mdi-eye" style={{fontSize:'18px',color:'#192a56'}} onClick={()=>this.SaleDetails(value.id)}></i></td>

                                                            <td>{value.final_status == 'Cancel' ? '':<i className="ri-upload-2-line" style={{fontSize:'18px',color:'#192a56'}} onClick={()=>this.UploadModal(value.id)}></i>}</td>

                                                        </tr>
                                                    </>
                                                )
                                            })
                                            ):null
                                            }
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </div>

                         {/* View Sale Detail Modal */}
                         {/* <Modal isOpen={this.state.ViewSaleDetailModal} size='xl' >
                                        <ModalHeader className="modal-header" toggle={this.CloseViewSaleDetailModal}> All Detail </ModalHeader>
                                        <ModalBody>
                                            {this.state.ViewSaleDetail? (this.state.ViewSaleDetail.map((value,index)=>{
                                                return (
                                                    <>
                                                        <Row key={index} className="mt-4">
                                                            <Col md={6}>
                                                                <Card>
                                                                    <CardTitle className="d-flex justify-content-center card-title" >Customer Details</CardTitle>
                                                                    <CardBody>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Property Address :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.property_address}</h6>
                                                                            </Col>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Property Value :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>£{value.property_value}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Freehold/Leasehold :</h6>
                                                                            </Col>
                                                                            <Col lg={5} md={5}>
                                                                                <h6>{value.hold_type}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Title :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.title_fc}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Surname :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.surname_fc}</h6>
                                                                            </Col>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>First Name :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.first_name_fc}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Last Name :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.last_name_fc}</h6>
                                                                            </Col>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Telephone :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.telephone_fc}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Email :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.email_fc}</h6>
                                                                            </Col>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Card>
                                                                    <CardTitle className="d-flex justify-content-center card-title">Disbursements</CardTitle>
                                                                    <div className="d-flex" style={{background:'#192a56'}}>
                                                                            <Col lg={6} md={6}>
                                                                                <h5 style={{color:'#fff'}}>Solicitor Fee :</h5>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h5 style={{color:'#fff',textAlign:'end'}}>£{value.std_fee}</h5>
                                                                            </Col>
                                                                        </div>
                                                                    <Table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Title</th>
                                                                                <th>Fees</th>
                                                                                <th>Vat %</th>
                                                                                <th>Total</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                    {value.sales_disbursements ?(value.sales_disbursements.map((option,index)=>{
                                                                        return (
                                                                            <>
                                                                                <tr key={index}>
                                                                                    <td>{option.title}</td>
                                                                                    <td>£{option.disbursement_charge}</td>
                                                                                    <td>{option.vat}%</td>
                                                                                    <td>£{option.disbursement_total}</td>
                                                                                </tr>
                                                                            </>
                                                                        )
                                                                    })):null}
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td>Total</td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td>£{value.disbursement_fee_total}</td>
                                                                        </tr>
                                                                        <tr className='card-title'>
                                                                            <td className='final-total-text'>Final Total</td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td className='final-total-text'>£{value.final_total == 0 ? value.std_fee:value.final_total }</td>
                                                                        </tr>
                                                                    </tfoot>
                                                                    </Table>
                                                                </Card>
                                                            </Col>

                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                            <Card>
                                                                <CardTitle className="d-flex justify-content-center" style={{background:'#192a56',color:'#fff'}}>Questions  Details</CardTitle>
                                                                    <CardBody>
                                                                        {this.state.Salequestions? (this.state.Salequestions.map((option,index)=>{
                                                                            return (
                                                                                <>
                                                                                    <div className="d-flex border-bottom mb-2" key={index}>
                                                                                        <Col lg={1} md={1}>
                                                                                            <h6>{option.que_no} </h6>
                                                                                        </Col>
                                                                                        <Col lg={8} md={8}>
                                                                                            <h6>{option.question} </h6>
                                                                                        </Col>
                                                                                        <Col lg={3} md={3}>
                                                                                            {option.que_no == 1 ? <h6>{value.located_eng}</h6>:option.que_no == 2 ? <h6>{value.buy_sell_auction}</h6>:option.que_no == 3 ? <h6>{value.hold_type}</h6>:option.que_no == 4 ? <h6>{value.land_registry}</h6>:option.que_no == 5 ? <h6>{value.mortgage}</h6>:''}
                                                                                        </Col>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })):null}
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )

                                            })):null}
                                    </ModalBody>
                        </Modal> */}

                        <Modal isOpen={this.state.ViewSaleDetailModal} size='xl' >
                            <ModalHeader className="modal-header" toggle={this.CloseViewSaleDetailModal}>Sale property step </ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col md={6} sm={6} lg={6}>
                                        <Table>
                                        <thead>
                                            <tr style={{'fontWeight':'bold','fontSize':'16px'}}>
                                                <th style={{width:'15%'}}>Step No</th>
                                                <th style={{width:'65%'}}>Step Name</th>
                                                <th style={{width:'20%'}}>Completed Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.StepList ?(this.state.StepList.map((stepValue,index)=>{
                                                    return(stepValue.step_no >= 2 && stepValue.step_no <= 6)? <tr key={index}>
                                                    <td>{stepValue.step_no}</td>
                                                    <td>{stepValue.step_name}</td>
                                                    <td>{stepValue.step_submit_date ? DateFormat(stepValue.step_submit_date):''}</td>
                                                </tr>:null 
                                                })):null
                                            }
                                        </tbody>
                                        </Table>
                                    </Col>
                                    <Col md={6} sm={6} lg={6}>
                                        <Table>
                                        <thead>
                                            <tr style={{'fontWeight':'bold','fontSize':'16px'}}>
                                                <th style={{width:'15%'}}>Step No</th>
                                                <th style={{width:'65%'}}>Step Name</th>
                                                <th style={{width:'20%'}}>Completed Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.StepList ?(this.state.StepList.map((stepValue,index)=>{
                                                    return(stepValue.step_no >= 7 && stepValue.step_no <= 11)? <tr key={index}>
                                                    <td>{stepValue.step_no}</td>
                                                    <td>{stepValue.step_name}</td>
                                                    <td>{stepValue.step_submit_date ? DateFormat(stepValue.step_submit_date):''}</td>
                                                </tr>:null 
                                                })):null
                                            }
                                        </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </ModalBody>
                        </Modal>

                        {/* View Purchase Detail Modal */}
                        {/* <Modal isOpen={this.state.ViewPurchaseDetailModal} size='xl' >
                                        <ModalHeader className="modal-header" toggle={this.CloseViewPurchaseDetailModal}> All Detail </ModalHeader>
                                        <ModalBody>
                                            {this.state.ViewPurchaseDetail? (this.state.ViewPurchaseDetail.map((value,index)=>{
                                                return (
                                                    <>
                                                        <Row key={index} className="mt-4">
                                                            <Col md={6}>
                                                                <Card>
                                                                    <CardTitle className="d-flex justify-content-center">Customer Details</CardTitle>
                                                                    <CardBody>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Property Address :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.property_address}</h6>
                                                                            </Col>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Property Value :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>£{value.property_value}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Freehold/Leasehold :</h6>
                                                                            </Col>
                                                                            <Col lg={5} md={5}>
                                                                                <h6>{value.hold_type}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Title :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.title_fc}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Surname :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.surname_fc}</h6>
                                                                            </Col>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>First Name :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.first_name_fc}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Last Name :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.last_name_fc}</h6>
                                                                            </Col>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Telephone :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.telephone_fc}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Email :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.email_fc}</h6>
                                                                            </Col>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Card>
                                                                    <CardTitle className="d-flex justify-content-center">Disbursements</CardTitle>
                                                                    <div className="d-flex" style={{background:'#192a56'}}>
                                                                            <Col lg={6} md={6}>
                                                                                <h5 style={{color:'#fff'}}>Solicitor Fee :</h5>
                                                                            </Col>
                                                                            <Col lg={6} md={6} style={{textAlign:'end'}}>
                                                                                <h5 style={{color:'#fff'}}>£{value.std_fee}</h5>
                                                                            </Col>
                                                                        </div>
                                                                    <Table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Title</th>
                                                                                <th>Fees</th>
                                                                                <th>Vat %</th>
                                                                                <th>Total</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                    {value.purchase_disbursements ?(value.purchase_disbursements.map((option,index)=>{
                                                                        return (
                                                                            <>
                                                                                <tr key={index}>
                                                                                    <td>{option.title}</td>
                                                                                    <td>£{option.disbursement_charge}</td>
                                                                                    <td>{option.vat}%</td>
                                                                                    <td>£{option.disbursement_total}</td>
                                                                                </tr>
                                                                            </>
                                                                        )
                                                                    })):null}
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td>Total</td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td>£{value.disbursement_fee_total}</td>
                                                                        </tr>
                                                                        <tr className='card-title'>
                                                                            <td className='final-total-text'>Final Total</td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td className='final-total-text'>£{value.final_total == 0 ? value.std_fee:value.final_total }</td>
                                                                        </tr>
                                                                    </tfoot>
                                                                    </Table>
                                                                </Card>
                                                            </Col>

                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                            <Card>
                                                                <CardTitle className="d-flex justify-content-center"style={{background:'#192a56',color:'#fff'}}>Questions  Details</CardTitle>
                                                                    <CardBody>
                                                                        {this.state.Purchasequestions? (this.state.Purchasequestions.map((option,index)=>{
                                                                            return (
                                                                                <>
                                                                                    <div className="d-flex border-bottom mb-2" key={index}>
                                                                                        <Col lg={1} md={1}>
                                                                                            <h6>{option.que_no} </h6>
                                                                                        </Col>
                                                                                        <Col lg={8} md={8}>
                                                                                            <h6>{option.question} </h6>
                                                                                        </Col>
                                                                                        <Col lg={3} md={3}>
                                                                                            {option.que_no == 1 ? <h6>{value.located_eng}</h6>:option.que_no == 2 ? <h6>{value.buy_sell_auction}</h6>:option.que_no == 3 ? <h6>{value.as_company}</h6>:option.que_no == 4 ? <h6>{value.hold_type}</h6>:option.que_no == 5 ? <h6>{value.new_build}</h6>:option.que_no == 6 ? <h6>{value.land_registry}</h6>:option.que_no == 7 ? <h6>{value.gift_money_tp}</h6>:option.que_no == 8 ? <h6>{value.help_buy_isa}</h6>:option.que_no == 9 ? <h6>{value.mortgage}</h6>:''}
                                                                                        </Col>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })):null}
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )

                                            })):null}
                                    </ModalBody>
                        </Modal> */}

                        <Modal isOpen={this.state.ViewPurchaseDetailModal} size='xl' >
                            <ModalHeader className="modal-header" toggle={this.CloseViewPurchaseDetailModal}>Purchase property step </ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col md={6} sm={6} lg={6}>
                                        <Table>
                                        <thead>
                                            <tr style={{'fontWeight':'bold','fontSize':'16px'}}>
                                                <th style={{width:'15%'}}>Step No</th>
                                                <th style={{width:'65%'}}>Step Name</th>
                                                <th style={{width:'20%'}}>Completed Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.StepList ?(this.state.StepList.map((stepValue,index)=>{
                                                    return(stepValue.step_no >= 2 && stepValue.step_no <= 8)? <tr key={index}>
                                                    <td>{stepValue.step_no}</td>
                                                    <td>{stepValue.step_name}
                                                        <div className="d-flex">
                                                            Notes: ({stepValue.note})
                                                        </div>
                                                    </td>
                                                    <td>{stepValue.step_submit_date? DateFormat(stepValue.step_submit_date):''}</td>
                                                </tr>:null 
                                                })):null
                                            }
                                        </tbody>
                                        </Table>
                                    </Col>
                                    <Col md={6} sm={6} lg={6}>
                                        <Table>
                                        <thead>
                                            <tr style={{'fontWeight':'bold','fontSize':'16px'}}>
                                                <th style={{width:'15%'}}>Step No</th>
                                                <th style={{width:'65%'}}>Step Name</th>
                                                <th style={{width:'20%'}}>Completed Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.StepList ?(this.state.StepList.map((stepValue,index)=>{
                                                    return(stepValue.step_no >= 9 && stepValue.step_no <= 16)? <tr key={index}>
                                                    <td>{stepValue.step_no}</td>
                                                    <td>{stepValue.step_name}
                                                        <div className="d-flex">
                                                            Notes: ({stepValue.note})
                                                        </div>
                                                    </td>
                                                    <td>{stepValue.step_submit_date ? DateFormat(stepValue.step_submit_date):''}</td>
                                                </tr>:null 
                                                })):null
                                            }
                                        </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </ModalBody>
                        </Modal>

                        {/* Upload modal */}
                        {this.state.ModalType == 'Purchase' ?
                        <FileUploadPurchase 
                            data={this.state}
                            onClick={this.ClosePurchaseUploadModal}
                            ModalType='Purchase'
                        />
                        :<FileUpload 
                            data={this.state}
                            onClick={this.CloseUploadModal}
                            ModalType='Sale'
                        />}

                        {/* sales Upload modal */}

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default CustomerDashBoard;
