import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label, Input ,Container, CustomInput, InputGroup, Form, InputGroupAddon } from "reactstrap";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import CommonApi from '../../apis/CommonApi';
import Swal from 'sweetalert2';
import {Loading,SwalClose,ErrorMessage,ErrorPopup,SuccessPopup} from "../../helpers/globalfunctions";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems : [
        { title : "Profile", link : "#" },
        { title : "Change Password", link : "#" },
      ],
      error_message: null,
      errors: null
    };
    this.handleSubmit = this.handleSubmit.bind(this);    
  }

  handleSubmit(e){
    e.preventDefault();
    Loading()
    const data = new FormData(event.target);    
    CommonApi.changePassword(data).then(response => {
        SwalClose()
        if (response.data) {                
            SuccessPopup(response.data.message)            
        }
    }).catch((error) => {
        SwalClose()
        if (error.response) {                
            ErrorPopup(error.response)
        }

    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
          
          

          <Breadcrumbs title="Profile" breadcrumbItems={this.state.breadcrumbItems} />
            <Row>
                <Col xl="12">
                    <Card>
                        <CardBody>
                            <h4 className="card-title">Change Password</h4>                            
                            <Form className="needs-validation" method="post" id="tooltipForm" onSubmit={this.handleSubmit}>
                                <Row className="mt-4">
                                    <Col md="4">
                                        <FormGroup className="position-relative">
                                            <Label htmlFor="old_password">Old Password <span style={{color:'red'}}>*</span></Label>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                <span className="input-group-text" id="old_password"><i className="ri-lock-password-fill align-middle"></i></span>
                                                </InputGroupAddon>
                                                <Input type="password" className="form-control" name="old_password" id="old_password" placeholder="" required/>                                                
                                            </InputGroup>                                                                                                    
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup className="position-relative">
                                            <Label htmlFor="password">New Password<span style={{color:'red'}}>*</span></Label>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                <span className="input-group-text" id="password"><i className="ri-lock-password-fill align-middle"></i></span>
                                                </InputGroupAddon>
                                                <Input type="password" className="form-control" id="password" name="password" placeholder="" required/>                                                
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup className="position-relative">
                                            <Label htmlFor="password_confirmation">Confirm Password<span style={{color:'red'}}>*</span></Label>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                <span className="input-group-text" id="password_confirmation"><i className="ri-lock-password-fill align-middle"></i></span>
                                                </InputGroupAddon>
                                                <Input type="password" className="form-control" id="password_confirmation" placeholder="" name="password_confirmation" required/>                                                
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>                                
                                <Button className="align-middle" color="primary" type="submit">Submit</Button>
                            </Form>
                        </CardBody>
                    </Card>
                </Col> 
            </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default Profile;
