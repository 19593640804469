import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from 'react-i18next';

import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader
} from "../../store/actions";

class UserSidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };

    }

    componentDidMount() {
        this.initMenu();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {

            if (this.props.type !== prevProps.type) {
                this.initMenu();
            }

        }
    }

    initMenu() {
            new MetisMenu("#side-menu");

            var matchingMenuItem = null;
            var ul = document.getElementById("side-menu");
            var items = ul.getElementsByTagName("a");
            for (var i = 0; i < items.length; ++i) {
                if (this.props.location.pathname === items[i].pathname) {
                    matchingMenuItem = items[i];
                    break;
                }
            }
            if (matchingMenuItem) {
                this.activateParentDropdown(matchingMenuItem);
            }
    }

    activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };

    render() {
        return (            
            <React.Fragment>
            {(localStorage.getItem("user.user_scopes")=='solicitor')?(
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li className="menu-title">{this.props.t('Menu')}</li>

                        <li>
                            <Link to="/admin-panel/solicitor-dashboard" className="waves-effect">
                                <i className="ri-dashboard-line"></i><span className="badge badge-pill badge-success float-right">3</span>
                                <span className="ml-1">{this.props.t('Dashboard')}</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/admin-panel/solicitors" className="waves-effect">
                                <i className="ri-group-2-fill"></i>
                                <span className="ml-1">{this.props.t('Company solicitors')}</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/admin-panel/sales-request" className="waves-effect">
                                <i className="ri-file-copy-2-line"></i>
                                <span className="ml-1">{this.props.t('Sale Requests')}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin-panel/purchase-request" className="waves-effect">
                                <i className=" ri-file-edit-fill"></i>
                                <span className="ml-1">{this.props.t('Purchase Requests')}</span>
                            </Link>
                        </li>

                        {/*<li>
                            <Link to="#" className="has-arrow waves-effect">
                                <i className="ri-image-add-fill"></i>
                                <span className="ml-1">{this.props.t('Card Generate')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="true">                                
                                <li><Link to="/admin-panel/beneficiary-card-generate">{this.props.t('Beneficiary Cards')}</Link></li>                                    
                            </ul>
                           
                        </li>

                        <li>
                            <Link to="#" className="has-arrow waves-effect">
                                <i className="ri-recycle-line"></i>
                                <span className="ml-1">{this.props.t('Kits Distribution Manage')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="true">                                
                                <li><Link to="/admin-panel/kit-tracking">{this.props.t('Card Scan')}</Link></li>                                    
                            </ul>
                        </li>*/}

                    </ul>
                </div>
            ):(

                (localStorage.getItem("user.user_scopes")=='customer')?(                    
                    <div id="sidebar-menu">
                        <ul className="metismenu list-unstyled" id="side-menu">
                            <li className="menu-title">{this.props.t('Menu')}</li>

                            <li>
                                <Link to="/admin-panel/customer-dashboard" className="waves-effect">
                                    <i className="ri-dashboard-line"></i><span className="badge badge-pill badge-success float-right">3</span>
                                    <span className="ml-1">{this.props.t('Dashboard')}</span>
                                </Link>
                            </li>
                            
                            
                        </ul>
                    </div>
                ):(localStorage.getItem("user.user_scopes")=='conveyancer')?(                    
                    <div id="sidebar-menu">
                        <ul className="metismenu list-unstyled" id="side-menu">
                            <li className="menu-title">{this.props.t('Menu')}</li>

                            <li>
                                <Link to="/admin-panel/conveyancer-dashboard" className="waves-effect">
                                    <i className="ri-dashboard-line"></i><span className="badge badge-pill badge-success float-right">3</span>
                                    <span className="ml-1">{this.props.t('Dashboard')}</span>
                                </Link>
                            </li>
                            
                            <li>
                            <Link to="/admin-panel/conveyancer-sales-request" className="waves-effect">
                                <i className="ri-file-copy-2-line"></i>
                                <span className="ml-1">{this.props.t('Sale Requests')}</span>
                            </Link>
                            </li>
                            <li>
                                <Link to="/admin-panel/conveyancer-purchase-request" className="waves-effect">
                                    <i className=" ri-file-edit-fill"></i>
                                    <span className="ml-1">{this.props.t('Purchase Requests')}</span>
                                </Link>
                            </li>
                            
                        </ul>
                    </div>
                ):''
            )}
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    return { ...state.Layout };
  };

export default withRouter(connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader
})(withTranslation()(UserSidebar)));
