import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";
import Profile from "../pages/Authentication/profile";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import UserDashboard from "../pages/Dashboard/UserDashboard";
import AgentDasboard from "../components/Agent/AgentDasboard";


/* Admin pages */
import USERS from "../pages/Users/user-index";


/* Report */
import SaleReport from "../pages/Reports/SaleReport";
import PurchaseReport from "../pages/Reports/PurchaseReport";


//Agent 
import SellingProperty from "../components/Agent/SellingProperty";
import BuyingProperty from "../components/Agent/BuyingProperty";
import AgentSaleReq from "../components/Agent/AgentSaleReq";
import AgentPurchaseReq from "../components/Agent/AgentPurchaseReq";
import ConveyancingQuote from "../components/Agent/ConveyancingQuote";
import SaleTrackCase from "../components/Agent/SaleTrackCase";
import PurchaseTrackCase from "../components/Agent/PurchaseTrackCase";
import AgentCreate from "../components/Agent/AgentCreate";


//Solicitor
import SolicitorDashboard from "../components/Solicitor/SolicitorDashboard";
import SaleRequest from "../components/Solicitor/SaleRequest";
import PurchaseReq from "../components/Solicitor/PurchaseReq";
import SolicitorCreate from "../components/Solicitor/SolicitorCreate";

//Customer
import CustomerDashBoard from "../components/Customer/CustomerDashBoard";

//conveyancer
import ConveyancerDashboard from "../components/Solicitor/Conveyancer/ConveyancerDashboard";
import ConveyancerPurchaseReq from "../components/Solicitor/Conveyancer/ConveyancerPurchaseReq";
import ConveyancerSaleReq from "../components/Solicitor/Conveyancer/ConveyancerSaleReq";


const isRole=localStorage.getItem("user.user_scopes");

/*if(isRole=='user'){	
	const home="/admin-panel/user-dashboard";
}else if(isRole!='user'){
	const home="/admin-panel/dashboard";
}*/

const authProtectedRoutes = [{ path: "/admin-panel/dashboard", component: Dashboard },	
	{ path: "/admin-panel/users", component: USERS },

	/* Dashboard */
	{ path: "/admin-panel/agent-dashboard", component: AgentDasboard },
	
	

	/* Reports */
	{ path: "/admin-panel/sale-report", component: SaleReport },
	{ path: "/admin-panel/purchase-report", component: PurchaseReport },
	
	{ path: "/admin-panel/sale-property", component: SellingProperty },
	{ path: "/admin-panel/purchase-property", component: BuyingProperty },
	
	//Agent	
	{ path: "/admin-panel/agent-create", component: AgentCreate },
	{ path: "/admin-panel/agent-sale", component: AgentSaleReq },
	{ path: "/admin-panel/agent-purchase", component: AgentPurchaseReq },
	{ path: "/admin-panel/conveyancing-quote", component: ConveyancingQuote },
	{ path: "/admin-panel/sale-track-case",component:SaleTrackCase},
	{ path: "/admin-panel/purchase-track-case",component:PurchaseTrackCase},

	//Solicitor
	{ path: "/admin-panel/solicitors", component: SolicitorCreate },
	{ path: "/admin-panel/solicitor-dashboard", component: SolicitorDashboard },
	{ path: "/admin-panel/sales-request", component: SaleRequest },
	{ path: "/admin-panel/purchase-request", component: PurchaseReq },

	//conveyancer
	{ path: "/admin-panel/conveyancer-dashboard", component: ConveyancerDashboard },
	{ path: "/admin-panel/conveyancer-purchase-request", component: ConveyancerPurchaseReq },
	{ path: "/admin-panel/conveyancer-sales-request", component: ConveyancerSaleReq },

	//Customer
	{ path: "/admin-panel/customer-dashboard", component: CustomerDashBoard },
	
	
	/* common */	
	{ path: "/admin-panel/profile", component: Profile },
	{ path: "/admin-panel/user-dashboard", component: UserDashboard },
	
	
	
	


	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to= "/admin-panel/login"/> },
];

const userRoutes = [];

const publicRoutes = [
	{ path: "/admin-panel/logout", component: Logout },
	{ path: "/admin-panel/login", component: Login },
	{ path: "/admin-panel/forgot-password", component: ForgetPwd },
	{ path: "/admin-panel/register", component: Register },
	{ path: "/admin-panel/auth-lock-screen", component: AuthLockScreen },
	//{ path: "/admin-panel/fms", component: FMS },
];

export { authProtectedRoutes, publicRoutes, userRoutes, isRole };
