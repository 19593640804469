import React, { useState } from 'react'
import { Table, Row, Col, Card, CardBody, Container, Button, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, CustomInput, Collapse, CardTitle } from "reactstrap";
import {DateFormat,DateTimeFormat,Loading,SuccessPopup, SwalClose, ErrorPopup} from "../../helpers/globalfunctions";
import Compressor from 'compressorjs';
import CommonApi from '../../apis/CommonApi';

function FileUploadPurchase(props) {
    //console.log(props);
    const [compressedFile, setcompressedFile] = useState([]);    

    //upload model    
    const CloseUploadModal = ()=>{        
        props.onClick(props.data.UploadViewModal)
    };

    //compress file
    const handleCompressedUpload = (e) =>{
        console.log(e.target.files[0]);        
        Loading()
          const file = e.target.files[0];
          var self = this;

          if (!file) {
            SwalClose()
            return;
          }
          //const assignState=this.setState('compressedFile');
        new Compressor(file, {
            quality: 0.6,

            // The compression process is asynchronous,
            // which means you have to access the `result` in the `success` hook function.
            success(result) {
                //self.setState({compressedFile:  self.state.compressedFile.concat(result)});
                setcompressedFile(compressedFile.concat(result))
                console.log("result");
                console.log(result);
                SwalClose()              
            },
            error(err) {
                SwalClose()
                console.log(err.message);
            },
        });        
    }

    //Submit Attachment 
    const SubmitAttachment = (e) =>{
        e.preventDefault();
        Loading()
        const data = new FormData(e.target);
        data.append('purchase_id',props.data.purchase_id);
        const files = compressedFile;
        files.map((file, index) => {
            data.append(`file[${index}]`, file);
        });        
        CommonApi.uploadPurchaseFile(data).then(response => {
            //this.handleCompressedUpload(event);
            //this.setState({compressedFile: compressedResult});
            SwalClose()
            if (response.data) {                
                SuccessPopup(response.data.message)
                CloseUploadModal()
                
                //this.resetForm()
            }
        }).catch((error) => {
            SwalClose()
            if (error.response) {                
                ErrorPopup(error.response)
            }

        });
    }

    return (
        <Modal isOpen={props.data.UploadViewModal} size='lg' >
            <ModalHeader className="modal-header" toggle={CloseUploadModal}>Upload </ModalHeader>
            <ModalBody>
                <Row>
                    <Col md={12}>
                        <Form className="mt-4" onSubmit={SubmitAttachment}>
                            <div className="">
                                <FormGroup>
                                    <Label for="purchase_upload">Select file/photo *</Label>
                                    <input
                                        accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.gif"
                                        capture="”camera"
                                        type="file"
                                        name="purchase_upload[]" 
                                        id="purchase_upload"
                                        onChange={handleCompressedUpload}
                                    />
                                    
                                </FormGroup>
                            </div>
                        

                            <hr/>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Upload By</th>
                                        <th>Attachment Name</th>
                                        <th>Upload Date</th>
                                        <th>Download</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    props.data.attachmentList ?(props.data.attachmentList.map((option,index)=>{
                                        return (
                                            <>
                                            <tr key={index}>
                                                <td>{option.first_name}</td>
                                                <td>{option.name}</td>
                                                <td>{DateFormat(option.created_at)}</td>
                                                <td><a href={"/download-sales-file/"+option.id} target="_blank"> <i class="ri-download-2-line"></i></a></td>
                                            </tr>
                                            </>
                                        )
                                    })):null
                                }
                                </tbody>
                            </Table>

                            <div class="d-flex justify-content-center">
                                <button type="submit" class="btn-sm mr-2 btn btn-primary">Submit</button>
                                <button type="button" class="btn-sm btn btn-secondary" onClick={CloseUploadModal}>Cancel</button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
}

export default FileUploadPurchase