import React, { Component } from "react";
import { Table, Row, Col, Card, CardBody, Container, Button, Form, FormGroup, Label, Input, FormText,
Pagination, PaginationItem, PaginationLink, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Modal, ModalHeader, ModalBody, ModalFooter, CustomInput, Collapse, CardTitle } from "reactstrap";
import ReactDOM from 'react-dom';
import User from '../../apis/User';
import ReactPaginate from 'react-paginate';
import { Link } from "react-router-dom";
import {DateFormat,DateTimeFormat,Loading,SuccessPopup, SwalClose, ErrorPopup} from "../../helpers/globalfunctions";
import CommonApi from '../../apis/CommonApi';
import Swal from 'sweetalert2';

//Import Breadcrumb
import Breadcrumbs from '../Common/Breadcrumb';

class AgentSaleReq extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Refer a Lead/Quote", link : "#" },
                { title : "sale property", link : "#" },
            ],
            modalIsOpen: false,
            CollapseOpen:false,
            ListFms:[],
            StepSubmitModal:false,
            StepList:[],
            ViewSaleDetail:'',
            ViewDetailModal:false,
            // WinLoseStatus:'',
            WinLoseModal:false,
            FmsTrackId:'',
            pageCount: 1,
            currentPage: 1,
            per_page:50,
            sort:'',
            questions:[],
        };
        this.HandleSubmit=this.HandleSubmit.bind(this);
        this.ViewDetails = this.ViewDetails.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.rowPerPageChange = this.rowPerPageChange.bind(this);
    }

    componentDidMount() {
        this._isMounted = true
        this.getSaleData()
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    //Modal open/close
    openModal = () => this.setState({ modalIsOpen: true });    
    closeModal = () => this.setState({ modalIsOpen: false });

    //Collapse Open/close
    Opencollapse = ()=>this.setState({CollapseOpen:true});
    Closecollapse = ()=>this.setState({CollapseOpen:false});
    
    //viewDetail Modal open/close
    OpenViewDetailModal = ()=>this.setState({ViewDetailModal:true});
    CloseViewDetailModal = ()=>this.setState({ViewDetailModal:false});



    //Form Submit
    HandleSubmit(event) {
        event.preventDefault();
        Loading()
        const data = new FormData(event.target);
        CommonApi.salePropertyRequest(data).then(response => {
            SwalClose()
            
            if (response.data) {
                // this.setState({modalIsOpen:false});
                this.closeModal();
                this.getSaleData();
                SuccessPopup(response.data.message)
            }
        }).catch((error) => {
            SwalClose()
            if (error.response) {
                ErrorPopup(error.response)
            }

        });      
    }

    //Fetch Sale Property data
    getSaleData() {
        CommonApi.AgentSaleFetch(this.state.currentPage,this.state.per_page).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({ListFms: response.data.data.data});
                    this.setState({pageCount: response.data.data.last_page});
                    this.setState({currentPage: response.data.data.current_page});
                    this.setState({per_page: response.data.data.per_page});
                }
            }
        });
    }
    
    // row per page    
    async rowPerPageChange(event) {        
        await this.setState({per_page: event.target.value});        
        this.getSaleData()
    }

    // page change event
    async handlePageClick(data) {
        const page = data.selected >= 0 ? data.selected + 1 : 0;
        await Promise.resolve(this.setState(() => ({ currentPage: page })));        
        this.getSaleData()
    }

    ViewDetails(id){
        this.OpenViewDetailModal();
        CommonApi.SalePropertyView(id).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({ViewSaleDetail:response.data.data.result})
                    // console.log(this.state.ViewPuchaserDetail);
                }
            }
        });
        //Sale Questions 
        CommonApi.GetSaleQuestions().then(response=>{
            if (response) {
                if(this._isMounted) {
                    this.setState({questions:response.data.data})
                }
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Sale Property" breadcrumbItems={this.state.breadcrumbItems} />

                    <Row>
                        <Col lg={12} className="mb-2">
                                <div>
                                    <Link to="/admin-panel/conveyancing-quote">
                                        <Button color="primary" className="d-flex justify-content-center"><span className="d-sm-inline-block me-2" size='sm'>Sale Quote</span></Button>
                                    </Link>
                                  {/* <Button color="primary" className="d-flex justify-content-center" onClick={this.openModal}><span className="d-sm-inline-block me-2" size='sm'>Sale Quote</span></Button>
                                  <Modal isOpen={this.state.modalIsOpen} size='xl' >
                                    <ModalHeader  toggle={this.closeModal}>Client Details</ModalHeader>
                                    <Form onSubmit={this.HandleSubmit}>
                                    <ModalBody>
                                    <Row>
                                        <Col md={12}>
                                        <FormGroup>
                                            <Label for="">
                                                Property Address
                                            </Label>
                                            <Input
                                            id=""
                                            name="property_address"
                                            placeholder=""
                                            type="textarea"
                                            />
                                        </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                        <FormGroup>
                                            <Label for="">
                                                Property Value
                                            </Label>
                                            <Input
                                            id=""
                                            name="property_value"
                                            placeholder=""
                                            type="text"
                                            />
                                        </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                        <FormGroup>
                                            <Label for="">
                                            Freehold / Leasehold 
                                            </Label>
                                            <Input
                                            id=""
                                            name="hold_type"
                                            placeholder=""
                                            type="text"
                                            />
                                        </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={2}>
                                        <FormGroup>
                                            <Label for="">
                                                Title 
                                            </Label>
                                            <Input
                                            id=""
                                            name="title_fc"
                                            placeholder=""
                                            type="select"
                                            >
                                                <option>
                                                    
                                                </option>
                                                <option value="mr">
                                                    Mr
                                                </option>
                                                <option value="mrs">
                                                    Mrs
                                                </option>
                                                <option value="miss">
                                                    Miss
                                                </option>
                                                <option value="ms">
                                                    Ms
                                                </option>
                                                <option value="dr">
                                                    Dr
                                                </option>
                                            </Input>
                                        </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                        <FormGroup>
                                            <Label for="">
                                                Surname 
                                            </Label>
                                            <Input
                                            id=""
                                            name="surname_fc"
                                            placeholder=""
                                            type="text"
                                            />
                                        </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                        <FormGroup>
                                            <Label for="">
                                                First Name
                                            </Label>
                                            <Input
                                            id=""
                                            name="first_name_fc"
                                            placeholder=""
                                            type="text"
                                            />
                                        </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                        <FormGroup>
                                            <Label for="">
                                                Last Name
                                            </Label>
                                            <Input
                                            id=""
                                            name="last_name_fc"
                                            placeholder=""
                                            type="text"
                                            />
                                        </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                        <FormGroup>
                                            <Label for="">
                                                Telephone
                                            </Label>
                                            <Input
                                            id=""
                                            name="telephone_fc"
                                            placeholder=""
                                            type="text"
                                            />
                                        </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                        <FormGroup>
                                            <Label for="">
                                                Email Address
                                            </Label>
                                            <Input
                                            id=""
                                            name="email_fc"
                                            placeholder=""
                                            type="text"
                                            />
                                        </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                        <FormGroup>
                                            <Label for="">
                                                Company Name 
                                            </Label>
                                            <Input
                                            id=""
                                            name="company_name_fc"
                                            placeholder="if applicable!"
                                            type="text"
                                            />
                                        </FormGroup>
                                        </Col>
                                    </Row>
                                    <a className="d-flex justify-content-end" style={{cursor:'pointer'}} onClick={this.Opencollapse}>Add Second Client</a>
                                    <Collapse isOpen={this.state.CollapseOpen}>
                                        <h5 className="my-2">Second Client</h5>
                                        <Row>
                                            <Col md={2}>
                                            <FormGroup>
                                                <Label for="">
                                                    Title 
                                                </Label>
                                                <Input
                                                id=""
                                                name="title_sc"
                                                placeholder=""
                                                type="select"
                                                >
                                                    <option>
                                                    
                                                    </option>
                                                    <option value="mr">
                                                        Mr
                                                    </option>
                                                    <option value="mrs">
                                                        Mrs
                                                    </option>
                                                    <option value="miss">
                                                        Miss
                                                    </option>
                                                    <option value="ms">
                                                        Ms
                                                    </option>
                                                    <option value="dr">
                                                        Dr
                                                    </option>
                                                </Input>
                                            </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                            <FormGroup>
                                                <Label for="">
                                                    Surname 
                                                </Label>
                                                <Input
                                                id=""
                                                name="surname_sc"
                                                placeholder=""
                                                type="text"
                                                />
                                            </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                            <FormGroup>
                                                <Label for="">
                                                    First Name
                                                </Label>
                                                <Input
                                                id=""
                                                name="first_name_sc"
                                                placeholder=""
                                                type="text"
                                                />
                                            </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                            <FormGroup>
                                                <Label for="">
                                                    Last Name
                                                </Label>
                                                <Input
                                                id=""
                                                name="last_name_sc"
                                                placeholder=""
                                                type="text"
                                                />
                                            </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                            <FormGroup>
                                                <Label for="">
                                                    Telephone
                                                </Label>
                                                <Input
                                                id=""
                                                name="telephone_sc"
                                                placeholder=""
                                                type="text"
                                                />
                                            </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                            <FormGroup>
                                                <Label for="">
                                                    Email Address
                                                </Label>
                                                <Input
                                                id=""
                                                name="email_sc"
                                                placeholder=""
                                                type="text"
                                                />
                                            </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                            <FormGroup>
                                                <Label for="">
                                                    Company Name 
                                                </Label>
                                                <Input
                                                id=""
                                                name="company_name_sc"
                                                placeholder="if applicable!"
                                                type="text"
                                                />
                                            </FormGroup>
                                            </Col>
                                        </Row>
                                            <a className="d-flex justify-content-end" style={{cursor:'pointer'}} onClick={this.Closecollapse}>Remove Second Client</a> 
                                    </Collapse>
                                    </ModalBody>

                                    <ModalFooter>
                                        <Button color="secondary" onClick={this.closeModal}>Cancel</Button>{' '}
                                        <Button type="submit" color="primary">Save</Button>                           
                                    </ModalFooter>
                                    </Form>
                                  </Modal> */}
                                </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody> 
                                    <Row>
                                        {/* <Col lg={2}>
                                            <Row>
                                                <Col lg={3} className="d-flex justify-content-end">
                                                    <FormGroup className="mt-2">                                                    
                                                        <Label for="selectRows"><i className="  fas fa-filter"></i></Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={6}>
                                                    <FormGroup>                                                    
                                                        <Input type="select" name="selectRows" onChange={this.rowSortChange} id="selectRows" value={this.state.sort}
                                                            >                                                              
                                                            <option value=''>All</option>
                                                            <option value='Won'>Won</option>
                                                            <option value='Lost'>Lost</option>
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                            </Row>                                                 
                                        </Col> */}
                                        <Col lg={3}>
                                                <Row>
                                                <Col lg={1}className="d-flex justify-content-start">
                                                    <FormGroup className="mt-2"> <Label for="selectRows"><i className=" fas fa-sort-amount-down-alt"></i></Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={5}>
                                                    <FormGroup>                                                    
                                                        <Input type="select" name="selectRows" onChange={this.rowPerPageChange} id="selectRows" value={this.state.per_page}
                                                            >                                                              
                                                            <option value=''>All</option>
                                                            <option value='50'>50</option>
                                                            <option value='100'>100</option>
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                </Row>                                                
                                        </Col>
                                    </Row>
                                    <div className="table-responsive">
                                        <Table className="mb-0">
                                            <thead className="bg-primary text-white">
                                                <tr>
                                                    <th>Property Address</th>
                                                    <th>Property Value</th>
                                                    {/* <th>Freehold/Leasehold</th> */}
                                                    {/* <th>Title client</th> */}
                                                    <th>Full Name</th>
                                                    {/* <th>First Name</th>
                                                    <th>Last Name</th> */}
                                                    {/* <th>Telephone</th> */}
                                                    {/* <th>Final Status</th> */}
                                                    <th>Won / Lost</th>
                                                    {/* <th>Title SCleint</th>
                                                    <th>Surname SCleint</th>
                                                    <th>First Name SCleint</th>
                                                    <th>Last Name SCleint</th>
                                                    <th>Telephone SCleint</th>
                                                    <th>Email SCleint</th>*/}
                                                    <th>Solicitor Fee</th> 
                                                    <th>Disbursement Fee</th>
                                                    <th>Total</th>
                                                    <th>View</th>
                                                    {/* <th>Action</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>  
                                                {
                                                    this.state.ListFms ?(this.state.ListFms.map((option,index)=>{
                                                        return <tr key={index}>
                                                            <td>{option.property_address}</td>
                                                            <td>£{option.property_value}</td>
                                                            <td className="d-flex">
                                                                <span className="mx-1">{option.first_name_fc}</span><span>{option.surname_fc}</span></td>
                                                                {/* <td style={option.final_status == 'Completed'? {background:'#5D9C59'}:option.final_status == 'Pending'?{background:'#F4D160'}:null}>{option.final_status}</td> */}

                                                                <td style={option.win_lose == 'Won'? {background:'#5D9C59'}:option.win_lose=='Lost'?{background:'#EF4040'}:null}>{option.win_lose}</td>
                                                            <td>£{option.std_fee}</td>
                                                            <td>£{option.disbursement_fee_total}</td>
                                                            <td>£{option.final_total}</td>
                                                            <td><i className=" mdi mdi-eye" style={{fontSize:'18px',color:'#192a56'}} onClick={()=>this.ViewDetails(option.id)}></i></td>
                                                        </tr>
                                                    })
                                                    ):null
                                                }
                                            </tbody>
                                            </Table>
                                    </div>

                                    {/* View Detail Modal */}
                                    <Modal isOpen={this.state.ViewDetailModal} size='xl' >
                                        <ModalHeader className="modal-header" toggle={this.CloseViewDetailModal}> All Detail </ModalHeader>
                                        <ModalBody>
                                            {this.state.ViewSaleDetail? (this.state.ViewSaleDetail.map((value,index)=>{
                                                return (
                                                    <>
                                                        <Row key={index} className="mt-4">
                                                            <Col md={6}>
                                                                <Card>
                                                                    <CardTitle className="d-flex justify-content-center">Customer Details</CardTitle>
                                                                    <CardBody>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Property Address :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.property_address}</h6>
                                                                            </Col>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Property Value :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>£{value.property_value}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Freehold/Leasehold :</h6>
                                                                            </Col>
                                                                            <Col lg={5} md={5}>
                                                                                <h6>{value.hold_type}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Title :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.title_fc}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Surname :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.surname_fc}</h6>
                                                                            </Col>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>First Name :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.first_name_fc}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Last Name :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.last_name_fc}</h6>
                                                                            </Col>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Telephone :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.telephone_fc}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Email :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.email_fc}</h6>
                                                                            </Col>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Card>
                                                                    <CardTitle className="d-flex justify-content-center">Disbursements</CardTitle>
                                                                    <div className="d-flex" style={{background:'#192a56'}}>
                                                                            <Col lg={6} md={6}>
                                                                                <h5 style={{color:'#fff'}}>Solicitor Fee :</h5>
                                                                            </Col>
                                                                            <Col lg={6} md={6} style={{textAlign:'end'}}>
                                                                                <h5 style={{color:'#fff'}}>£{value.std_fee}</h5>
                                                                            </Col>
                                                                        </div>
                                                                    <Table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Title</th>
                                                                                <th>Fees</th>
                                                                                <th>Vat %</th>
                                                                                <th>Total</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                    {value.sales_disbursements ?(value.sales_disbursements.map((option,index)=>{
                                                                        return (
                                                                            <>
                                                                                <tr key={index}>
                                                                                    <td>{option.title}</td>
                                                                                    <td>£{option.disbursement_charge}</td>
                                                                                    <td>{option.vat}%</td>
                                                                                    <td>£{option.disbursement_total}</td>
                                                                                </tr>
                                                                            </>
                                                                        )
                                                                    })):null}
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td>Total</td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td>£{value.disbursement_fee_total}</td>
                                                                        </tr>
                                                                        <tr className="card-title">
                                                                            <td className="final-total-text">Final Total</td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td className="final-total-text">£{value.final_total == 0 ? value.std_fee:value.final_total }</td>
                                                                        </tr>
                                                                    </tfoot>
                                                                    </Table>
                                                                </Card>
                                                            </Col>

                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                            <Card>
                                                                <CardTitle className="d-flex justify-content-center" style={{background:'#192a56',color:'#fff'}}>Questions  Details</CardTitle>
                                                                    <CardBody>
                                                                        {this.state.questions? (this.state.questions.map((option,index)=>{
                                                                            return (
                                                                                <>
                                                                                    <div className="d-flex border-bottom mb-2" key={index}>
                                                                                        <Col lg={1} md={1}>
                                                                                            <h6>{option.que_no} </h6>
                                                                                        </Col>
                                                                                        <Col lg={8} md={8}>
                                                                                            <h6>{option.question} </h6>
                                                                                        </Col>
                                                                                        <Col lg={3} md={3}>
                                                                                            {option.que_no == 1 ? <h6>{value.located_eng}</h6>:option.que_no == 2 ? <h6>{value.buy_sell_auction}</h6>:option.que_no == 3 ? <h6>{value.hold_type}</h6>:option.que_no == 4 ? <h6>{value.land_registry}</h6>:option.que_no == 5 ? <h6>{value.mortgage}</h6>:''}
                                                                                        </Col>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })):null}
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                        {/* {
                                                            value.first_name_sc ?<>
                                                                <h6 className="my-3" style={{color:'#192a56'}}>Second Client</h6>
                                                                <Row>
                                                                <Col md={2}>
                                                                <FormGroup>
                                                                    <Label for="">
                                                                        Title 
                                                                    </Label>
                                                                    <Input
                                                                    id=""
                                                                    name="title_sc"
                                                                    placeholder=""
                                                                    type="text"
                                                                    disabled
                                                                    value={value.title_sc}
                                                                    />
                                                                </FormGroup>
                                                                </Col>
                                                                <Col md={2}>
                                                                <FormGroup>
                                                                    <Label for="">
                                                                        Surname 
                                                                    </Label>
                                                                    <Input
                                                                    id=""
                                                                    name="surname_sc"
                                                                    placeholder=""
                                                                    type="text"
                                                                    disabled
                                                                    value={value.surname_sc}
                                                                    />
                                                                </FormGroup>
                                                                </Col>
                                                                <Col md={4}>
                                                                <FormGroup>
                                                                    <Label for="">
                                                                        First Name
                                                                    </Label>
                                                                    <Input
                                                                    id=""
                                                                    name="first_name_sc"
                                                                    placeholder=""
                                                                    type="text"
                                                                    disabled
                                                                    value={value.first_name_sc}
                                                                    />
                                                                </FormGroup>
                                                                </Col>
                                                                <Col md={4}>
                                                                <FormGroup>
                                                                    <Label for="">
                                                                        Last Name
                                                                    </Label>
                                                                    <Input
                                                                    id=""
                                                                    name="last_name_sc"
                                                                    placeholder=""
                                                                    type="text"
                                                                    disabled
                                                                    value={value.last_name_sc}
                                                                    />
                                                                </FormGroup>
                                                                </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md={4}>
                                                                    <FormGroup>
                                                                        <Label for="">
                                                                            Telephone
                                                                        </Label>
                                                                        <Input
                                                                        id=""
                                                                        name="telephone_sc"
                                                                        placeholder=""
                                                                        type="text"
                                                                        disabled
                                                                        value={value.telephone_sc}
                                                                        />
                                                                    </FormGroup>
                                                                    </Col>
                                                                    <Col md={4}>
                                                                    <FormGroup>
                                                                        <Label for="">
                                                                            Email Address
                                                                        </Label>
                                                                        <Input
                                                                        id=""
                                                                        name="email_sc"
                                                                        placeholder=""
                                                                        type="text"
                                                                        disabled
                                                                        value={value.email_sc}
                                                                        />
                                                                    </FormGroup>
                                                                    </Col>
                                                                    <Col md={4}>
                                                                    <FormGroup>
                                                                        <Label for="">
                                                                            Company Name 
                                                                        </Label>
                                                                        <Input
                                                                        id=""
                                                                        name="company_name_sc"
                                                                        placeholder=""
                                                                        type="text"
                                                                        disabled
                                                                        value={value.company_name_sc}
                                                                        />
                                                                    </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </>:''
                                                        } */}
                                                    </>
                                                )

                                            })):null}
                                    </ModalBody>
                                    </Modal>
                                    {/* paginnation */}
                                    <ReactPaginate
                                      previousLabel="&#x276E;"
                                      nextLabel="&#x276F;"
                                      breakLabel={'...'}
                                      breakClassName={'break-me'}
                                      pageCount={this.state.pageCount}
                                      initialPage={this.state.currentPage - 1}
                                      forcePage={this.state.currentPage - 1}
                                      marginPagesDisplayed={2}
                                      pageRangeDisplayed={5}
                                      onPageChange={this.handlePageClick}
                                      containerClassName={'pagination mt-4 float-right'}
                                      activeClassName={'active'}
                                      disableInitialCallback={true}
                                    /> 
        
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default AgentSaleReq;
