import React, { Component } from "react";
import { Table, Row, Col, Card, CardBody, Container, Button, Form, FormGroup, Label, Input, FormText,
Pagination, PaginationItem, PaginationLink, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Modal, ModalHeader, ModalBody, ModalFooter, CustomInput, Collapse, CardTitle } from "reactstrap";
import ReactDOM from 'react-dom';
import User from '../../apis/User';
import ReactPaginate from 'react-paginate';
import { Link } from "react-router-dom";
import {DateFormat,DateTimeFormat,Loading,SuccessPopup, SwalClose, ErrorPopup} from "../../helpers/globalfunctions";
import CommonApi from '../../apis/CommonApi';
import Swal from 'sweetalert2';
import Compressor from 'compressorjs';
import FileUpload from '../Common/FileUpload';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';

//Import Breadcrumb
import Breadcrumbs from '../Common/Breadcrumb';
import { Tooltip } from "@mui/material";
import EditModal from "../Common/EditModal";

class SaleTrackCase extends Component {
    componentRef = null;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Track Case", link : "#" },
                { title : "Sales", link : "#" },
            ],
            modalIsOpen: false,
            CollapseOpen:false,
            ListFms:[],
            StepSubmitModal:false,
            attachmentList:[],
            ViewSaleDetail:'',
            ViewDetailModal:false,
            // WinLoseStatus:'',
            WinLoseModal:false,
            FmsTrackId:'',
            pageCount: 1,
            currentPage: 1,
            per_page:50,
            sort:'',
            questions:[],
            from_date:'',
            to_date:'',
            search : '',
            UploadViewModal:false,
            compressedFile:[],
            sale_id:'',
            EditContent:null,
            EditModal:false,
            EditDetails:''
        };
        this.ViewDetails = this.ViewDetails.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.rowPerPageChange = this.rowPerPageChange.bind(this);
        this.FromdateChange= this.FromdateChange.bind(this);
        this.TodateChange=this.TodateChange.bind(this);
        this.handleSearch=this.handleSearch.bind(this);        
        this.CloseUploadModal=this.CloseUploadModal.bind(this);
        this.DeleteSale=this.DeleteSale.bind(this);
        this.EditHandler = this.EditHandler.bind(this);
    }

    componentDidMount() {
        this._isMounted = true
        this.getSaleData()
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    
    //viewDetail Modal open/close
    OpenViewDetailModal = ()=>this.setState({ViewDetailModal:true});
    CloseViewDetailModal = ()=>this.setState({ViewDetailModal:false});

    
    //steps view modal
    OpenStepDetailModal = ()=>this.setState({StepDetailViewModal:true});
    CloseStepDetailModal = ()=>this.setState({StepDetailViewModal:false});

    //upload model
    OpenUploadModal = ()=>this.setState({UploadViewModal:true});
    CloseUploadModal(){this.setState({UploadViewModal:false})};

    //Fetch Sale Property data
    getSaleData() {
        CommonApi.AgentSaleFetch(this.state.currentPage,this.state.per_page,this.state.from_date,this.state.to_date,this.state.search).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({ListFms: response.data.data.data});
                    this.setState({pageCount: response.data.data.last_page});
                    this.setState({currentPage: response.data.data.current_page});
                    this.setState({per_page: response.data.data.per_page});
                }
            }
        });
    }
    
    // row per page    
    async rowPerPageChange(event) {        
        await this.setState({per_page: event.target.value});        
        this.getSaleData()
    }

    // page change event
    async handlePageClick(data) {
        const page = data.selected >= 0 ? data.selected + 1 : 0;
        await Promise.resolve(this.setState(() => ({ currentPage: page })));        
        this.getSaleData()
    }

    ViewDetails(id){
        this.OpenViewDetailModal();
        CommonApi.SalePropertyView(id).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({ViewSaleDetail:response.data.data.result})
                    // console.log(this.state.ViewPuchaserDetail);
                }
            }
        });
        //Sale Questions 
        CommonApi.GetSaleQuestions().then(response=>{
            if (response) {
                if(this._isMounted) {
                    this.setState({questions:response.data.data})
                }
            }
        })
    }

    //steps view
    StepsView(id){
        this.OpenStepDetailModal();
        CommonApi.SalePropertyFetchSteps(id).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({StepList:response.data.data.result})
                }
            }
        });
    }

    //upload modal view
    async UploadModal(id){
        this.OpenUploadModal();
        await this.setState({sale_id: id});
        CommonApi.getSaleFiles(id).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({attachmentList:response.data.data.result.attachments})
                }
            }
        });
    }

    //date change event
    async FromdateChange(e){
        await this.setState({from_date: e.target.value});  
        this.getSaleData();       
    }
    async TodateChange(e){
        await this.setState({to_date:e.target.value});
        this.getSaleData() 
    }
    // on change search
    async handleSearch(e) {
        await this.setState({ search: e.target.value });
        await Promise.resolve(this.setState(() => ({ currentPage: 1 })));
        this.getSaleData()
    }
    
    //Delete sales
    async DeleteSale(id){
        Swal.fire({
            title: "Are you sure?",
            text: "It will permanent delete from system!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Delete it!"
          }).then((result) => {
            if (result.isConfirmed) {                
                Loading()                
                CommonApi.deleteSales(id).then(response => {
                    SwalClose()                    
                    if (response.data) {
                        this.getSaleData();
                        SuccessPopup(response.data.message)
                    }
                }).catch((error) => {
                    SwalClose()
                    if (error.response) {
                        ErrorPopup(error.response)
                    }

                });
            }
          });
    }

    //Cancel 
    async CancelRequest(id){
        Swal.fire({
            title: "Are you sure?",
            // text: "It will permanent delete from system!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Cancel it!"
          }).then((result) => {
            if (result.isConfirmed) {                
                Loading()                
                CommonApi.CancelSales(id).then(response => {
                    SwalClose()                    
                    if (response.data) {
                        this.getSaleData();
                        SuccessPopup(response.data.message)
                    }
                }).catch((error) => {
                    SwalClose()
                    if (error.response) {
                        ErrorPopup(error.response)
                    }

                });
            }
          });
    }

    //Edit handler
    async EditHandler(content){
        this.setState({EditModal:true});
        await this.setState({EditContent:content})
        // console.log(this.state.EditContent)
    }
    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Sale" breadcrumbItems={this.state.breadcrumbItems} />
                    <EditModal isOpen={this.state.EditModal} EditDetails={this.state.EditContent} 
                    toggle={() => this.setState({ EditModal: false })} getSaleData={this.getSaleData}/>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={3}>
                                                <Row>
                                                <Col lg={1}className="d-flex justify-content-start">
                                                    <FormGroup className="mt-2"> <Label for="selectRows"><i className="  fas fa-sort-amount-down-alt"></i></Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={5}>
                                                    <FormGroup>
                                                        <Input type="select" name="selectRows" onChange={this.rowPerPageChange} id="selectRows" value={this.state.per_page}
                                                            >         
                                                            <option value=''>All</option>
                                                            <option value='50'>50</option>
                                                            <option value='100'>100</option>
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                </Row>                                                
                                        </Col>
                                        <Col lg={3}>
                                                <Row>
                                                <Col lg={1}className="d-flex justify-content-start">
                                                    <FormGroup className="mt-2"> <Label for="selectRows"><i className=" fas fa-search"></i></Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={9}>
                                                    <FormGroup>
                                                        <Input type="text" name="" onChange={this.handleSearch} id="selectRows" value={this.state.search} placeholder="search..."
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                </Row>
                                        </Col>
                                        <Col lg={6}>
                                            <Row className="justify-content-end">
                                                <Col lg={2} className="d-flex justify-content-end">
                                                    <FormGroup className="mt-2">                                                    
                                                        <Label for="">From<i class=" fas fa-calendar-alt mx-2"></i></Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={3}>
                                                    <FormGroup>                                                            
                                                        <Input type="date" name="created_at" onChange={this.FromdateChange}  />
                                                    </FormGroup>
                                                </Col>

                                                <Col lg={2} className="d-flex justify-content-end">
                                                    <FormGroup className="mt-2">                                                    
                                                        <Label for="">To<i class=" fas fa-calendar-alt mx-2"></i></Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={3}>
                                                    <FormGroup>                                                            
                                                        <Input type="date" name="created_at" onChange={this.TodateChange} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <div className="table-responsive">
                                        <Table className="mb-0">
                                            <thead className="bg-primary text-white">
                                                <tr>
                                                    <th>Created Date</th>
                                                    <th>Id</th>
                                                    <th>Property Address</th>
                                                    <th>Property Value</th>
                                                    {/* <th>Freehold/Leasehold</th> */}
                                                    {/* <th>Title client</th> */}
                                                    <th>Full Name</th>
                                                    {/* <th>First Name</th>
                                                    <th>Last Name</th> */}
                                                    {/* <th>Telephone</th> */}
                                                    {/* <th>Final Status</th> */}
                                                    {/* <th>Won / Lost</th> */}
                                                    {/* <th>Title SCleint</th>
                                                    <th>Surname SCleint</th>
                                                    <th>First Name SCleint</th>*/}
                                                    <th>Step Track</th>
                                                    <th>Steps</th>
                                                    <th>Agent company name</th>
                                                    <th>estate agent name</th>
                                                    <th>solicitor company name</th>
                                                    <th>conveyancer name</th>
                                                    <th>View</th>
                                                    <th>Upload</th>
                                                    <th>Edit/Delete</th>
                                                    <th>Cancel</th>
                                                    <th style={{minWidth:'85px'}}>Won / Lost</th>
                                                </tr>
                                            </thead>
                                            <tbody>  
                                                {
                                                    this.state.ListFms ?(this.state.ListFms.map((option,index)=>{
                                                        return <tr key={index}>
                                                            <td>{DateFormat(option.created_at)}</td>
                                                            <td>{option.id}</td>
                                                            <td>{option.property_address}</td>
                                                            <td>£{option.property_value}</td>
                                                            <td className="d-flex">
                                                                <span className="mx-1">{option.first_name_fc}</span><span>{option.surname_fc}</span></td>
                                                                {/* <td style={option.final_status == 'Completed'? {background:'#5D9C59'}:option.final_status == 'Pending'?{background:'#F4D160'}:null}>{option.final_status}</td> */}
                                                            <td>
                                                                <div className="flat pipeline">
                                                                    {option.sales_step ?(option.sales_step.map((value,index)=>{
                                                                        return <Tooltip arrow title={value.step_name}><a key={index} text={value.step_name}className={value.status=='Pending'?'pending-pipeline':(value.status=='Completed')?'completed-pipeline'
                                                                        :(value.status=='Cancel')?'cancel-pipeline':''}><span></span></a></Tooltip>
                                                                    })):null}
                                                                </div>
                                                            </td>
                                                            <td><i className=" ri-funds-box-fill" style={{fontSize:'18px',color:'#192a56'}} onClick={()=>this.StepsView(option.id)}></i></td>
                                                            <th>{option.agent_company}</th>
                                                            <th>{option.agent_name}</th>
                                                            <td>{option.soli_company}</td>
                                                            <td>{option.conveyancer_name}</td>
                                                            <td><i className=" mdi mdi-eye" style={{fontSize:'18px',color:'#192a56'}} onClick={()=>this.ViewDetails(option.id)}></i></td>
                                
                                                            <td><i className="ri-upload-2-line" style={{fontSize:'18px',color:'#192a56'}} onClick={()=>this.UploadModal(option.id)}></i></td>

                                                            <td>
                                                                {option.final_status == 'Cancel'?'':<><i className=" ri-edit-2-fill"style={{fontSize:'15px',color:'#192a56'}} onClick={()=>this.EditHandler(option)}></i>
                                                               
                                                                <i className="ri-delete-bin-line" style={{fontSize:'18px',color:'red',marginLeft:'7px'}} onClick={()=>this.DeleteSale(option.id)}></i></>
                                                                }
                                                            
                                                            </td>
                                                            <td style={option.final_status == 'Cancel'? {background:'red',color:'#fff'}:null}>{option.final_status == 'Cancel' ? 
                                                                    <span>{option.final_status}</span>
                                                                : 
                                                                    <i className="dripicons-cross" style={{fontSize:'18px',color:'red'}} onClick={()=>this.CancelRequest(option.id)}></i>
                                                                     }
                                                            </td>

                                                            <td style={option.win_lose == 'Won'? {background:'#5D9C59'}:option.win_lose=='Lost'?{background:'#EF4040'}:null}>{option.win_lose}</td>
                                                        </tr>
                                                    })
                                                    ):null
                                                }
                                            </tbody>
                                            </Table>
                                    </div>

                                    {/* View Detail Modal */}
                                    <Modal isOpen={this.state.ViewDetailModal} size='xl' >
                                        <ModalHeader className="modal-header" toggle={this.CloseViewDetailModal}> 
                                            
                                            <span className="align-middle flex-grow-1">All Detail</span>
                                            <div> 
                                                <ReactToPrint
                                                    trigger={() => <i className="mdi mdi-printer" style={{fontSize:'18px',color:'white'}}></i>}
                                                    content={() => this.componentRef}
                                                />
                                            </div>
                                            
                                        </ModalHeader>

                                        {/* Print section */}
                                        <div ref={el => (this.componentRef = el)} >
                                        <ModalBody>
                                            {this.state.ViewSaleDetail? (this.state.ViewSaleDetail.map((value,index)=>{
                                                return (
                                                    <>
                                                        <Row key={index} className="mt-4">
                                                            <Col md={6}>
                                                                <Card>
                                                                    <CardTitle className="d-flex justify-content-center">Customer Details</CardTitle>
                                                                    <CardBody>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Property Address :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.property_address}</h6>
                                                                            </Col>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Property Value :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>£{value.property_value}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Freehold/Leasehold :</h6>
                                                                            </Col>
                                                                            <Col lg={5} md={5}>
                                                                                <h6>{value.hold_type}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Title :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.title_fc}</h6>
                                                                            </Col>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>First Name :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.first_name_fc}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Middle Name :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.last_name_fc}</h6>
                                                                            </Col>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Surname :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.surname_fc}</h6>
                                                                            </Col>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Telephone :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.telephone_fc}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Email :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.email_fc}</h6>
                                                                            </Col>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Company :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.company_name}</h6>
                                                                            </Col>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Card>
                                                                    <CardTitle className="d-flex justify-content-center">2nd Person Details</CardTitle>
                                                                    <CardBody>             

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Title :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.title_sc}</h6>
                                                                            </Col>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>First Name :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.first_name_sc}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Middle Name :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.last_name_sc}</h6>
                                                                            </Col>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Surname :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.surname_sc}</h6>
                                                                            </Col>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Telephone :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.telephone_sc}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Email :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.email_sc}</h6>
                                                                            </Col>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Company :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.company_name_sc}</h6>
                                                                            </Col>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                                {/* <Card>
                                                                    <CardTitle className="d-flex justify-content-center">Disbursements</CardTitle>
                                                                        <div className="d-flex" style={{background:'#192a56'}}>
                                                                            <Col lg={6} md={6}>
                                                                                <h5 style={{color:'#fff'}}>Solicitor Fee :</h5>
                                                                            </Col>
                                                                            <Col lg={6} md={6} style={{textAlign:'end'}}>
                                                                                <h5 style={{color:'#fff'}}>£{value.std_fee}</h5>
                                                                            </Col>
                                                                        </div>
                                                                    <Table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Title</th>
                                                                                <th>Fees</th>
                                                                                <th>Vat %</th>
                                                                                <th>Total</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                    {value.sales_disbursements ?(value.sales_disbursements.map((option,index)=>{
                                                                        return (
                                                                            <>
                                                                                <tr key={index}>
                                                                                    <td>{option.title}</td>
                                                                                    <td>£{option.disbursement_charge}</td>
                                                                                    <td>{option.vat}%</td>
                                                                                    <td>£{option.disbursement_total}</td>
                                                                                </tr>
                                                                            </>
                                                                        )
                                                                    })):null}
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td>Total</td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td>£{value.disbursement_fee_total}</td>
                                                                        </tr>
                                                                        <tr className="card-title">
                                                                            <td className="final-total-text">Final Total</td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td className="final-total-text">£{value.final_total == 0 ? value.std_fee:value.final_total }</td>
                                                                        </tr>
                                                                    </tfoot>
                                                                    </Table>
                                                                </Card> */}
                                                            </Col>

                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                            <Card>
                                                                <CardTitle className="d-flex justify-content-center" style={{background:'#192a56',color:'#fff'}}>Questions  Details</CardTitle>
                                                                    <CardBody>
                                                                        {this.state.questions? (this.state.questions.map((option,index)=>{
                                                                            return (
                                                                                <>
                                                                                    <div className="d-flex border-bottom mb-2" key={index}>
                                                                                        <Col lg={1} md={1}>
                                                                                            <h6>{option.que_no} </h6>
                                                                                        </Col>
                                                                                        <Col lg={8} md={8}>
                                                                                            <h6>{option.question} </h6>
                                                                                        </Col>
                                                                                        <Col lg={3} md={3}>
                                                                                            {option.que_no == 1 ? <h6>{value.located_eng}</h6>:option.que_no == 2 ? <h6>{value.buy_sell_auction}</h6>:option.que_no == 3 ? <h6>{value.hold_type}</h6>:option.que_no == 4 ? <h6>{value.land_registry}</h6>:option.que_no == 5 ? <h6>{value.mortgage}</h6>:''}
                                                                                        </Col>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })):null}
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                        {/* {
                                                            value.first_name_sc ?<>
                                                                <h6 className="my-3" style={{color:'#192a56'}}>Second Client</h6>
                                                                <Row>
                                                                <Col md={2}>
                                                                <FormGroup>
                                                                    <Label for="">
                                                                        Title 
                                                                    </Label>
                                                                    <Input
                                                                    id=""
                                                                    name="title_sc"
                                                                    placeholder=""
                                                                    type="text"
                                                                    disabled
                                                                    value={value.title_sc}
                                                                    />
                                                                </FormGroup>
                                                                </Col>
                                                                <Col md={2}>
                                                                <FormGroup>
                                                                    <Label for="">
                                                                        Surname 
                                                                    </Label>
                                                                    <Input
                                                                    id=""
                                                                    name="surname_sc"
                                                                    placeholder=""
                                                                    type="text"
                                                                    disabled
                                                                    value={value.surname_sc}
                                                                    />
                                                                </FormGroup>
                                                                </Col>
                                                                <Col md={4}>
                                                                <FormGroup>
                                                                    <Label for="">
                                                                        First Name
                                                                    </Label>
                                                                    <Input
                                                                    id=""
                                                                    name="first_name_sc"
                                                                    placeholder=""
                                                                    type="text"
                                                                    disabled
                                                                    value={value.first_name_sc}
                                                                    />
                                                                </FormGroup>
                                                                </Col>
                                                                <Col md={4}>
                                                                <FormGroup>
                                                                    <Label for="">
                                                                        Last Name
                                                                    </Label>
                                                                    <Input
                                                                    id=""
                                                                    name="last_name_sc"
                                                                    placeholder=""
                                                                    type="text"
                                                                    disabled
                                                                    value={value.last_name_sc}
                                                                    />
                                                                </FormGroup>
                                                                </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md={4}>
                                                                    <FormGroup>
                                                                        <Label for="">
                                                                            Telephone
                                                                        </Label>
                                                                        <Input
                                                                        id=""
                                                                        name="telephone_sc"
                                                                        placeholder=""
                                                                        type="text"
                                                                        disabled
                                                                        value={value.telephone_sc}
                                                                        />
                                                                    </FormGroup>
                                                                    </Col>
                                                                    <Col md={4}>
                                                                    <FormGroup>
                                                                        <Label for="">
                                                                            Email Address
                                                                        </Label>
                                                                        <Input
                                                                        id=""
                                                                        name="email_sc"
                                                                        placeholder=""
                                                                        type="text"
                                                                        disabled
                                                                        value={value.email_sc}
                                                                        />
                                                                    </FormGroup>
                                                                    </Col>
                                                                    <Col md={4}>
                                                                    <FormGroup>
                                                                        <Label for="">
                                                                            Company Name 
                                                                        </Label>
                                                                        <Input
                                                                        id=""
                                                                        name="company_name_sc"
                                                                        placeholder=""
                                                                        type="text"
                                                                        disabled
                                                                        value={value.company_name_sc}
                                                                        />
                                                                    </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </>:''
                                                        } */}
                                                    </>
                                                )

                                            })):null}
                                    </ModalBody>
                                    </div>
                                    </Modal>

                                    {/* step display model */}
                                    <Modal isOpen={this.state.StepDetailViewModal} size='xl' >
                                        <ModalHeader className="modal-header" toggle={this.CloseStepDetailModal}>
                                            <span className="align-middle flex-grow-1">Sale property step</span>
                                            <div> 
                                                <ReactToPrint
                                                    trigger={() => <i className="mdi mdi-printer" style={{fontSize:'18px',color:'white'}}></i>}
                                                    content={() => this.componentRef}
                                                />
                                            </div>
                                        </ModalHeader>

                                        {/* Print section */}
                                        <div ref={el => (this.componentRef = el)} >
                                        <ModalBody>
                                            <Row>
                                                <Col md={6} sm={6} lg={6}>
                                                    <Table>
                                                    <thead>
                                                        <tr style={{'fontWeight':'bold','fontSize':'16px'}}>
                                                            <th style={{width:'15%'}}>Step No</th>
                                                            <th style={{width:'65%'}}>Step Name</th>
                                                            <th style={{width:'20%'}}>Completed Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.StepList ?(this.state.StepList.map((stepValue,index)=>{
                                                                return(stepValue.step_no >= 1 && stepValue.step_no <= 5)? <tr key={index}>
                                                                <td>{stepValue.step_no}</td>
                                                                <td>{stepValue.step_name}
                                                                    <div className="d-flex">
                                                                        Notes: ({stepValue.note})
                                                                    </div>
                                                                </td>
                                                                <td>{stepValue.step_submit_date ? DateFormat(stepValue.step_submit_date):''}</td>
                                                            </tr>:null 
                                                            })):null
                                                        }
                                                    </tbody>
                                                    </Table>
                                                </Col>
                                                <Col md={6} sm={6} lg={6}>
                                                    <Table>
                                                    <thead>
                                                        <tr style={{'fontWeight':'bold','fontSize':'16px'}}>
                                                            <th style={{width:'15%'}}>Step No</th>
                                                            <th style={{width:'65%'}}>Step Name</th>
                                                            <th style={{width:'20%'}}>Completed Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.StepList ?(this.state.StepList.map((stepValue,index)=>{
                                                                return(stepValue.step_no >= 6 && stepValue.step_no <= 11)? <tr key={index}>
                                                                <td>{stepValue.step_no}</td>
                                                                <td>{stepValue.step_name}
                                                                    <div className="d-flex">
                                                                        Notes: ({stepValue.note})
                                                                    </div>
                                                                </td>
                                                                <td>{stepValue.step_submit_date ? DateFormat(stepValue.step_submit_date):''}</td>
                                                            </tr>:null 
                                                            })):null
                                                        }
                                                    </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        </ModalBody>
                                        </div>
                                    </Modal>

                                    {/* Upload modal */}
                                    <FileUpload 
                                        data={this.state}
                                        onClick={this.CloseUploadModal}
                                    />

                                    {/* paginnation */}
                                    <ReactPaginate
                                      previousLabel="&#x276E;"
                                      nextLabel="&#x276F;"
                                      breakLabel={'...'}
                                      breakClassName={'break-me'}
                                      pageCount={this.state.pageCount}
                                      initialPage={this.state.currentPage - 1}
                                      forcePage={this.state.currentPage - 1}
                                      marginPagesDisplayed={2}
                                      pageRangeDisplayed={5}
                                      onPageChange={this.handlePageClick}
                                      containerClassName={'pagination mt-4 float-right'}
                                      activeClassName={'active'}
                                      disableInitialCallback={true}
                                    /> 
        
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default SaleTrackCase;
