import React, { Component } from 'react';
import { Container, Row, Col, Table, Card, CardBody, Button, Form, FormGroup, Label, Input, FormText,
Pagination, PaginationItem, PaginationLink } from "reactstrap";
//import MiniWidgets from "./MiniWidgets";
//import RevenueAnalytics from "./RevenueAnalytics";
import CommonApi from '../../apis/CommonApi';
import { Link } from "react-router-dom";
import WonImage from "../../assets/images/Won.svg";
import LostImage from "../../assets/images/Lost.svg";
import PendingImage from "../../assets/images/Pending.svg";
import NAIcon from "../../assets/images/NAIcon.png";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import SaleWonLostChart from '../AdminDashboard/SaleWonLostChart';
import PurchaseWonLostChart from '../AdminDashboard/PurchaseWonLostChart';
import SolicitorPurchaseWonLostChart from './SolicitorPurchaseWonLostChart';
import SolicitorSaleWonLostChart from './SolicitorSaleWonLostChart';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
ChartJS.register(ArcElement, Tooltip, Legend);

//import Stats from '../../apis/Stats';
let isMounted = false;
class SolicitorDashboard extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state={
            breadcrumbItems : [
                { title : "Dashboard", link : "#" },                
            ],
            TotalSale:'',
            TotalPurachse:'',
            from_date:'',
            to_date:'',
            TotalSaleWon:'',
            TotalSaleLost:'',
            TotalPurchaseWon:'',
            TotalPurchaseLost:'',
            TotalPurchasePending:'',
            TotalSalePending:'',
            TotalSaleCancel:'',
            TotalPurchaseCancel:'' ,
        };
        this.FromdateChange= this.FromdateChange.bind(this);
        this.TodateChange=this.TodateChange.bind(this);
    }

    componentDidMount(){
        this._isMounted = true;
        // this.getDashboardCount()
        this.getDashboardCountWithFilter();
    }

    /*getDashboardCount() {
        CommonApi.SolicitorDashboard().then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({TotalSale:response.data.data.totalsale});
                    this.setState({TotalPurachse:response.data.data.totalpurchase})

                }
            }
        });
    }*/

    //Total Dashboard count 
    getDashboardCountWithFilter() {
        CommonApi.SolicitorDashboardFilter(this.state.from_date,this.state.to_date).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({TotalSaleWon:response.data.data.saleWon_Status});
                    this.setState({TotalSaleLost:response.data.data.saleLost_Status});
                    this.setState({TotalPurchaseWon:response.data.data.purchaseWon_Status});
                    this.setState({TotalPurchaseLost:response.data.data.purchaseLost_Status});
                    this.setState({TotalPurchasePending:response.data.data.purchaseNull_Status});
                    this.setState({TotalSalePending:response.data.data.saleNull_Status});
                    this.setState({TotalSaleCancel:response.data.data.saleCancel_status});
                    this.setState({TotalPurchaseCancel:response.data.data.purchaseCancel_status});
                    // console.log(response.data.data);
                }
            }
        });
    }
    //date change event
    async FromdateChange(e){
        await this.setState({from_date: e.target.value});  
        this.getDashboardCountWithFilter();       
    }
    async TodateChange(e){
        await this.setState({to_date:e.target.value});
        this.getDashboardCountWithFilter() 
    }
    render() {        
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Dashboard" breadcrumbItems={this.state.breadcrumbItems} />
                    {/* <Row>          
                        <Col lg={3}>                                               
                        </Col>

                        <Col lg={2}></Col>
                        <Col lg={7}>
                            <Row className="justify-content-end">
                                <Col lg={2} className="d-flex justify-content-end">
                                    <FormGroup className="mt-2">                                                    
                                        <Label for="">From<i class=" fas fa-calendar-alt mx-2"></i></Label>
                                    </FormGroup>
                                </Col>
                                <Col lg={3}>
                                    <FormGroup>                                                            
                                        <Input type="date" name="created_at" onChange={this.FromdateChange}  />
                                    </FormGroup>
                                </Col>

                                <Col lg={2} className="d-flex justify-content-end">
                                    <FormGroup className="mt-2">                                                    
                                        <Label for="">To<i class=" fas fa-calendar-alt mx-2"></i></Label>
                                    </FormGroup>
                                </Col>
                                <Col lg={3}>
                                    <FormGroup>                                                            
                                        <Input type="date" name="created_at" onChange={this.TodateChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row> */}

                    <Row>
                        <Col lg={6}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={12}>
                                            {/* <h6 className='text-center mb-4 font-weight-bold'>Default current month data display</h6> */}
                                            <Row className="justify-content-end">
                                                <Col lg={2} className="d-flex justify-content-end">
                                                    <FormGroup className="mt-2">                                                    
                                                        <Label for="">From<i class=" fas fa-calendar-alt mx-2"></i></Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={3}>
                                                    <FormGroup>                                                            
                                                        <Input type="date" name="created_at" onChange={this.FromdateChange}  />
                                                    </FormGroup>
                                                </Col>

                                                <Col lg={2} className="d-flex justify-content-end">
                                                    <FormGroup className="mt-2">                                                    
                                                        <Label for="">To<i class=" fas fa-calendar-alt mx-2"></i></Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={3}>
                                                    <FormGroup>                                                            
                                                        <Input type="date" name="created_at" onChange={this.TodateChange} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className='mt-2'>
                                        <Col lg={6}>
                                            <h5>Sale</h5>
                                                <div className='d-flex justify-content-between align-items-center mt-2'>
                                                    <div className='d-flex'>
                                                        {/* <i className=' ri-stack-line mx-2' style={{fontSize:'18px',color:'#7752FE'}}></i> */}
                                                        <img src={WonImage} width={'50px'}/>
                                                        <h6 className='mx-2 align-items-center d-flex'>Total Won</h6>
                                                    </div>  
                                                    <div>
                                                        <h6>{this.state.TotalSaleWon}</h6> 
                                                    </div>  
                                                </div>
                                                <div className='d-flex justify-content-between mt-4 align-items-center'>
                                                    <div className='d-flex'>
                                                        <img src={LostImage} width={'50px'}/>
                                                        <h6 className='mx-2 align-items-center d-flex'>Total Lost</h6>
                                                    </div>    
                                                    <h6>{this.state.TotalSaleLost}</h6>
                                                </div>

                                                <div className='d-flex justify-content-between mt-4 align-items-center'>
                                                        <div className='d-flex'>
                                                            <img src={PendingImage} width={'50px'}/>
                                                            <h6 className='mx-2 align-items-center d-flex'>Total Pending</h6>
                                                        </div>    
                                                        <h6>{this.state.TotalSalePending}</h6>
                                                </div>
                                                <div className='d-flex justify-content-between mt-4 align-items-center'>
                                                        <div className='d-flex'>
                                                            <img src={NAIcon} width={'50px'}/>
                                                            <h6 className='mx-2 align-items-center d-flex'>Total Cancel</h6>
                                                        </div>    
                                                        <h6>{this.state.TotalSaleCancel}</h6>
                                                </div>
                                        </Col>
                                        <Col lg={6}>
                                            <h5>Purchase</h5>
                                                <div className='d-flex justify-content-between align-items-center mt-2'>
                                                    <div className='d-flex'>
                                                        {/* <i className=' ri-stack-line mx-2' style={{fontSize:'18px',color:'#7752FE'}}></i> */}
                                                        <img src={WonImage} width={'50px'}/>
                                                        <h6 className='mx-2 align-items-center d-flex'>Total Won</h6>
                                                    </div>  
                                                    <div>
                                                        <h6>{this.state.TotalPurchaseWon}</h6> 
                                                    </div>  
                                                </div>
                                                <div className='d-flex justify-content-between mt-4 align-items-center'>
                                                    <div className='d-flex'>
                                                        <img src={LostImage} width={'50px'}/>
                                                        <h6 className='mx-2 align-items-center d-flex'>Total Lost</h6>
                                                    </div>    
                                                    <h6>{this.state.TotalPurchaseLost}</h6>
                                                </div>

                                                <div className='d-flex justify-content-between mt-4 align-items-center'>
                                                        <div className='d-flex'>
                                                            <img src={PendingImage} width={'50px'}/>
                                                            <h6 className='mx-2 align-items-center d-flex'>Total Pending</h6>
                                                        </div>    
                                                        <h6>{this.state.TotalPurchasePending}</h6>
                                                </div>
                                                <div className='d-flex justify-content-between mt-4 align-items-center'>
                                                        <div className='d-flex'>
                                                            <img src={NAIcon} width={'50px'}/>
                                                            <h6 className='mx-2 align-items-center d-flex'>Total Cancel</h6>
                                                        </div>    
                                                        <h6>{this.state.TotalPurchaseCancel}</h6>
                                                </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        {/* <Col lg={3} md={3}>
                            <Card>
                                <CardBody style={{background:'#FECDA6'}}>
                                <Link to="/admin-panel/sales-request">
                                    <div className='d-flex justify-content-between'>
                                        <h6 style={{color:'#FF5B22'}}>Total sale request</h6>
                                        <i className=' ri-stack-line' style={{fontSize:'18px',color:'#FF5B22'}}></i>    
                                    </div>
                                    <h4 style={{color:'#FF5B22'}}>{this.state.TotalSale}</h4>
                                    <h5 style={{borderBottom:'1px solid #FF5B22',marginTop:'20px'}}></h5>
                                    <h6 className='text-center' style={{color:'#FF5B22'}}>Current month</h6>  
                                </Link>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={3} md={3}>
                            <Card>
                                <CardBody style={{background:'#C2D9FF'}}>
                                    <Link to="/admin-panel/purchase-request">
                                    <div className='d-flex justify-content-between'>
                                        <h6 style={{color:'#7752FE'}}>Total purchase request</h6>
                                        <i className=' ri-stack-line' style={{fontSize:'18px',color:'#7752FE'}}></i>    
                                    </div>
                                    <h4 style={{color:'#7752FE'}}>{this.state.TotalPurachse}</h4>
                                    <h5 style={{borderBottom:'1px solid #7752FE',marginTop:'20px'}}></h5>
                                    <h6 className='text-center' style={{color:'#7752FE'}}>Current month</h6>
                                    </Link>
                                </CardBody>
                            </Card>
                        </Col> */}
                        <Col lg={3}>
                            {/* <SolicitorSaleWonLostChart/> */}
                            <Card>
                                <CardBody>
                                    {/* <h6 className='text-center mb-4 font-weight-bold'>Default current month data display</h6> */}
                                    <h6 className="mb-4">Sale success report</h6>
                                    <Row className="text-center">
                                        <Col xs={3}>
                                            <h5 className="mb-0">{this.state.TotalSaleWon}</h5>
                                            <p className="text-muted text-truncate">Won</p>
                                        </Col>
                                        <Col xs={3}>
                                            <h5 className="mb-0">{this.state.TotalSaleLost}</h5>
                                            <p className="text-muted text-truncate">Lost</p>
                                        </Col>
                                        <Col xs={3}>
                                            <h5 className="mb-0">{this.state.TotalSalePending}</h5>
                                            <p className="text-muted text-truncate">Pending</p>
                                        </Col>
                                        <Col xs={3}>
                                            <h5 className="mb-0">{this.state.TotalSaleCancel}</h5>
                                            <p className="text-muted text-truncate">Cancel</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Doughnut data={{
                                                labels: [
                                                    "Won",
                                                    "Lost",
                                                    "Pending",
                                                    "Cancel"
                                                ],
                                                datasets: [
                                                    {
                                                        data: [this.state.TotalSaleWon, this.state.TotalSaleLost,this.state.TotalSalePending,this.state.TotalSaleCancel],
                                                        backgroundColor: [
                                                            "#79AC78",
                                                            "#E78895",
                                                            "#F4D160",
                                                            "#E72929"
                                                        ],
                                                        hoverBackgroundColor: [
                                                            "#79AC78",
                                                            "#E78895",
                                                            "#F4D160",
                                                            "#E72929"
                                                        ],
                                                        hoverBorderColor: "#fff"
                                                    }]
                                            }} />
                                        </Col>
                                    </Row>

                                </CardBody>         
                            </Card>
                        </Col>
                        <Col lg={3}>
                            {/* <SolicitorPurchaseWonLostChart/> */}
                            <Card>
                                <CardBody>
                                    {/* <h6 className='text-center mb-4 font-weight-bold'>Default current month data display</h6> */}
                                    <h6 className="mb-4">Purchase success report</h6>
                                    <Row className="text-center">
                                        <Col xs={3}>
                                            <h5 className="mb-0">{this.state.TotalPurchaseWon}</h5>
                                            <p className="text-muted text-truncate">Won</p>
                                        </Col>
                                        <Col xs={3}>
                                            <h5 className="mb-0">{this.state.TotalPurchaseLost}</h5>
                                            <p className="text-muted text-truncate">Lost</p>
                                        </Col>
                                        <Col xs={3}>
                                            <h5 className="mb-0">{this.state.TotalPurchasePending}</h5>
                                            <p className="text-muted text-truncate">Pending</p>
                                        </Col>
                                        <Col xs={3}>
                                            <h5 className="mb-0">{this.state.TotalPurchaseCancel}</h5>
                                            <p className="text-muted text-truncate">Cancel</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Doughnut data={{
                                                labels: [
                                                    "Won",
                                                    "Lost",
                                                    "Pending",
                                                    "Cancel"
                                                ],
                                                datasets: [
                                                    {
                                                        data: [this.state.TotalPurchaseWon, this.state.TotalPurchaseLost,this.state.TotalPurchasePending,this.state.TotalPurchaseCancel],
                                                        backgroundColor: [
                                                            "#79AC78",
                                                            "#E78895",
                                                            "#F4D160",
                                                            "#E72929"
                                                        ],
                                                        hoverBackgroundColor: [
                                                            "#79AC78",
                                                            "#E78895",
                                                            "#F4D160",
                                                            "#E72929"
                                                        ],
                                                        hoverBorderColor: "#fff"
                                                    }]
                                            }} />
                                        </Col>
                                    </Row>

                                </CardBody>         
                            </Card>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col lg={3}>
                            <SolicitorSaleWonLostChart/>
                        </Col>
                        <Col lg={3}>
                            <SolicitorPurchaseWonLostChart/>
                        </Col>
                    </Row> */}
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default SolicitorDashboard;
