import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Collapse, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import CommonApi from '../../apis/CommonApi';
import Swal from 'sweetalert2';
import { Loading } from '../../helpers/globalfunctions';

const EditModal = ({isOpen,toggle,EditDetails,getSaleData,getPurchaseData}) => {
  if(!EditDetails)return null;
  // console.log(EditDetails.id);
  const [editdata,setEditData] =useState({
    property_address:EditDetails.property_address,
    property_value:EditDetails.property_value,
    title_fc:EditDetails.title_fc,
    first_name_fc:EditDetails.first_name_fc,
    last_name_fc:EditDetails.last_name_fc,
    surname_fc:EditDetails.surname_fc,
    email_fc:EditDetails.email_fc,
    telephone_fc:EditDetails.telephone_fc,
    company_name:EditDetails.company_name,

    title_sc:EditDetails.title_sc,
    first_name_sc:EditDetails.first_name_sc,
    last_name_sc:EditDetails.last_name_sc,
    surname_sc:EditDetails.surname_sc,
    email_sc:EditDetails.email_sc,
    telephone_sc:EditDetails.telephone_sc,
    company_name_sc:EditDetails.company_name_sc,


  })
  const [CollapseOpen,setCollapseOpen] = useState(true);
  //Collapse Open/close
  const Opencollapse = ()=>setCollapseOpen(true);
  const Closecollapse = ()=>setCollapseOpen(false);
  // console.log(editdata.property_address)

  const handleSubmit = (event)=>{
    event.preventDefault();
    const data = new FormData(event.target);  
    data.append("update_id",EditDetails.id);
    // console.log(data)   
    // Loading();
    if(EditDetails.sales_step){
      CommonApi.UpdateSaleRequest(data).then(response => {
        if(response.data.status == "success"){
          Swal.fire ({
            icon:"success",
            text:response.data.message, 
          });
          toggle();
          window.location.reload();
          getSaleData();
        }
  
      }).catch((error) => {
  
      }); 
    }else if(EditDetails.purchase_step){
      CommonApi.UpdatePurchaseRequest(data).then(response => {
        if(response.data.status == "success"){
          Swal.fire ({
            icon:"success",
            text:response.data.message,
          });
          toggle();
          window.location.reload();
          getPurchaseData();
        }
  
      }).catch((error) => {
  
      }); 
    }
  }
  // console.log(EditDetails)
  const handleInputChange = (event) => { const { name, value } = event.target; 
    setEditData(prevState => ({ ...prevState, [name]: value })); 
  }
  useEffect(()=>{
    setEditData({
      property_address: EditDetails.property_address,
      property_value: EditDetails.property_value,
      title_fc: EditDetails.title_fc,
      first_name_fc: EditDetails.first_name_fc,
      last_name_fc: EditDetails.last_name_fc,
      surname_fc: EditDetails.surname_fc,
      email_fc: EditDetails.email_fc,
      telephone_fc: EditDetails.telephone_fc,
      company_name: EditDetails.company_name,

      title_sc:EditDetails.title_sc,
      first_name_sc:EditDetails.first_name_sc,
      last_name_sc:EditDetails.last_name_sc,
      surname_sc:EditDetails.surname_sc,
      email_sc:EditDetails.email_sc,
      telephone_sc:EditDetails.telephone_sc,
      company_name_sc:EditDetails.company_name_sc,
    });
  },[EditDetails,getPurchaseData,getSaleData])
  return (
    <div>
      <Modal isOpen={isOpen} size='xl'>
        <ModalHeader>Edit</ModalHeader>
        <Form onSubmit={handleSubmit}>
            <ModalBody>

              <Row className="mt-3">
                <Col md={9}lg={9}>
                  <FormGroup>
                      <Label>Property Address</Label>
                      <Input type="text" name="property_address" id="property_address" value={editdata.property_address} onChange={handleInputChange}/>
                  </FormGroup>
                </Col>
                <Col md={3} lg={3}> 
                  <FormGroup>
                      <Label for="property_value">Property Value</Label>
                      <Input type="property_value" name="property_value" id="property_value" value={editdata.property_value}
                      onChange={handleInputChange}/>
                  </FormGroup>
                </Col>
              </Row> 
              <Row>
                <Col md={2}>
                  <FormGroup>
                  <Label for="">
                      Title 
                  </Label>
                  <Input
                      placeholder=""
                      type="select"
                      name="title_fc"
                      onChange={handleInputChange}
                  >
                      <option value={editdata.title_fc}>
                        {editdata.title_fc}
                      </option>
                      <option value="mr">
                          Mr
                      </option>
                      <option value="mrs">
                          Mrs
                      </option>
                      <option value="miss">
                          Miss
                      </option>
                      <option value="ms">
                          Ms
                      </option>
                      <option value="dr">
                          Dr
                      </option>
                  </Input>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                      <Label for="">
                          First Name <span style={{color:'red'}}>*</span>
                      </Label>
                      <Input
                      placeholder=""
                      type="text"
                      name="first_name_fc"
                      value={editdata.first_name_fc}
                      onChange={handleInputChange}
                      required/>
                  </FormGroup>
                </Col>

                <Col md={4}>
                  <FormGroup>
                      <Label for="">
                          Middle Name 
                      </Label>
                      <Input
                      placeholder=""
                      type="text"
                      name="last_name_fc"
                      value={editdata.last_name_fc}
                      onChange={handleInputChange}/>
                  </FormGroup>
                </Col>

                <Col md={2}>
                  <FormGroup>
                      <Label for="">
                          Surname 
                      </Label>
                      <Input
                      placeholder=""
                      type="text"
                      name="surname_fc"
                      value={editdata.surname_fc}
                      onChange={handleInputChange}/>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <FormGroup>
                      <Label for="">
                      Telephone 
                      </Label>
                      <Input
                      placeholder=""
                      type="text"
                      value={editdata.telephone_fc}
                      name="telephone_fc"
                      onChange={handleInputChange}/>
                  </FormGroup>
                </Col>
                {/* <Col md={4}>
                  <FormGroup>
                      <Label for="">
                          Email Address <span style={{color:'red'}}>*</span>
                      </Label>
                      <Input
                      placeholder=""
                      type="text"
                      value={editdata.email_fc}
                      onChange={handleInputChange}
                      name="email_fc"/>
                  </FormGroup>
                </Col> */}
                <Col md={4}>
                  <FormGroup>
                      <Label for="">
                      Company Name (if applicable)
                      </Label>
                      <Input
                      value={editdata.company_name}
                      onChange={handleInputChange}
                      type="text"
                      name="company_name" />
                  </FormGroup>
                </Col>
              </Row>
                <a className="d-flex justify-content-end" style={{cursor:'pointer'}} onClick={Opencollapse}>Add Second Client</a>
                <Collapse isOpen={CollapseOpen}>
                    <Row className='mt-4'>
                        <Col md={2}>
                          <FormGroup>
                          <Label for="">
                              Title 
                          </Label>
                          <Input
                              placeholder=""
                              type="select"
                              name="title_sc"
                              onChange={handleInputChange}
                          >
                              <option value={editdata.title_sc}>
                                {editdata.title_sc}
                              </option>
                              <option value="mr">
                                  Mr
                              </option>
                              <option value="mrs">
                                  Mrs
                              </option>
                              <option value="miss">
                                  Miss
                              </option>
                              <option value="ms">
                                  Ms
                              </option>
                              <option value="dr">
                                  Dr
                              </option>
                          </Input>
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup>
                              <Label for="">
                                  First Name 
                              </Label>
                              <Input
                              placeholder=""
                              type="text"
                              name="first_name_sc"
                              value={editdata.first_name_sc}
                              onChange={handleInputChange}/>
                          </FormGroup>
                        </Col>

                        <Col md={4}>
                          <FormGroup>
                              <Label for="">
                                  Middle Name 
                              </Label>
                              <Input
                              placeholder=""
                              type="text"
                              name="last_name_sc"
                              value={editdata.last_name_sc}
                              onChange={handleInputChange}/>
                          </FormGroup>
                        </Col>

                        <Col md={2}>
                          <FormGroup>
                              <Label for="">
                                  Surname 
                              </Label>
                              <Input
                              placeholder=""
                              type="text"
                              name="surname_sc"
                              value={editdata.surname_sc}
                              onChange={handleInputChange}/>
                          </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                          <FormGroup>
                              <Label for="">
                              Telephone 
                              </Label>
                              <Input
                              placeholder=""
                              type="text"
                              name="telephone_sc"
                              value={editdata.telephone_sc}
                              onChange={handleInputChange}/>
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup>
                              <Label for="">
                                  Email Address
                              </Label>
                              <Input
                              placeholder=""
                              type="text"
                              name="email_sc"
                              value={editdata.email_sc}
                              onChange={handleInputChange}/>
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup>
                              <Label for="">
                              Company Name (if applicable)
                              </Label>
                              <Input
                              type="text"
                              name="company_name_sc"
                              value={editdata.company_name_sc}
                              onChange={handleInputChange} />
                          </FormGroup>
                        </Col>
                    </Row>
                    <a className="d-flex justify-content-end" style={{cursor:'pointer'}} onClick={Closecollapse}>Remove Second Client</a> 
                </Collapse>
                
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
                <Button type="submit" color="primary">Save</Button>                           
            </ModalFooter>
        </Form>
      </Modal>
    </div>
  )
}

export default EditModal