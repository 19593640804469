import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from "react-router-dom";
//i18n
import { withTranslation } from "react-i18next";

// users
import avatar2 from '../../../assets/images/users/user_mock_light.png';

class ProfileMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
        this.toggle = this.toggle.bind(this);
    }


    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }

    render() {

    let username = "Name";
   if(localStorage.getItem("user.name"))
   {
        // const obj = JSON.parse(localStorage.getItem("user.name"));
        const uNm = localStorage.getItem("user.name");
        // const uNm = obj.email.split("@")[0];
        username = uNm.charAt(0).toUpperCase() + uNm.slice(1);
   }

        return (
            <React.Fragment>
                        <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block user-dropdown">
                            <DropdownToggle tag="button" className="btn header-item waves-effect" id="page-header-user-dropdown">
                                <img className="rounded-circle header-profile-user mr-1" src={avatar2} alt="Header Avatar"/>
                                <span className="d-none d-xl-inline-block ml-1 text-transform" style={{color:'#fff'}}>{username}</span>
                                <i className="mdi mdi-chevron-down d-none ml-1 d-xl-inline-block" style={{color:'#fff'}}></i>
                            </DropdownToggle>
                            <DropdownMenu right>
                                {
                                    (localStorage.getItem("user.user_scopes")=='supervisor')?(
                                        <Link to="/admin-panel/supervisore-check-out">
                                            <DropdownItem><i className="ri-exchange-line align-middle mr-1"></i> {this.props.t('Kit Check Out')}</DropdownItem>
                                        </Link>
                                    ):null
                                }
                                <Link to="/admin-panel/profile">
                                    <DropdownItem><i className="ri-user-line align-middle mr-1"></i> {this.props.t('Profile')}</DropdownItem>
                                </Link>
                                {/*<DropdownItem href="#"><i className="ri-wallet-2-line align-middle mr-1"></i> {this.props.t('My Wallet')}</DropdownItem>*/}
                                {/*<DropdownItem className="d-block" href="#"><span className="badge badge-success float-right mt-1">11</span><i className="ri-settings-2-line align-middle mr-1"></i> {this.props.t('Settings')}</DropdownItem>*/}
                                {/*<DropdownItem href="#"><i className="ri-lock-unlock-line align-middle mr-1"></i> {this.props.t('Lock screen')}</DropdownItem>*/}
                                <DropdownItem divider />
                                <DropdownItem className="text-danger" href="/admin-panel/logout"><i className="ri-shut-down-line align-middle mr-1 text-danger"></i> {this.props.t('Logout')}</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
            </React.Fragment>
        );
    }
}

export default withTranslation()(ProfileMenu);
