import React, { Component } from "react";
import { Table, Row, Col, Card, CardBody, Container, Button, Form, FormGroup, Label, Input, FormText,
Pagination, PaginationItem, PaginationLink, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Modal, ModalHeader, ModalBody, ModalFooter, CustomInput } from "reactstrap";
import ReactDOM from 'react-dom';
import User from '../../apis/User';
import ReactPaginate from 'react-paginate';
import { Link } from "react-router-dom";
import {DateFormat,DateTimeFormat,Loading,SuccessPopup, SwalClose, ErrorPopup} from "../../helpers/globalfunctions";
import CommonApi from '../../apis/CommonApi';
import Swal from 'sweetalert2';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

class UserManageMent extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Users", link : "#" },
                { title : "System Users", link : "#" },
            ],
            listEmployee:[],
            pageCount: 1,
            currentPage: 1,
            per_page:20,
            search:'',
            isWaiting:false,
            modalIsOpen: false,
            rolesList:[],
            role:'',
            companyList:[],
            CheckRole:false,
        };

        this.handlePageClick = this.handlePageClick.bind(this);
        this.rowPerPageChange = this.rowPerPageChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.statusChange=this.statusChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.InputChange = this.InputChange.bind(this);
    }

    componentDidMount() {
        this._isMounted = true
        this.getUserData()
        this.getRoleList()
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    //Get Use data
    getUserData() {
        User.employeeList(this.state.currentPage,this.state.per_page,this.state.search).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({listEmployee: response.data.data.data});
                    this.setState({pageCount: response.data.data.last_page});
                    this.setState({currentPage: response.data.data.current_page});
                    this.setState({per_page: response.data.data.per_page});
                }
            }
        });
    }

    getRoleList(){
      CommonApi.getRoleList().then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({rolesList: response.data.data});                    
                }
            }
        });
    }

     // row per page    
    async rowPerPageChange(event) {        
        await this.setState({per_page: event.target.value});        
        this.getUserData()
    }

    // page change event
    async handlePageClick(data) {
        const page = data.selected >= 0 ? data.selected + 1 : 0;
        await Promise.resolve(this.setState(() => ({ currentPage: page })));        
        this.getUserData()
    }

    // on change search
    async handleSearch(searchText) {
        await this.setState({ search: searchText.target.value });
        await Promise.resolve(this.setState(() => ({ currentPage: 1 })));
        this.getUserData()
    }
    

    /* change active status */
    async statusChange(id){
        // Loading()
        // this.setState({ isWaiting: true });
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to change this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Deactive User!"
          }).then((result) => {
            if (result.isConfirmed) {
                CommonApi.ChangeUserStatus(id).then(response => {
                    // SwalClose()
                    if (response) {                
                        this.getUserData();
                        // this.setState({ isWaiting: false });
                        Swal.fire({
                            title: "Deactive!",
                            text: "Your User has been deactive.",
                            icon: "success"
                        });
                    }
                });
            }
          });
    }    

    openModal = () => this.setState({ modalIsOpen: true });    
    closeModal = () => this.setState({ modalIsOpen: false });

    // Add user API
    handleSubmit(event) {
        event.preventDefault();
        Loading()
        const data = new FormData(event.target);
        CommonApi.AddUser(data).then(response => {
            SwalClose()
            
            if (response.data) {
                this.setState({modalIsOpen:false});
                SuccessPopup(response.data.message)
                this.getUserData();
            }
        }).catch((error) => {
            SwalClose()
            if (error.response) {
                ErrorPopup(error.response)
            }

        });      
    }

    async InputChange(e){
       await this.setState({role:e.target.value});
       if(this.state.role == 5 || this.state.role == 6){
        this.setState({CheckRole:true})
            User.companyList(this.state.role).then(res=>{
                this.setState({companyList:res.data.data});
            })
       }else{
        this.setState({CheckRole:false});
       }
    }
    
    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="System Users" breadcrumbItems={this.state.breadcrumbItems} />
                        

                        <Row>
                            <Col lg={12} className="mb-2">
                                <div>
                                  <Button color="primary" className="d-flex justify-content-center" onClick={this.openModal}><i className="ri-add-line"></i><span className="d-sm-inline-block me-2">Add</span></Button>
                                  <Modal isOpen={this.state.modalIsOpen}>
                                    <ModalHeader>Add User</ModalHeader>
                                    <Form onSubmit={this.handleSubmit}>
                                    <ModalBody>

                                            <FormGroup>
                                                <Label for="first_name">Name*</Label>
                                                <Input type="text" name="first_name" id="first_name" placeholder="Full Name" />
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="email">Email*</Label>
                                                <Input type="email" name="email" id="email" placeholder="Enter Email ID" />
                                            </FormGroup>
                                            
                                            <FormGroup>
                                                <Label for="password">Password*</Label>
                                                <Input type="password" name="password" id="password" placeholder="Enter Password" />
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="user_role">Role*</Label>
                                                <Input type="select" name="user_role" id="user_role" onChange={this.InputChange} >
                                                  <option value="">--Select Role--</option>                                                  
                                                  {
                                                        this.state.rolesList ?(
                                                            this.state.rolesList.map((option,index)=>{
                                                                return <option key={index} value={option.id}>{option.name}</option>
                                                            })
                                                        ): null
                                                    }
                                                </Input>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="company_name">Company Name{this.state.role == 2 ? '*':''}</Label>
                                                {this.state.CheckRole ? <Input type="select" name="company_name" id="company_name">
                                                  <option value="">--Select Company--</option>                                                  
                                                  {
                                                        this.state.companyList ?(
                                                            this.state.companyList.map((option,index)=>{
                                                                return <option key={index} value={option.company_name}>{option.company_name}</option>
                                                            })
                                                        ): null
                                                    }
                                                </Input> : <Input type="text" name="company_name" id="company_name" placeholder="Company Name" required={this.state.role == 2} />}
                                            </FormGroup>
                                            {this.state.role == 2 || this.state.role == 3 ? 
                                                <FormGroup>
                                                    <Label for="">Company Address</Label>
                                                    <Input type="text" name="address" id="" placeholder="Company Address" />
                                                </FormGroup>
                                            :''}

                                            {this.state.role == 2 || this.state.role == 3? 
                                                <FormGroup>
                                                    <Label for="">City</Label>
                                                    <Input type="text" name="city" id="" placeholder="City" />
                                                </FormGroup>
                                            :''}

                                            <FormGroup>
                                                <Label for="mobile_no">Mobile Number</Label>
                                                <Input type="text" name="mobile_no" id="mobile_no" placeholder="Enter Mobile Number" />
                                            </FormGroup>
                                        
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="secondary" onClick={this.closeModal}>Cancel</Button>{' '}
                                        <Button type="submit" color="primary">Save</Button>                           
                                    </ModalFooter>
                                    </Form>
                                  </Modal>
                                </div>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>                                       

                                        <Row>
                                            <Col lg={2}>
                                                <Row>
                                                    <Col lg={3}>
                                                        <FormGroup className="mt-2">                                                    
                                                            <Label for="selectRows">Show</Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <FormGroup>                                                    
                                                            <Input type="select" name="selectRows" onChange={this.rowPerPageChange} id="selectRows" value={this.state.per_page}
                                                             >
                                                              <option value='10'>10</option>
                                                              <option value='20'>20</option>
                                                              <option value='50'>50</option>
                                                              <option value='100'>100</option>
                                                              <option value='500'>500</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>                                                 
                                            </Col>
                                            <Col lg={7}>
                                                {/*<Button>Print</Button>*/}
                                            </Col>
                                            <Col lg={3}>
                                                 <Row>
                                                    <Col lg={3}>
                                                        <FormGroup className="mt-2">                                    
                                                            <Label for="search">search</Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={9}>
                                                        <Form>
                                                          <FormGroup>                                                            
                                                            <Input type="text" onKeyUp={this.handleSearch} name="search" id="search" placeholder="" />
                                                          </FormGroup>
                                                        </Form>
                                                    </Col>
                                                 </Row>                                                
                                            </Col>

                                        </Row>


        
                                        <div className="table-responsive">
                                            <Table className="mb-0">
                                                <thead className="bg-primary text-white">
                                                    <tr>                                     <th className="thead-left"></  th>  
                                                        <th>Created Date</th>      
                                                        <th>Id</th>          
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Company name</th>
                                                        <th>Role</th>
                                                        <th>Mobile</th>
                                                        <th>Status</th>                          
                                                        <th>Action</th>
                                                        <th className="thead-right"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.listEmployee ?(
                                                            this.state.listEmployee.map((option,index)=>{
                                                                return <tr key={index}className={option.status=='ACTIVE'?'completed-tr':''}>

                                                                <td className={option.status=='ACTIVE'? 'completed-td-left':''}></td>   
                                                                          <td>{DateFormat(option.created_at)}</td>
                                                                          <td>{option.id}</td>            
                                                                          <td>{option.first_name}</td>
                                                                          <td>{option.email}</td>
                                                                          <td>{option.company_name}</td>
                                                                          <td>{option.role_name}</td>              
                                                                          <td>{option.mobile_no}</td>
                                                                          <td>{option.status}</td>                                                                          
                                                                          <td>
                                                                             <Button onClick={() =>this.statusChange(option.id)} disabled={this.state.isWaiting} className="btn-sm" color={option.status=='ACTIVE'?'success':'danger'}>{option.status}</Button>                                                        
                                                                          </td>
                                                                          <td className={option.status=='ACTIVE'? 'completed-td-right':''}></td> 
                                                                       </tr>
                                                            })
                                                        ): null
                                                    }
                                                   
                                                    
                                                </tbody>
                                            </Table>
                                        </div>

                                    {/* paginnation */}
                                    <ReactPaginate
                                      previousLabel="&#x276E;"
                                      nextLabel="&#x276F;"
                                      breakLabel={'...'}
                                      breakClassName={'break-me'}
                                      pageCount={this.state.pageCount}
                                      initialPage={this.state.currentPage - 1}
                                      forcePage={this.state.currentPage - 1}
                                      marginPagesDisplayed={2}
                                      pageRangeDisplayed={5}
                                      onPageChange={this.handlePageClick}
                                      containerClassName={'pagination mt-4 float-right'}
                                      activeClassName={'active'}
                                      disableInitialCallback={true}
                                    /> 
        
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default UserManageMent;
