import React, { Component } from "react";
import { Table, Row, Col, Card, CardBody, Container, Button, Form, FormGroup, Label, Input, FormText,
Pagination, PaginationItem, PaginationLink, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Modal, ModalHeader, ModalBody, ModalFooter, CustomInput, Collapse, CardTitle } from "reactstrap";
import ReactDOM from 'react-dom';
import User from '../../apis/User';
import ReactPaginate from 'react-paginate';
import { Link } from "react-router-dom";
import {DateFormat,DateTimeFormat,Loading,SuccessPopup, SwalClose, ErrorPopup} from "../../helpers/globalfunctions";
import CommonApi from '../../apis/CommonApi';
import Swal from 'sweetalert2';
import FileUploadPurchase from '../Common/FileUploadPurchase';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';

//Import Breadcrumb
import Breadcrumbs from '../Common/Breadcrumb';
import { TextField, Tooltip } from "@mui/material";
import E from "react-script";
import InvoiceModal from "../Common/Invoice";
import EditModal from "../Common/EditModal";

class PurchaseReq extends Component {
    componentRef = null;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Purchase Request", link : "#" },
            ],
            modalIsOpen: false,
            CollapseOpen:false,
            ListFms:[],
            StepSubmitModal:false,
            StepList:[],
            ViewPurchaseDetail:'',
            ViewDetailModal:false,
            // WinLoseStatus:'',
            WinLoseModal:false,
            FmsTrackId:'',
            DisbursementsFeeList:[],
            DisbursementsFeeModal:false,
            DisbursementsFeeValue:0,
            DisbursementsVat:0,
            DisbursementsVatValue:0,
            selectedId:'',
            DisbursementsTotal:0,
            pageCount: 1,
            currentPage: 1,
            per_page:50,
            sort:'',
            questions:[],
            from_date:'',
            to_date:'',
            StepDetailViewModal:false,
            search:'',
            note:'',
            SolicitorModal:false,
            PurchaseId:'',
            UploadViewModal:false,            
            purchase_id:'',
            attachmentList:[],
            InvoiceContent:null,
            InvoiceModal:false,
            InvoiceDetails:'',
            EditContent:null,
            EditModal:false,
            EditDetails:''
        };
        this.HandleSubmit=this.HandleSubmit.bind(this);
        this.StepView= this.StepView.bind(this);
        this.ViewDetails = this.ViewDetails.bind(this);
        this.HandleSubmitStep = this.HandleSubmitStep.bind(this);
        this.WinLoseSubmit = this.WinLoseSubmit.bind(this);
        this.DisbursementsFee = this.DisbursementsFee.bind(this);
        this.HandleChangeFee = this.HandleChangeFee.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.rowPerPageChange = this.rowPerPageChange.bind(this);
        this.FromdateChange= this.FromdateChange.bind(this);
        this.TodateChange=this.TodateChange.bind(this);
        this.StepView = this.StepView.bind(this);
        this.StepSubmitView = this.StepSubmitView.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.HandleNoteSubmit =this.HandleNoteSubmit.bind(this);
        this.SubmitSelectSolicitor = this.SubmitSelectSolicitor.bind(this);
        this.ClosePurchaseUploadModal=this.ClosePurchaseUploadModal.bind(this);
        this.HandleInvoice = this.HandleInvoice.bind(this);
        this.EditHandler = this.EditHandler.bind(this);
        this.DeletePurchase=this.DeletePurchase.bind(this);
    }

    componentDidMount() {
        this._isMounted = true
        this.getPurchaseData()
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    //Modal open/close
    openModal = () => this.setState({ modalIsOpen: true });    
    closeModal = () => this.setState({ modalIsOpen: false });

    //Collapse Open/close
    Opencollapse = ()=>this.setState({CollapseOpen:true});
    Closecollapse = ()=>this.setState({CollapseOpen:false});

    //Step Submit Modal 
    StepSubmitOpenModal = () => this.setState({ StepSubmitModal: true });    
    StepSubmitCloseModal = () => this.setState({ StepSubmitModal: false });
    
    //viewDetail Modal open/close
    OpenViewDetailModal = ()=>this.setState({ViewDetailModal:true});
    CloseViewDetailModal = ()=>this.setState({ViewDetailModal:false});

    //winlose Status 
    OpenWinLoseModal = ()=>this.setState({WinLoseModal:true});
    CloseWinLoseModal = ()=>this.setState({WinLoseModal:false});

    //DisbursementsFee modal
    OpenDisbursementsFeeModal = ()=>this.setState({DisbursementsFeeModal:true});
    CloseDisbursementsFeeModal = ()=>this.setState({DisbursementsFeeModal:false});

    //steps view modal
    OpenStepDetailModal = ()=>this.setState({StepDetailViewModal:true});
    CloseStepDetailModal = ()=>this.setState({StepDetailViewModal:false});

    //conveyancer select modal
    OpenSolicitorModal = ()=>this.setState({SolicitorModal:true});
    CloseSolicitorModal = ()=>this.setState({SolicitorModal:false});

    //upload model
    OpenUploadModal = ()=>this.setState({UploadViewModal:true});
    ClosePurchaseUploadModal(){this.setState({UploadViewModal:false})};

    //upload modal purchase
    async UploadModal(id){
        this.OpenUploadModal();
        await this.setState({purchase_id: id});
        CommonApi.getPurchaseFiles(id).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({attachmentList:response.data.data.result.attachments})
                }
            }
        });
    }

    //Form Submit
    HandleSubmit(event) {
        event.preventDefault();
        Loading()
        const data = new FormData(event.target);
        CommonApi.PurchasePropertyRequest(data).then(response => {
            SwalClose()
            
            if (response.data) {
                // this.setState({modalIsOpen:false});
                this.closeModal();
                this.getPurchaseData();
                SuccessPopup(response.data.message)
            }
        }).catch((error) => {
            SwalClose()
            if (error.response) {
                ErrorPopup(error.response)
            }

        });      
    }

    //Fetch purchase Property data
    getPurchaseData() {
        CommonApi.SolicitorPurchaseFetch(this.state.currentPage,this.state.per_page,this.state.from_date,this.state.to_date,this.state.search).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({ListFms: response.data.data.data});
                    this.setState({pageCount: response.data.data.last_page});
                    this.setState({currentPage: response.data.data.current_page});
                    this.setState({per_page: response.data.data.per_page});
                }
            }
        });
    }

    //date change event
    async FromdateChange(e){
        await this.setState({from_date: e.target.value});  
        this.getPurchaseData();       
    }
    async TodateChange(e){
        await this.setState({to_date:e.target.value});
        this.getPurchaseData() 
    }

    //Step view
    StepView(id){
        // event.preventDefault();
        this.OpenStepDetailModal();
        CommonApi.PurchasePropertyFetchSteps(id).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({StepList:response.data.data.result})
                }
            }
        });
        // console.log(id);
    }

    //Step Submit view
    StepSubmitView(id){
        // event.preventDefault();
        this.StepSubmitOpenModal();
        CommonApi.PurchasePropertyFetchSteps(id).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({StepList:response.data.data.result})
                }
            }
        });
        // console.log(id);
    }

    ViewDetails(id){
        this.OpenViewDetailModal();
        CommonApi.PurchasePropertyView(id).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({ViewPurchaseDetail:response.data.data.result})
                    // console.log(this.state.ViewPuchaserDetail);
                }
            }
        });

        //Purchase Questions
        CommonApi.GetPurchaseQuestions().then(response=>{
            if (response) {
                if(this._isMounted) {
                    this.setState({questions:response.data.data})
                }
            }
        })
    }
    
    //Step Submit 
    async HandleSubmitStep(id,step_no,e){
        // console.log(id);
        await this.setState({FmsTrackId:id})
        if(step_no == 1){
            this.OpenWinLoseModal();
            this.StepSubmitCloseModal();
        }else{
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to change this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!'
            }).then((result) => {
                if (result.isConfirmed) {
                    const data = new FormData();
                    data.append('id',this.state.FmsTrackId);
                    CommonApi.PurchasePropertyStepSubmit(data).then(response => {
                        if (response) {
                            if(this._isMounted) {
                                this.getPurchaseData();
                                this.StepSubmitCloseModal();
                                Swal.fire(
                                    'Completed',
                                    'Your step has been completed.',
                                    'success'
                                )   
                            }
                        }
                    });
                }
            })
        }
    }

    onNoteChange(id,value){
        this.setState((preState)=>({
            StepList:preState.StepList.map((data)=>data.id === id ? {...data,note:value}:data)
        }))
        this.setState({note:value})
    }
    //note submit
    async HandleNoteSubmit(id){
        await this.setState({FmsTrackId:id})
        Swal.fire({
            title: 'Are you sure?',
            // text: "You won't be able to change this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!'
        }).then((result) => {
            if (result.isConfirmed) {
                const data = new FormData();
                data.append('id',this.state.FmsTrackId);
                data.append('note',this.state.note);
                CommonApi.PurchasePropertyNoteSubmit(data).then(response => {
                    if (response) {
                        if(this._isMounted) {  
                            Swal.fire({
                                toast: true,
                                position: 'center',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                                icon: 'success',
                                title: 'Note added',
                            }) 
                        }
                    }
                });
            }
        })
    }

    //Win Lose Status Submit 
    WinLoseSubmit(event){
        event.preventDefault();
        const data = new FormData(event.target);
        data.append('id',this.state.FmsTrackId);
        // console.log(this.state.FmsTrackId)
        CommonApi.PurchasePropertyStepSubmit(data).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.getPurchaseData();
                    this.CloseWinLoseModal();
                    Swal.fire(
                        'Completed',
                        'Your step has been completed.',
                        'success'
                    )   
                }
            }
        });
    }

    // DisbursementsFee Submit view
    DisbursementsFee(id){
        // event.preventDefault();
        this.OpenDisbursementsFeeModal();
        CommonApi.GetPurchaseDisbursementsFee(id).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({DisbursementsFeeList:response.data.data.result})
                }
            }
        });
        // console.log(id);
        CommonApi.PurchasePropertyView(id).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({ViewPurchaseDetail:response.data.data.result})
                    // console.log(this.state.ViewPuchaserDetail);
                }
            }
        });

        //Purchase Questions
        CommonApi.GetPurchaseQuestions().then(response=>{
            if (response) {
                if(this._isMounted) {
                    this.setState({questions:response.data.data})
                }
            }
        })
    }

    //DisbursementsFee OnChange
    HandleChangeFee(id,disbursement_charge){
        // const DisbursementsFeeValue = parseFloat(e.target.value) || 0;
        // this.setState({DisbursementsFeeValue},this.calculateTotal);
        const UpdatedFee = this.state.DisbursementsFeeList.map(DisbursementsFeeData=>DisbursementsFeeData.id === id ? {...DisbursementsFeeData, disbursement_charge:parseFloat(disbursement_charge) || 0 }: DisbursementsFeeData);
        this.calculateTotal(UpdatedFee);
    }
    HandleChangeTax(id,vat){
        // const DisbursementsVat = parseFloat(e.target.value) || 0;
        // this.setState({DisbursementsVat},this.calculateTotal);
        const UpdatedFee = this.state.DisbursementsFeeList.map(DisbursementsFeeData=>DisbursementsFeeData.id === id ? {...DisbursementsFeeData, vat:parseFloat(vat) }: DisbursementsFeeData);
        this.calculateTotal(UpdatedFee);
    }
    calculateTotal = (UpdatedFee)=>{
        // const {DisbursementsFeeValue , DisbursementsVat , selectedId} =this.state;
        // const DisbursementsTotal = DisbursementsFeeValue + (DisbursementsFeeValue * DisbursementsVat) / 100
        // this.setState({DisbursementsTotal});
        const DisbursementsTotal = UpdatedFee.map(DisbursementsFeeData=>({
            ...DisbursementsFeeData,
            disbursement_total : (parseFloat(DisbursementsFeeData.disbursement_charge) | 0 ) + ((parseFloat(DisbursementsFeeData.disbursement_charge) | 0 )*(parseFloat(DisbursementsFeeData.vat)/100 )|| 0)
        }))
        this.setState({DisbursementsFeeList:DisbursementsTotal});
    }

    // row per page    
    async rowPerPageChange(event) {        
        await this.setState({per_page: event.target.value});        
        this.getPurchaseData()
    }

    // page change event
    async handlePageClick(data) {
        const page = data.selected >= 0 ? data.selected + 1 : 0;
        await Promise.resolve(this.setState(() => ({ currentPage: page })));        
        this.getPurchaseData()
    }

     //Disbursements Submit
     HandleDisbursementsSubmit=(e)=>{
        e.preventDefault();
        Loading()
        CommonApi.UpdateDisbursementsFee(this.state.DisbursementsFeeList).then(res=>{
            if(res.status == 200){
                SwalClose();
                this.CloseDisbursementsFeeModal();
                Swal.fire({
                    icon: "success",
                    title: "Disbursements fees successfully saved",
                    showConfirmButton: false,
                    timer: 1500
                });
                this.getPurchaseData();
            }else{
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                  });
            }
        })
    }

    // on change search
    async handleSearch(e) {
        await this.setState({ search: e.target.value });
        await Promise.resolve(this.setState(() => ({ currentPage: 1 })));
        this.getPurchaseData()
    }

    //select conveyancer
    async SelectSolicitor(solicitor_ref_id,conveyancer_name,id){
        this.setState({solicitor_name:conveyancer_name});
        this.OpenSolicitorModal();
        await this.setState({PurchaseId:id});
        const company_parent_id = solicitor_ref_id;
        CommonApi.getPurchaseConveyancer(company_parent_id).then(res=>{
            this.setState({solicitorList:res.data.data})
        })
    }

    //Submit Solicitor Modal 
    SubmitSelectSolicitor(e){
        e.preventDefault();
        const data = new FormData(e.target);
        data.append('id',this.state.PurchaseId);
        CommonApi.PurchaseSolicitorSubmit(data).then(res=>{
            if(res.status == 200){
                this.CloseSolicitorModal();
                Swal.fire(
                    {
                        timer: 1500,
                        icon:'success',
                        showConfirmButton:false
                    }
                )
                this.getPurchaseData();
            }
        })
    }
    //Invoice handler
    async HandleInvoice(content){
        this.setState({InvoiceModal:true});
        await this.setState({InvoiceContent:content})
        // console.log(this.state.InvoiceContent)
    }

    //Cancel 
    async CancelRequest(id){
        Swal.fire({
            title: "Are you sure?",
            // text: "It will permanent delete from system!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Cancel it!"
          }).then((result) => {
            if (result.isConfirmed) {                
                Loading()                
                CommonApi.CancelPurchase(id).then(response => {
                    SwalClose()                    
                    if (response.data) {
                        this.getPurchaseData();
                        SuccessPopup(response.data.message)
                    }
                }).catch((error) => {
                    SwalClose()
                    if (error.response) {
                        ErrorPopup(error.response)
                    }

                });
            }
          });
    }

    //Edit handler
    async EditHandler(content){
        this.setState({EditModal:true});
        await this.setState({EditContent:content})
        this.getPurchaseData()
        // console.log(this.state.EditContent)
    }
    //Delete Purchase
    async DeletePurchase(id){
        Swal.fire({
            title: "Are you sure?",
            text: "It will permanent delete from system!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Delete it!"
          }).then((result) => {
            if (result.isConfirmed) {                
                Loading()                
                CommonApi.deletePurchase(id).then(response => {
                    SwalClose()                    
                    if (response.data) {
                        this.getPurchaseData();
                        SuccessPopup(response.data.message)
                    }
                }).catch((error) => {
                    SwalClose()
                    if (error.response) {
                        ErrorPopup(error.response)
                    }

                });
            }
          });
    }
    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Purchase Request" breadcrumbItems={this.state.breadcrumbItems} />
                    <InvoiceModal isOpen={this.state.InvoiceModal} InvoiceDetails={this.state.InvoiceContent} 
                    toggle={() => this.setState({ InvoiceModal: false })}/>

                    <EditModal isOpen={this.state.EditModal} EditDetails={this.state.EditContent} 
                    toggle={() => this.setState({ EditModal: false })}
                    getPurchaseData={this.getPuchaseData}/>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody> 
                                    <Row>
                                        {/* <Col lg={2}>
                                            <Row>
                                                <Col lg={3} className="d-flex justify-content-end">
                                                    <FormGroup className="mt-2">                                                    
                                                        <Label for="selectRows"><i className="  fas fa-filter"></i></Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={6}>
                                                    <FormGroup>                                                    
                                                        <Input type="select" name="selectRows" onChange={this.rowSortChange} id="selectRows" value={this.state.sort}
                                                            >                                                              
                                                            <option value=''>All</option>
                                                            <option value='Won'>Won</option>
                                                            <option value='Lost'>Lost</option>
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                            </Row>                                                 
                                        </Col> */}
                                        <Col lg={3}>
                                                <Row>
                                                <Col lg={1}className="d-flex justify-content-start">
                                                    <FormGroup className="mt-2"> <Label for="selectRows"><i className=" fas fa-sort-amount-down-alt"></i></Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={5}>
                                                    <FormGroup>                                                    
                                                        <Input type="select" name="selectRows" onChange={this.rowPerPageChange} id="selectRows" value={this.state.per_page}
                                                            >                                                              
                                                            <option value=''>All</option>
                                                            <option value='50'>50</option>
                                                            <option value='100'>100</option>
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                </Row>                                                
                                        </Col>

                                        <Col lg={3}>
                                            <Row>
                                                <Col lg={1}className="d-flex justify-content-start">
                                                    <FormGroup className="mt-2"> <Label for="selectRows"><i className=" fas fa-search"></i></Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={9}>
                                                    <FormGroup>
                                                        <Input type="text" name="" onChange={this.handleSearch} id="selectRows" value={this.state.search} placeholder="search..."
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg={6}>
                                            <Row className="justify-content-end">
                                                <Col lg={2} className="d-flex justify-content-end">
                                                    <FormGroup className="mt-2">                                                    
                                                        <Label for="">From<i class=" fas fa-calendar-alt mx-2"></i></Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={3}>
                                                    <FormGroup>                                                            
                                                        <Input type="date" name="created_at" onChange={this.FromdateChange}  />
                                                    </FormGroup>
                                                </Col>

                                                <Col lg={2} className="d-flex justify-content-end">
                                                    <FormGroup className="mt-2">                                                    
                                                        <Label for="">To<i class=" fas fa-calendar-alt mx-2"></i></Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={3}>
                                                    <FormGroup>                                                            
                                                        <Input type="date" name="created_at" onChange={this.TodateChange} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <div className="table-responsive">
                                        <Table className="mb-0">
                                            <thead className="bg-primary text-white">
                                                <tr>
                                                    <th>Created Date</th>
                                                    <th>Id</th>
                                                    <th>Property Address</th>
                                                    <th>Property Value</th>
                                                    <th>Full Name</th>
                                                    <th style={{width:'35%'}}>Step Track</th>
                                                    <th>Steps</th>
                                                    {/* <th>Final Status</th> */}
                                                    {/* <th>Won / Lost</th> */}
                                                    <th>Agent company name</th>
                                                    <th>estate agent name </th>
                                                    <th>solicitor company name </th>
                                                    <th>conveyancer name</th>
                                                    <th>Admin fee</th>
                                                    <th>View</th>
                                                    {/* <th>Disbursements Charge</th> */}
                                                    {/*<th>Step submit</th>*/}
                                                    <th>select conveyancer</th>
                                                    <th>Upload</th>
                                                    <th>Edit/Delete</th>
                                                    <th>Cancel</th>
                                                    <th style={{minWidth:'85px'}}>Won / Lost</th>
                                                </tr>
                                            </thead>
                                            <tbody>  
                                                {
                                                    this.state.ListFms ?(this.state.ListFms.map((option,index)=>{
                                                        return <tr key={index}>
                                                            <td>{DateFormat(option.created_at)}</td>
                                                            <td>{option.id}</td>
                                                            <td>{option.property_address}</td>
                                                            <td>£{option.property_value}</td>
                                                            <td className="d-flex">
                                                                <span className="mx-1">{option.first_name_fc}</span><span>{option.surname_fc}</span></td>
                                                            <td>
                                                                <div className="flat pipeline">
                                                                    {option.purchase_step ?(option.purchase_step.map((value,index)=>{
                                                                        return <Tooltip arrow title={value.step_name}><a key={index} text={value.step_name}className={value.status=='Pending'?'pending-pipeline':(value.status=='Completed')?'completed-pipeline'
                                                                        :(value.status=='Cancel')?'cancel-pipeline':''}><span></span></a></Tooltip>
                                                                    })):null}
                                                                </div>
                                                            </td>
                                                            <td><i className=" ri-funds-box-fill" style={{fontSize:'18px',color:'#192a56'}} onClick={()=>this.StepView(option.id)}></i></td>

                                                            {/* <td style={option.final_status == 'Completed'? {background:'#5D9C59'}:option.final_status == 'Pending'?{background:'#F4D160'}:null}>{option.final_status}</td> */}

                                                            {/* <td style={option.win_lose == 'Won'? {background:'#5D9C59'}:option.win_lose=='Lost'?{background:'#EF4040'}:null}>{option.win_lose}</td> */}
                                                            <td>{option.agent_company_name}</td>
                                                            <td>{option.agent_name}</td>
                                                            <td>{option.soli_company}</td>
                                                            <td>{option.conveyancer_name}</td>
                                                            <td>£{option.admin_fee}{option.admin_fee > 0 ? <i className="fas fa-file-invoice"style={{fontSize:'15px',color:'#192a56',marginLeft:'5px'}} onClick={()=>this.HandleInvoice(option)}></i>:''}</td>
                                                            <td>{option.final_status=='Cancel'?'':<i className=" mdi mdi-eye" style={{fontSize:'18px',color:'#192a56'}} onClick={()=>this.ViewDetails(option.id)}></i>}</td>

                                                            {/* mdi-auto-fix */}
                                                            {/* <td><i className=" ri-money-pound-circle-fill" title="DisbursementsFee" style={{fontSize:'18px',color:'#192a56'}}color='primary'onClick={()=>this.DisbursementsFee(option.id)}></i></td> */}

                                                            {/*<td><i className="ri-edit-box-fill" style={{fontSize:'18px',color:'#192a56'}}color='primary'onClick={()=>this.StepSubmitView(option.id)}></i></td>*/}

                                                            <td><i className="ri-edit-box-fill" style={option.win_lose=='Won'? {display:'none'}:option.win_lose=='Lost'? {display:'none'}:{fontSize:'18px',color:'#192a56'}} color='primary' onClick={()=>this.SelectSolicitor(option.solicitor_ref_id,option.conveyancer_name,option.id)}></i></td>

                                                            <td>{option.final_status=='Cancel'?'':<i className="ri-upload-2-line" style={{fontSize:'18px',color:'#192a56'}} onClick={()=>this.UploadModal(option.id)}></i>}</td>

                                                            <td>{option.final_status == 'Cancel'?'':<i className=" ri-edit-2-fill"style={{fontSize:'15px',color:'#192a56'}} onClick={()=>this.EditHandler(option)}></i>}<i className="ri-delete-bin-line" style={{fontSize:'18px',color:'red',marginLeft:'7px'}} onClick={()=>this.DeletePurchase(option.id)}></i></td>

                                                            <td style={option.final_status == 'Cancel'? {background:'red',color:'#fff'}:null}>{option.final_status == 'Cancel' ? <span>{option.final_status}</span>:
                                                            <i className="dripicons-cross" style={{fontSize:'18px',color:'red'}} onClick={()=>this.CancelRequest(option.id)}></i>}</td>

                                                            <td style={option.win_lose == 'Won'? {background:'#5D9C59'}:option.win_lose=='Lost'?{background:'#EF4040'}:null}>{option.win_lose}</td>
                                                        </tr>
                                                    })
                                                    ):null
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                    <Modal isOpen={this.state.StepSubmitModal} size='xl' >
                                        <ModalHeader className="modal-header" toggle={this.StepSubmitCloseModal}>Purchase property step </ModalHeader>
                                        <ModalBody>
                                            <Row>
                                                <Col md={6} sm={6} lg={6}>
                                                    <Table>
                                                    <thead>
                                                        <tr style={{'fontWeight':'bold','fontSize':'16px'}}>
                                                            <th style={{width:'10%'}}>Step No</th>
                                                            <th style={{width:'80%'}}>Step Name</th>
                                                            <th style={{width:'10%'}}>Submit</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.StepList ?(this.state.StepList.map((stepValue,index)=>{
                                                                return(stepValue.step_no >= 1 && stepValue.step_no <= 8)? <tr key={index}>
                                                                <td>{stepValue.step_no}</td>
                                                                <td>{stepValue.step_name}</td>
                                                                <td>{stepValue.status == 'Pending'?<Button className="btn-sm" type="button" onClick={()=>this.HandleSubmitStep(stepValue.id,stepValue.step_no,)}color="warning"><i className=" ri-send-plane-fill" style={{fontSize:'16px'}}></i></Button>:<Button title="Completed" className="btn-sm" color="success" disabled><i className=" ri-checkbox-circle-fill" style={{fontSize:'16px'}}></i></Button>}</td>
                                                            </tr>:null 
                                                            })):null
                                                        }
                                                    </tbody>
                                                    </Table>
                                                </Col>
                                                <Col md={6} sm={6} lg={6}>
                                                    <Table>
                                                    <thead>
                                                        <tr style={{'fontWeight':'bold','fontSize':'16px'}}>
                                                            <th style={{width:'10%'}}>Step No</th>
                                                            <th style={{width:'80%'}}>Step Name</th>
                                                            <th style={{width:'20%'}}>Submit</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.StepList ?(this.state.StepList.map((stepValue,index)=>{
                                                                return(stepValue.step_no >= 9 && stepValue.step_no <= 16)? <tr key={index}>
                                                                <td>{stepValue.step_no}</td>
                                                                <td>{stepValue.step_name}</td>
                                                                <td>{stepValue.status == 'Pending'?<Button className="btn-sm" type="button" onClick={()=>this.HandleSubmitStep(stepValue.id)}color="warning"><i className=" ri-send-plane-fill" style={{fontSize:'16px'}}></i></Button>:<Button title="Completed" className="btn-sm" color="success" disabled><i className=" ri-checkbox-circle-fill" style={{fontSize:'16px'}}></i></Button>}</td>
                                                            </tr>:null 
                                                            })):null
                                                        }
                                                    </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        </ModalBody>
                                        {/* 
                                        <ModalFooter>
                                            <Button color="secondary" onClick={this.StepSubmitCloseModal}>Cancel</Button>                          
                                        </ModalFooter> */}
                                    </Modal>

                                    {/* View Detail Modal */}
                                    <Modal isOpen={this.state.ViewDetailModal} size='xl' >
                                        <ModalHeader className="modal-header" toggle={this.CloseViewDetailModal}> 
                                            
                                            <span className="align-middle flex-grow-1">All Detail</span>
                                            <div> 
                                                <ReactToPrint
                                                    trigger={() => <i className="mdi mdi-printer" style={{fontSize:'18px',color:'white'}}></i>}
                                                    content={() => this.componentRef}
                                                />
                                            </div>
                                        
                                        </ModalHeader>

                                        {/* Print section */}
                                        <div ref={el => (this.componentRef = el)} >
                                        <ModalBody>
                                            {this.state.ViewPurchaseDetail? (this.state.ViewPurchaseDetail.map((value,index)=>{
                                                return (
                                                    <>
                                                        <Row key={index} className="mt-4">
                                                            <Col md={6}>
                                                                <Card>
                                                                    <CardTitle className="d-flex justify-content-center">Customer Details</CardTitle>
                                                                    <CardBody>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Property Address :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.property_address}</h6>
                                                                            </Col>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Property Value :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>£{value.property_value}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Freehold/Leasehold :</h6>
                                                                            </Col>
                                                                            <Col lg={5} md={5}>
                                                                                <h6>{value.hold_type}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Title :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.title_fc}</h6>
                                                                            </Col>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>First Name :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.first_name_fc}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Middle Name :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.last_name_fc}</h6>
                                                                            </Col>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Surname :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.surname_fc}</h6>
                                                                            </Col>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Telephone :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.telephone_fc}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Email :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.email_fc}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Company :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.company_name}</h6>
                                                                            </Col>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Card>
                                                                    <CardTitle className="d-flex justify-content-center">2nd Person Details</CardTitle>
                                                                    <CardBody>             

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Title :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.title_sc}</h6>
                                                                            </Col>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>First Name :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.first_name_sc}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Middle Name :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.last_name_sc}</h6>
                                                                            </Col>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Surname :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.surname_sc}</h6>
                                                                            </Col>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Telephone :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.telephone_sc}</h6>
                                                                            </Col>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Email :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.email_sc}</h6>
                                                                            </Col>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <Col lg={6} md={6}>
                                                                                <h6>Company :</h6>
                                                                            </Col>
                                                                            <Col lg={6} md={6}>
                                                                                <h6>{value.company_name_sc}</h6>
                                                                            </Col>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                                {/* <Card>
                                                                    <CardTitle className="d-flex justify-content-center">Disbursements</CardTitle>
                                                                        <div className="d-flex" style={{background:'#192a56'}}>
                                                                            <Col lg={6} md={6}>
                                                                                <h5 style={{color:'#fff'}}>Solicitor Fee :</h5>
                                                                            </Col>
                                                                            <Col lg={6} md={6} style={{textAlign:'end'}}>
                                                                                <h5 style={{color:'#fff'}}>£{value.std_fee}</h5>
                                                                            </Col>
                                                                        </div>
                                                                    <Table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Title</th>
                                                                                <th>Fees</th>
                                                                                <th>Vat %</th>
                                                                                <th>Total</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                    {value.purchase_disbursements ?(value.purchase_disbursements.map((option,index)=>{
                                                                        return (
                                                                            <>
                                                                                <tr key={index}>
                                                                                    <td>{option.title}</td>
                                                                                    <td>£{option.disbursement_charge}</td>
                                                                                    <td>{option.vat}%</td>
                                                                                    <td>£{option.disbursement_total}</td>
                                                                                </tr>
                                                                            </>
                                                                        )
                                                                    })):null}
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td>Total</td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td>£{value.disbursement_fee_total}</td>
                                                                        </tr>
                                                                        <tr className="card-title">
                                                                            <td className="final-total-text"> Final Total</td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td className="final-total-text">£{value.final_total == 0 ? value.std_fee:value.final_total }</td>
                                                                        </tr>
                                                                    </tfoot>
                                                                    </Table>
                                                                </Card> */}
                                                            </Col>

                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                            <Card>
                                                                <CardTitle className="d-flex justify-content-center"style={{background:'#192a56',color:'#fff'}}>Questions  Details</CardTitle>
                                                                    <CardBody>
                                                                        {this.state.questions? (this.state.questions.map((option,index)=>{
                                                                            return (
                                                                                <>
                                                                                    <div className="d-flex border-bottom mb-2" key={index}>
                                                                                        <Col lg={1} md={1}>
                                                                                            <h6>{option.que_no} </h6>
                                                                                        </Col>
                                                                                        <Col lg={8} md={8}>
                                                                                            <h6>{option.question} </h6>
                                                                                        </Col>
                                                                                        <Col lg={3} md={3}>
                                                                                            {option.que_no == 1 ? <h6>{value.located_eng}</h6>:option.que_no == 2 ? <h6>{value.buy_sell_auction}</h6>:option.que_no == 3 ? <h6>{value.as_company}</h6>:option.que_no == 4 ? <h6>{value.hold_type}</h6>:option.que_no == 5 ? <h6>{value.new_build}</h6>:option.que_no == 6 ? <h6>{value.land_registry}</h6>:option.que_no == 7 ? <h6>{value.gift_money_tp}</h6>:option.que_no == 8 ? <h6>{value.help_buy_isa}</h6>:option.que_no == 9 ? <h6>{value.mortgage}</h6>:''}
                                                                                        </Col>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })):null}
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                        {/* {
                                                            value.first_name_sc ?<>
                                                                <h6 className="my-3" style={{color:'#192a56'}}>Second Client</h6>
                                                                <Row>
                                                                <Col md={2}>
                                                                <FormGroup>
                                                                    <Label for="">
                                                                        Title 
                                                                    </Label>
                                                                    <Input
                                                                    id=""
                                                                    name="title_sc"
                                                                    placeholder=""
                                                                    type="text"
                                                                    disabled
                                                                    value={value.title_sc}
                                                                    />
                                                                </FormGroup>
                                                                </Col>
                                                                <Col md={2}>
                                                                <FormGroup>
                                                                    <Label for="">
                                                                        Surname 
                                                                    </Label>
                                                                    <Input
                                                                    id=""
                                                                    name="surname_sc"
                                                                    placeholder=""
                                                                    type="text"
                                                                    disabled
                                                                    value={value.surname_sc}
                                                                    />
                                                                </FormGroup>
                                                                </Col>
                                                                <Col md={4}>
                                                                <FormGroup>
                                                                    <Label for="">
                                                                        First Name
                                                                    </Label>
                                                                    <Input
                                                                    id=""
                                                                    name="first_name_sc"
                                                                    placeholder=""
                                                                    type="text"
                                                                    disabled
                                                                    value={value.first_name_sc}
                                                                    />
                                                                </FormGroup>
                                                                </Col>
                                                                <Col md={4}>
                                                                <FormGroup>
                                                                    <Label for="">
                                                                        Last Name
                                                                    </Label>
                                                                    <Input
                                                                    id=""
                                                                    name="last_name_sc"
                                                                    placeholder=""
                                                                    type="text"
                                                                    disabled
                                                                    value={value.last_name_sc}
                                                                    />
                                                                </FormGroup>
                                                                </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md={4}>
                                                                    <FormGroup>
                                                                        <Label for="">
                                                                            Telephone
                                                                        </Label>
                                                                        <Input
                                                                        id=""
                                                                        name="telephone_sc"
                                                                        placeholder=""
                                                                        type="text"
                                                                        disabled
                                                                        value={value.telephone_sc}
                                                                        />
                                                                    </FormGroup>
                                                                    </Col>
                                                                    <Col md={4}>
                                                                    <FormGroup>
                                                                        <Label for="">
                                                                            Email Address
                                                                        </Label>
                                                                        <Input
                                                                        id=""
                                                                        name="email_sc"
                                                                        placeholder=""
                                                                        type="text"
                                                                        disabled
                                                                        value={value.email_sc}
                                                                        />
                                                                    </FormGroup>
                                                                    </Col>
                                                                    <Col md={4}>
                                                                    <FormGroup>
                                                                        <Label for="">
                                                                            Company Name 
                                                                        </Label>
                                                                        <Input
                                                                        id=""
                                                                        name="company_name_sc"
                                                                        placeholder=""
                                                                        type="text"
                                                                        disabled
                                                                        value={value.company_name_sc}
                                                                        />
                                                                    </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </>:''
                                                        } */}
                                                    </>
                                                )

                                            })):null}
                                    </ModalBody>
                                    </div>
                                    </Modal>

                                    {/* Win Lose Status Button modal */}
                                    <Modal isOpen={this.state.WinLoseModal} size='md' centered >
                                        <ModalHeader className="modal-header" toggle={this.CloseWinLoseModal}>Won / Lost Status </ModalHeader>
                                        <ModalBody>
                                            <Form className="mt-4" onSubmit={this.WinLoseSubmit}>
                                                <div className="d-flex justify-content-center">
                                                <FormGroup>
                                                    <Label for="">
                                                        Won Lost Status Select  
                                                    </Label>
                                                    <Input
                                                    id=""
                                                    name="win_lose"
                                                    placeholder=""
                                                    type="select"
                                                    >
                                                        <option>
                                                            Select Status
                                                        </option>
                                                        <option value="Won">
                                                            Won
                                                        </option>
                                                        <option value="Lost">
                                                            Lost
                                                        </option>
                                                        
                                                    </Input>
                                                </FormGroup>
                                                </div>
                                                <div className="d-flex justify-content-center">
                                                <Button type='submit'className="btn-sm mr-2"color="primary">Submit</Button>
                                                <Button className="btn-sm" onClick={this.CloseWinLoseModal}>Cancel</Button>
                                                </div>
                                            </Form>  
                                        </ModalBody>
                                    </Modal>

                                    {/* DisbursementsFeeModal */}
                                    <Modal isOpen={this.state.DisbursementsFeeModal} size='xl' >
                                        <ModalHeader className="modal-header" toggle={this.CloseDisbursementsFeeModal}>Disbursements </ModalHeader>
                                        <ModalBody>
                                        <Row>
                                            <Col>
                                            <Card>
                                                <CardTitle className="d-flex justify-content-center" style={{background:'#192a56',color:'#fff'}}>Questions  Details</CardTitle>
                                                    <CardBody>
                                                        {this.state.questions? (this.state.questions.map((option,index)=>{
                                                            return (
                                                                <>
                                                                    <div className="d-flex border-bottom mb-2" key={index}>
                                                                        <Col lg={1} md={1}>
                                                                            <h6>{option.que_no} </h6>
                                                                        </Col>
                                                                        <Col lg={8} md={8}>
                                                                            <h6>{option.question} </h6>
                                                                        </Col>
                                                                    {this.state.ViewPurchaseDetail? (this.state.ViewPurchaseDetail.map((value,index)=>{
                                                                        return (
                                                                        <Col lg={3} md={3}>
                                                                            {option.que_no == 1 ? <h6>{value.located_eng}</h6>:option.que_no == 2 ? <h6>{value.buy_sell_auction}</h6>:option.que_no == 3 ? <h6>{value.as_company}</h6>:option.que_no == 4 ? <h6>{value.hold_type}</h6>:option.que_no == 5 ? <h6>{value.new_build}</h6>:option.que_no == 6 ? <h6>{value.land_registry}</h6>:option.que_no == 7 ? <h6>{value.gift_money_tp}</h6>:option.que_no == 8 ? <h6>{value.help_buy_isa}</h6>:option.que_no == 9 ? <h6>{value.mortgage}</h6>:''}
                                                                        </Col>
                                                                        )
                                                                    })):null}
                                                                    </div>
                                                                </>
                                                            )
                                                        })):null}
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <div className="table-responsive">
                                        <form onSubmit={this.HandleDisbursementsSubmit}>
                                        <Table className="mb-0">
                                            <thead className="bg-primary text-white">
                                                <tr>
                                                    <th>Title</th>
                                                    <th>Disbursement Fee(£)</th>
                                                    <th>Vat(%)</th>
                                                    <th>Disbursement Total(£) </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.DisbursementsFeeList ?(this.state.DisbursementsFeeList.map((option)=>{
                                                        return (
                                                            <>
                                                                <tr key={option.id}>
                                                                    <td>{option.title}</td>
                                                                    <td><TextField
                                                                            label="Disbursement Fee"
                                                                            type="text"
                                                                            name="disbursement_charge"
                                                                            variant="standard"
                                                                            value={option.disbursement_charge}
                                                                            onChange={(e)=>this.HandleChangeFee(option.id,e.target.value)}
                                                                        />
                                                                    </td>
                                                                    <td><TextField
                                                                            label="Vat"
                                                                            type="number"
                                                                            name="vat"
                                                                            variant="standard"
                                                                            value={option.vat}
                                                                            onChange={(e)=>this.HandleChangeTax(option.id,e.target.value)}
                                                                        />
                                                                    </td>
                                                                    <td><TextField
                                                                            label="Total"
                                                                            type="text"
                                                                            variant="standard"
                                                                            name="disbursement_total"
                                                                            InputProps={{
                                                                                readOnly: true,
                                                                            }}
                                                                            value={option.disbursement_total}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })
                                                    ):null
                                                }
                                            </tbody>
                                        </Table>
                                            <div className="d-flex justify-content-end mt-4">
                                                <Button type='submit'className="btn-sm mr-2"color="primary">Submit</Button>
                                                <Button className="btn-sm" onClick={this.CloseDisbursementsFeeModal}>Cancel</Button>
                                            </div>
                                        </form>
                                        </div>
                                        </ModalBody>
                                    </Modal>

                                    <Modal isOpen={this.state.StepDetailViewModal} size='xl' >
                                        <ModalHeader className="modal-header" toggle={this.CloseStepDetailModal}>
                                            
                                            <span className="align-middle flex-grow-1">Purchase property step</span>
                                            <div> 
                                                <ReactToPrint
                                                    trigger={() => <i className="mdi mdi-printer" style={{fontSize:'18px',color:'white'}}></i>}
                                                    content={() => this.componentRef}
                                                />
                                            </div>
                                            
                                        </ModalHeader>

                                        {/* Print section */}
                                        <div ref={el => (this.componentRef = el)} >
                                        <ModalBody>
                                            <Row>
                                                <Col md={6} sm={6} lg={6}>
                                                    <Table>
                                                    <thead>
                                                        <tr style={{'fontWeight':'bold','fontSize':'16px'}}>
                                                            <th style={{width:'15%'}}>Step No</th>
                                                            <th style={{width:'65%'}}>Step Name</th>
                                                            <th style={{width:'20%'}}>Completed Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.StepList ?(this.state.StepList.map((stepValue,index)=>{
                                                                return(stepValue.step_no >= 1 && stepValue.step_no <= 8)? <tr key={index}>
                                                                <td>{stepValue.step_no}</td>
                                                                <td>{stepValue.step_name}
                                                                <div className="d-flex">
                                                                    {/* <input type="text" name="note" value={(stepValue.note!=null)?stepValue.note:''} className="d-block w-100" onChange={(e)=>this.onNoteChange(stepValue.id,e.target.value)}/>
                                                                    <Button className="btn-sm ml-2" color="success" onClick={()=>this.HandleNoteSubmit(stepValue.id)}><i className="ri-send-plane-fill" style={{fontSize:'12px'}}></i></Button> */}
                                                                    Notes: ({stepValue.note})
                                                                </div>
                                                                </td>
                                                                <td>{stepValue.step_submit_date? DateFormat(stepValue.step_submit_date):''}</td>
                                                            </tr>:null 
                                                            })):null
                                                        }
                                                    </tbody>
                                                    </Table>
                                                </Col>
                                                <Col md={6} sm={6} lg={6}>
                                                    <Table>
                                                    <thead>
                                                        <tr style={{'fontWeight':'bold','fontSize':'16px'}}>
                                                            <th style={{width:'15%'}}>Step No</th>
                                                            <th style={{width:'65%'}}>Step Name</th>
                                                            <th style={{width:'20%'}}>Completed Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.StepList ?(this.state.StepList.map((stepValue,index)=>{
                                                                return(stepValue.step_no >= 9 && stepValue.step_no <= 16)? <tr key={index}>
                                                                <td>{stepValue.step_no}</td>
                                                                <td>{stepValue.step_name}
                                                                <div className="d-flex">
                                                                    {/* <input type="text" name="note" value={(stepValue.note!=null)?stepValue.note:''} className="d-block w-100"onChange={(e)=>this.onNoteChange(stepValue.id,e.target.value)}/>
                                                                    <Button className="btn-sm ml-2" color="success" onClick={()=>this.HandleNoteSubmit(stepValue.id)}><i className="ri-send-plane-fill" style={{fontSize:'12px'}}></i></Button> */}
                                                                    Notes: ({stepValue.note})
                                                                </div>
                                                                </td>
                                                                <td>{stepValue.step_submit_date ? DateFormat(stepValue.step_submit_date):''}</td>
                                                            </tr>:null 
                                                            })):null
                                                        }
                                                    </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        </ModalBody>
                                        </div>
                                    </Modal>

                                    {/* solicitor  modal */}
                                    <Modal isOpen={this.state.SolicitorModal} size='md' centered >
                                        <ModalHeader className="modal-header" toggle={this.CloseSolicitorModal}>
                                            Select conveyancer </ModalHeader>
                                        <ModalBody>
                                            <Form className="mt-4" onSubmit={this.SubmitSelectSolicitor}>
                                                <div className="d-flex justify-content-center">
                                                <FormGroup style={{width:'100%'}}>
                                                    <Label for="">Select conveyancer 
                                                    </Label>
                                                    <Input
                                                    id=""
                                                    name="solicitor_id"
                                                    placeholder=""
                                                    type="select"
                                                    >
                                                        {/*this.state.solicitor_name == '' ? <option>--Select conveyancer--</option> : <option>{this.state.company_name}</option>*/}
                                                        <option>--Select conveyancer--</option> 

                                                        {this.state.solicitorList ? (this.state.solicitorList.map((value,index)=>{
                                                            return (
                                                                <option value={value.id} key={index} selected={this.state.solicitor_name == value.first_name}>{value.first_name}</option>
                                                            )
                                                        })):null}
                                                        
                                                    </Input>
                                                </FormGroup>
                                                </div>
                                                <div className="d-flex justify-content-center">
                                                <Button type='submit'className="btn-sm mr-2"color="primary">Submit</Button>
                                                <Button className="btn-sm" onClick={this.CloseSolicitorModal}>Cancel</Button>
                                                </div>
                                            </Form>  
                                        </ModalBody>
                                    </Modal>

                                    {/* Upload modal */}
                                    <FileUploadPurchase 
                                        data={this.state}
                                        onClick={this.ClosePurchaseUploadModal}
                                    />

                                    {/* paginnation */}
                                    <ReactPaginate
                                      previousLabel="&#x276E;"
                                      nextLabel="&#x276F;"
                                      breakLabel={'...'}
                                      breakClassName={'break-me'}
                                      pageCount={this.state.pageCount}
                                      initialPage={this.state.currentPage - 1}
                                      forcePage={this.state.currentPage - 1}
                                      marginPagesDisplayed={2}
                                      pageRangeDisplayed={5}
                                      onPageChange={this.handlePageClick}
                                      containerClassName={'pagination mt-4 float-right'}
                                      activeClassName={'active'}
                                      disableInitialCallback={true}
                                    />
        
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default PurchaseReq;
