import React, { useRef } from 'react'
import GowlingLogo from "../../assets/images/gowlings_logo_red.png";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ReactToPrint from 'react-to-print';
import moment from 'moment';

const InvoiceModal = ({isOpen,InvoiceDetails,toggle}) => {
  if(!InvoiceDetails)return null;
  
  let componentRef  = useRef();
  let TodayDate = moment(new Date()).format("DD/MM/YYYY");
  // console.log(InvoiceDetails)
  return (
    <div>
      <Modal isOpen={isOpen} size='xl'>
        <ModalHeader>Generate Invoice</ModalHeader>
        <ModalBody>
          <div ref={(el) => (componentRef = el)}>
              <div style={{padding:'30px',background:'#d7e1fa'}}>
                <div className='d-flex justify-content-between p-3' style={{background:'#192a56'}}>
                  <div className='d-flex justify-content-end'>
                      <div className="text-center d-flex justify-content-center" style={{marginRight:'13px'}}>
                          <div className='d-flex'>
                              <img src={GowlingLogo} height='58'/>
                              <div style={{marginLeft:'15px'}}>
                              <h4 className='text-logo' style={{fontSize:'20px'}}>Y O U R  <span style={{marginLeft:'10px'}}>L E G A L</span></h4>
                              <h6 className='logo-sub' style={{fontSize:'10px'}}>━━━━━ G R O U P ━━━━━</h6>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className='d-flex align-items-center'>
                    <h4 style={{color:'#fff'}}>INVOICE</h4>
                  </div>
                </div>                
              </div>

              <div className='d-flex justify-content-between p-3' style={{marginTop:'2rem',marginBottom:'1rem'}}>
                <div>
                  <h6 style={{marginBottom:'12px',color:'#192a56',fontWeight:'bold'}}>From :</h6>
                  <div style={{marginLeft:'30px'}}>
                    <h6>Your Legal Group Ltd</h6>
                    <h6>Company number 15282722 </h6>
                    <h6>Registered office address: </h6>
                    <h6>64 Deepdale Road,</h6>
                    <h6>Preston,</h6>
                    <h6>Lancashire,</h6>
                    <h6>PR1 5AR</h6>                    
                  </div>
                </div>
                <div className=''>
                    <h6>Invoice No : {InvoiceDetails.invoice_number}</h6>
                    <h6>Date : {TodayDate}</h6>
                    {/* <h6>Vat No : 123456789</h6> */}
                </div>
              </div>

              <div className='p-3'>
                <h6 style={{marginBottom:'12px',color:'#192a56',fontWeight:'bold'}}>To :</h6>
                <div style={{marginLeft:'30px'}}>
                  {/*  <h6>Your Legal Group Ltd</h6>
                  <h6>Company number 15282722 </h6>
                  <h6>Registered office address : </h6>
                  <h6>64 Deepdale Road,</h6>
                  <h6>Preston,</h6>
                  <h6>Lancashire,</h6>
                  <h6>PR1 5AR</h6> */}
                  <h6>Solicitor : {InvoiceDetails.soli_company}</h6>
                  <h6>Address : {InvoiceDetails.solicitor_address} , {InvoiceDetails.solicitor_city}</h6>
                  <h6>F.A.Q Conveyancer : {InvoiceDetails.conveyancer_name}</h6>                    
                </div>                  
              </div>

              <div className='d-flex justify-content-between p-3' style={{marginTop:'2rem',marginBottom:'1rem'}}>
                  <div>
                    <h6 style={{marginBottom:'10px',color:'#192a56',fontWeight:'bold'}}>Description :</h6>
                    <h6 style={{marginLeft:'88px'}}>Admin fee of address : {InvoiceDetails.property_address}</h6>
                  </div>

                  <div style={{width:'200px'}}>
                    <div className='text-right pt-2 px-2'>
                      <div>
                        <div><h6>Amount GBP </h6></div>
                        <div><h6>£{InvoiceDetails.admin_fee}</h6></div>
                      </div>
                    </div>
                    {/* <div className='d-flex justify-content-between pt-2 px-2'>
                      <div><h6>Vat : </h6></div>
                      <div><h6>£0</h6></div>
                    </div> */}
                    <div className='d-flex justify-content-between pt-2 px-2'>
                      <div><h6>Total GBP : </h6></div>
                      <div><h6>£{InvoiceDetails.admin_fee}</h6></div>
                    </div>
                  </div>
              </div>

              
              <div className='d-flex justify-content-between p-3' style={{marginTop:'2rem',marginBottom:'1rem'}}>
                <div>
                  <h6 style={{marginBottom:'12px',color:'#192a56',fontWeight:'bold'}}>Payment:</h6>
                  <div style={{marginLeft:'30px'}}>
                    <h6>Direct bank transfers can be made (quoting invoice number or property address)</h6>
                    <h6>To:</h6>
                    <h6>Bank : Lloyds Bank</h6>
                    <h6>Account Name : Your Legal Group Ltd </h6>
                    <h6>Sortcode : 30-96-85</h6>
                    <h6>Account No : 71542460</h6>
                  </div>
                </div>                  
              </div>

              <div className='d-flex justify-content-between p-3' style={{marginTop:'2rem',marginBottom:'1rem'}}>
                <div style={{marginBottom: '20px'}}>
                  <h6 style={{marginBottom:'12px',color:'#192a56',fontWeight:'bold'}}>Terms & Condition: </h6>
                  <div style={{marginLeft:'30px'}}>
                    <h6>Please send full payment within 7 days of receiving this invoice.</h6>
                  </div>
                </div>
              </div>
              
              <div style={{background:'#d7e1fa',padding:'0.6rem 3rem',position: 'absolute',left: '0',bottom: '0',width: '100%'}} className='d-flex justify-content-between'>
                <div className='d-flex'>
                    {/* <i className='dripicons-location mr-2' style={{fontSize:'18px',color:'#192a56'}}></i> */}
                    <h6 style={{display:'contents',color:'#192a56',fontWeight:'bold'}}>Registered office: 64 Deepdale Road, Preston, Lancashire, PR1 5AR</h6>
                </div>
                <div className='d-flex'>
                    {/* <i className='ri-phone-fill mr-2' style={{fontSize:'18px',color:'#192a56'}}></i> */}
                    <h6 style={{marginTop:'4px',color:'#192a56',fontWeight:'bold'}}>07966518009</h6>
                </div>
              </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <ReactToPrint
            trigger={() => <Button color='primary' size='sm'>Print</Button>}
            content={() => componentRef}
            copyStyles={true}
          />
          <Button onClick={toggle} size='sm'>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default InvoiceModal