import Api from "./api";

export const CommonApi = {
	userlist : () => {
	    return Api.get('/admin/user-list');
	},
  vendorList : () => {
      return Api.get('/admin/vendor-list');
  },
  getSupervisorList:()=>{
    return Api.get('/admin/supervisor-list');
  },
	AddUser : (data) => {
		return Api.post('/admin/add-user',data);
	},  
  downloadUserCard:(data)=>{
    return Api.post('/admin/download-user-card',{'user_id':data},{responseType: 'blob'});
  },
  getRoleList : () => {
      return Api.get('/admin/get-role-list');
  },  
	ChangeUserStatus:(id)=>{
		return Api.post('/admin/user-status-change/'+id);
	},
	// Get village list
	GetAreaTypeList: () => {
	    return Api.get('/admin/get-area-type-list');
	},
	AreaList:(currentPage,per_page,search)=>{
      return Api.get('/admin/system-area-data?page='+currentPage+'&per_page='+per_page+'&search='+search);
  },
  AddArea : (data) => {
		return Api.post('/admin/add-area',data);
	},
  getAreaClusterList:(area_type_id)=>{
    return Api.get('/admin/get-area-cluster-list?area_type_id='+area_type_id);
  },
	getAreaList: (area_cluster_id) => {
	  return Api.get('/admin/get-area-list?area_cluster_id='+area_cluster_id);
	},
	
	/* User Profile change password */
	changePassword: (data) => {
    return Api.post('/admin/change-password',data);
  },

	/*Beneficiary*/
	AddBeneficiary: (data)=>{  		
		return Api.post('/admin/add-beneficiary',data);
	},
  beneficiaryEditData: (id)=>{     
    return Api.post('/admin/edit-beneficiary/'+id);
  },    
	beneficiaryList:(currentPage,per_page,search)=>{
      return Api.get('/admin/beneficiary-list?page='+currentPage+'&per_page='+per_page+'&search='+search);
  },
  importBeneficiary: (data)=>{  		
		return Api.post('/admin/import-beneficiary',data);
	},
  updateBeneficiary: (data)=>{      
    return Api.post('/admin/update-beneficiary',data);
  },
  deleteBeneficiary: (id)=>{      
    return Api.post('/admin/delete-beneficiary/'+id);
  },

  /* Beneficiary Card generate */
	beneficiaryNonCardList:(currentPage,per_page,search,area_type_id,area_id,card_generated,area_cluster)=>{
      return Api.get('/admin/beneficiary-noncard-list?page='+currentPage+'&per_page='+per_page+'&search='+search+'&area_type='+area_type_id+'&area='+area_id+'&card_generated='+card_generated+'&area_cluster='+area_cluster);
  },
  generateBeneficiaryCard: (data)=>{  		
		return Api.post('/admin/generate-beneficiary-card',data);
	},
	downloadBeneficiaryCard:(data)=>{
		return Api.post('/admin/download-beneficiary-card',{'beneficiary_id':data},{responseType: 'blob'});
	},

  /* Route Manage */  
  getRouteNonGenerated:(currentPage,per_page,search,area_type_id,area_cluster)=>{
    return Api.get('/admin/route-non-generated-list?page='+currentPage+'&per_page='+per_page+'&search='+search+'&area_type='+area_type_id+'&area_cluster='+area_cluster);
  },
  generateRoute: (data)=>{      
    return Api.post('/admin/generate-route',data);
  },
  getRouteList:(currentPage,per_page,search)=>{
    return Api.get('/admin/get-route-list?page='+currentPage+'&per_page='+per_page+'&search='+search);
  },
  deleteRoute: (id)=>{      
    return Api.post('/admin/delete-route/'+id);
  },
  getVendorRoute:()=>{
    return Api.get('/admin/get-vendor-route');
  },
  getRouteDetails:(id)=>{
    return Api.get('/admin/get-route-details/'+id);
  },
  getRouteArea:(area_id)=>{
    return Api.get('/admin/get-route-area?area_id='+area_id);
  },  

  /* Kit Manage */
  addKit : (data) => {
    return Api.post('/admin/add-kit',data);
  },
  getKitList:(currentPage,per_page,search)=>{
    return Api.get('/admin/get-kit-list?page='+currentPage+'&per_page='+per_page+'&search='+search);
  },
  deleteKit: (id)=>{      
    return Api.post('/admin/delete-kit/'+id);
  },
  returnKit:(data) => {
    return Api.post('/admin/return-kit',data);
  },

  /* Kit distributon */
  assignKitDistribution: (data) => {
    return Api.post('/admin/assign-kit-distribution',data);
  },
  getKitDistributionData:(currentPage,per_page,search)=>{
    return Api.get('/admin/get-kit-distribution-list?page='+currentPage+'&per_page='+per_page+'&search='+search);
  },
  qrCodeSubmit:(qr_code) => {
    return Api.post('/admin/beneficiary-qr-code-submit',{'qr_code':qr_code});
  },
  getKitDistributionTracking:(currentPage,per_page,search)=>{
    return Api.get('/admin/get-kit-distribution-tracking?page='+currentPage+'&per_page='+per_page+'&search='+search);
  },
  getDeliverdKitList:(currentPage,per_page,search)=>{
    return Api.get('/admin/get-delivered-kit-list?page='+currentPage+'&per_page='+per_page+'&search='+search);
  },  

  /* Report */
  getBeneficiaryReport:(currentPage,per_page,search)=>{
    return Api.get('/admin/beneficiary-report?page='+currentPage+'&per_page='+per_page+'&search='+search);
  },
  getKitStockReport:(currentPage,per_page,search)=>{
    return Api.get('/admin/kit-stock-report?page='+currentPage+'&per_page='+per_page+'&search='+search);
  },
  getAreaCardReport:(currentPage,per_page,search,area_type_id,area_cluster)=>{
    return Api.get('/admin/area-card-report?page='+currentPage+'&per_page='+per_page+'&search='+search+'&area_type='+area_type_id+'&area_cluster='+area_cluster);
  },  
  getTotalCardCount:()=>{
    return Api.get('/admin/total-card-count');
  },
  getDashboardReport:()=>{
    return Api.get('/admin/get-dashboard-report');
  },
  getBeneficiaryVerifyReport:(currentPage,per_page,search,verify_date)=>{
    return Api.get('/admin/beneficiary-verify-report?page='+currentPage+'&per_page='+per_page+'&search='+search+'&verify_date='+verify_date);
  },
  getKitVerifyReport:(currentPage,per_page,search,verify_date)=>{
    return Api.get('/admin/kit-verify-report?page='+currentPage+'&per_page='+per_page+'&search='+search+'&verify_date='+verify_date);
  },

  /* Verifiyer */
  beneficiaryNonOrVerifiyList:(currentPage,per_page,search,area_type_id,area_id,verified_beneficiary,area_cluster)=>{
      return Api.get('/admin/beneficiary-non-or-verify-list?page='+currentPage+'&per_page='+per_page+'&search='+search+'&area_type='+area_type_id+'&area='+area_id+'&verified_beneficiary='+verified_beneficiary+'&area_cluster='+area_cluster);
  },  
  verifyBeneficiary: (data)=>{      
    return Api.post('/admin/verify-beneficiary',data);
  },
  kitDistributionNonOrVerifiyList:(currentPage,per_page,search,area_type_id,area_id,verified_kit,area_cluster)=>{
      return Api.get('/admin/kit-non-or-verify-list?page='+currentPage+'&per_page='+per_page+'&search='+search+'&area_type='+area_type_id+'&area='+area_id+'&verified_kit='+verified_kit+'&area_cluster='+area_cluster);
  },
  verifyKit: (data)=>{      
    return Api.post('/admin/verify-kit',data);
  },
  getVehicleList:() => {
    return Api.get('/admin/get-vehicle');
  },
  vehicleOutKM:(data) => {
    return Api.post('/admin/vehicle-out-km',data);
  },

  //sale property request
  salePropertyRequest:(data)=>{
    return Api.post('/admin/sale-quote-request',data);
  },
  //Purchase property request
  PurchasePropertyRequest:(data)=>{
    return Api.post('/admin/purchase-request',data);
  },

  //Purchase property fetch data
  PurchasePropertyFetch:(currentPage,per_page,from_date,to_date,search)=>{
    return Api.get('/admin/fetch-purchase-property?page='+currentPage+'&per_page='+per_page+'&from_date='+from_date+'&to_date='+to_date+'&search='+search);
  },

  //Purchase property fetch step data
  PurchasePropertyFetchSteps:(id)=>{
    return Api.get('/admin/fetch-purchase-property-steps/'+id);
  },

  PurchasePropertyView:(id)=>{
    return Api.get('/admin/view-purchase-property/'+id);
  },

  //Purchase Property Step submit
  PurchasePropertyStepSubmit:(data)=>{
    return Api.post('/admin/purchase-property-step-submit',data);
  },

  //Purchase Property Note submit
  PurchasePropertyNoteSubmit:(data)=>{
    return Api.post('/admin/purchase-property-note-submit',data);
  },

  //sale Property Note submit
  SalePropertyNoteSubmit:(data)=>{
    return Api.post('/admin/sale-property-note-submit',data);
  },

    //Sale property fetch data
    SalePropertyFetch:(currentPage,per_page,from_date,to_date,search)=>{
      return Api.get('/admin/fetch-sale-property?page='+currentPage+'&per_page='+per_page+'&from_date='+from_date+'&to_date='+to_date+'&search='+search);
    },
  
    //Sale property fetch step data
    SalePropertyFetchSteps:(id)=>{
      return Api.get('/admin/fetch-sale-property-steps/'+id);
    },
  
    SalePropertyView:(id)=>{
      return Api.get('/admin/view-sale-property/'+id);
    },
  
    //Sale Property Step submit
    SalePropertyStepSubmit:(data)=>{
      return Api.post('/admin/sale-property-step-submit',data);
    },

    //Admin dasboard total count
    TotalCount:()=>{
      return Api.get('/admin/dashboard-total-count');
    },

    //Agent dasboard
    AgentDashboardData:()=>{
      return Api.get('/admin/agent-dashboard');
    },

    // Agent Sale property fetch data
    AgentSaleFetch:(currentPage,per_page,from_date,to_date,search)=>{
      return Api.get('/admin/agent-sale-request?page='+currentPage+'&per_page='+per_page+'&from_date='+from_date+'&to_date='+to_date+'&search='+search);
    },

    // Agent Purchase property fetch data
    AgentPurchaseFetch:(currentPage,per_page,from_date,to_date,search)=>{
      return Api.get('/admin/agent-purchase-request?page='+currentPage+'&per_page='+per_page+'&from_date='+from_date+'&to_date='+to_date+'&search='+search);
    },

    // Sale report
    getSaleReport:(currentPage,per_page,search,from_date,to_date,sort)=>{
      return Api.get('/admin/sale-report?page='+currentPage+'&per_page='+per_page+'&search='+search+'&from_date='+from_date+'&to_date='+to_date+'&sort='+sort);
    },

    // Purchase report
    getPurchaseReport:(currentPage,per_page,search,from_date,to_date,sort)=>{
      return Api.get('/admin/purchase-report?page='+currentPage+'&per_page='+per_page+'&search='+search+'&from_date='+from_date+'&to_date='+to_date+'&sort='+sort);
    },

    //get quote 
    GetBuyStdFee:(property_value)=>{
      return Api.get('/admin/get-purchase-std-fees?property_value='+property_value);
    },

    GetSaleStdFee:(property_value)=>{
      return Api.get('/admin/get-sale-std-fees?property_value='+property_value);
    },

    GetPurchaseAddiFee:()=>{
      return Api.get('/admin/get-purchase-addi-fees');
    },

    GetSalesAddiFee:()=>{
      return Api.get('/admin/get-sales-addi-fees');
    },

    //get  Sale Disbursements fee
    GetSaleDisbursementsFee:(id)=>{
      return Api.get('/admin/get-sale-disbursements-fees/'+id);
    },

    //get purchase Disbursements fee
    GetPurchaseDisbursementsFee:(id)=>{
      return Api.get('/admin/get-purchase-disbursements-fees/'+id);
    },

    //update Disbursements fee
    UpdateDisbursementsFee:(data)=>{
      return Api.post('/admin/update-disbursements-fees',data);
    },

    //customer Dashboard
    GetCustomerDashBoard:()=>{
      return Api.get('/admin/customer-dashboard');
    },

    GetCustomerData:()=>{
      return Api.get('/admin/customer-data');
    },

    //get questions
    GetPurchaseQuestions:()=>{
      return Api.get('/admin/purchase-questions');
    },
    GetSaleQuestions:()=>{
      return Api.get('/admin/sale-questions');
    },

    //Purchase admin fee
    PurchaseAdminFee:(admin_fee,id)=>{
      return Api.post('/admin/purchase-property-admin-fee?admin_fee='+admin_fee+'&id='+id);
    },

    //sale admin fee
    SaleAdminFee:(admin_fee,id)=>{
      return Api.post('/admin/sale-property-admin-fee?admin_fee='+admin_fee+'&id='+id);
    },

    //get solicitor
    GetSolicitor:()=>{
      return Api.get('/admin/get-solicitor');
    },

    PurchaseSolicitorSubmit:(data)=>{
      return Api.post('/admin/purchase-select-solicitor',data)
    },
    SaleSolicitorSubmit:(data)=>{
      return Api.post('/admin/sale-select-solicitor',data)
    },

    // solicitor Sale property fetch data
    SolicitorSaleFetch:(currentPage,per_page,from_date,to_date,search)=>{
      return Api.get('/admin/solicitor-sale-request?page='+currentPage+'&per_page='+per_page+'&from_date='+from_date+'&to_date='+to_date+'&search='+search);
    },

    // solicitor Purchase property fetch data
    SolicitorPurchaseFetch:(currentPage,per_page,from_date,to_date,search)=>{
      return Api.get('/admin/solicitor-purchase-request?page='+currentPage+'&per_page='+per_page+'&from_date='+from_date+'&to_date='+to_date+'&search='+search);
    },
    SolicitorDashboard:()=>{
      return Api.get('/admin/solicitor-dashboard');
    },
    SolicitorDashboardFilter:(from_date,to_date)=>{
      return Api.get('/admin/solicitor-dashboard-count?from_date='+from_date+'&to_date='+to_date);
    },
    AdminDashboardFilter:(from_date,to_date)=>{
      return Api.get('/admin/admin-dashboard-count?from_date='+from_date+'&to_date='+to_date);
    },
    AgentDashboardFilter:(from_date,to_date)=>{
      return Api.get('/admin/agent-dashboard-count?from_date='+from_date+'&to_date='+to_date);
    },

    //add solicitor
    AddSolicitor : (data) => {
      return Api.post('/admin/add-solicitor',data);
    },

    //select conveyancer
    getPurchaseConveyancer : (company_parent_id) => {
      return Api.get('/admin/get-purchase-conveyancer?company_parent_id='+company_parent_id);
    },
    getSaleConveyancer : (company_parent_id) => {
      return Api.get('/admin/get-sale-conveyancer?company_parent_id='+company_parent_id);
    },

    //upload sale file
    uploadSaleFile : (data) => {
      return Api.post('/admin/upload-sale-property-file',data);
    },

    //fetch sale files    
    getSaleFiles : (id) => {
      return Api.get('/admin/fetch-sale-property-files/'+id);
    },
    
    //upload purchase file
    uploadPurchaseFile : (data) => {
      return Api.post('/admin/upload-purchase-property-file',data);
    },

    //fetch purchase files    
    getPurchaseFiles : (id) => {
      return Api.get('/admin/fetch-purchase-property-files/'+id);
    },

    //delete sale   
    deleteSales : (id) => {
      return Api.get('/admin/delete-sales/'+id);
    },
    //delete purchase
    deletePurchase: (id)=>{
      return Api.get('/admin/delete-purchase/'+id);
    },

    //Purchase admin note
    EditPurchaseAdminNote:(id)=>{
      return Api.get('/admin/edit-purchase-property-admin-note/'+id);
    },
    PurchaseAdminNote:(id,admin_note)=>{
      return Api.put('/admin/purchase-property-admin-note?id='+id+'&admin_note='+admin_note);
    },

    //sale admin note
    EditSaleAdminNote:(id)=>{
      return Api.get('/admin/edit-sale-property-admin-note/'+id);
    },
    SaleAdminNote:(id,admin_note)=>{
      return Api.put('/admin/sale-property-admin-note?id='+id+'&admin_note='+admin_note);
    },

    //cacncel sale
    CancelSales : (id) => {
      return Api.get('/admin/cancel-sales/'+id);
    },

    //cacncel Purchase
    CancelPurchase : (id) => {
      return Api.get('/admin/cancel-purchase/'+id);
    },

    //Update Purchase
    UpdatePurchaseRequest : (data) =>{
      return Api.post('/admin/update-purchase', data);
    },
    //Update sale
    UpdateSaleRequest : (data) =>{
      return Api.post('/admin/update-sale', data);
    }
    
};

export default CommonApi;