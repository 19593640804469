import React from 'react'
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
ChartJS.register(ArcElement, Tooltip, Legend);
import { Container, Row, Col, Table, Card, CardBody} from "reactstrap";
import { useState } from 'react';
import CommonApi from '../../apis/CommonApi';
import { useEffect } from 'react';

const PurchaseWonLostChart = () => {
    const [WonData,setWonData]=useState('');
    const [LostData,setLostData] = useState('');
    const GetWonLostData=()=>{
        CommonApi.TotalCount().then((res)=>{
            setWonData(res.data.data.purchaseWon_Status);
            setLostData(res.data.data.purchaseLost_Status);
        })
    }
    useEffect(()=>{
        GetWonLostData();
    },[]);
    const data = {
        labels: [
            "Won",
            "Lost"
        ],
        datasets: [
            {
                data: [WonData, LostData],
                backgroundColor: [
                    "#79AC78",
                    "#FF6969"
                ],
                hoverBackgroundColor: [
                    "#79AC78",
                    "#FF6969"
                ],
                hoverBorderColor: "#fff"
            }]
    };
  return (
        <Card>
            <CardBody>
                
                <h5 className="mb-4">Purchase success report</h5>

                <Row className="text-center">
                    <Col xs={6}>
                        <h5 className="mb-0">{WonData}</h5>
                        <p className="text-muted text-truncate">Won</p>
                    </Col>
                    <Col xs={6}>
                        <h5 className="mb-0">{LostData}</h5>
                        <p className="text-muted text-truncate">Lost</p>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Doughnut data={data} />
                    </Col>
                </Row>

            </CardBody>         
        </Card>
  )
}

export default PurchaseWonLostChart