import React from "react";
import { Row, Col, Container } from "reactstrap";
import { Foot,VerifyFoot } from "../../helpers/globalfunctions";
import { Route, Redirect } from "react-router-dom";

const Footer = () => {
  VerifyFoot()
  return (
    <React.Fragment>
              <footer className="footer">
                  <Container fluid>
                        <Row>
                            <Col sm={6} style={{fontWeight:600}}>
                               <span className="foot-dev-link" dangerouslySetInnerHTML={{__html: Foot()}} />
                               © {new Date().getFullYear()} 
                            </Col>
                            <Col sm={6}>
                                <div className="text-sm-right foot-dev-link d-none d-sm-block" style={{fontWeight:600}}>
                                    Version 1.0
                                </div>
                            </Col>
                        </Row>
                  </Container>
              </footer>
    </React.Fragment>
  );
};

export default Footer;
